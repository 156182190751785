import { Box, TextField, Typography } from '@mui/material'
import styles from './styles.module.scss'
import { RenderTooltip } from '../../../../../components/utilities/RenderTooltip'

const TextInput = ({
  value,
  onChange,
  error,
  label,
  placeholder = '',
  tooltip = '',
  required = false,
  type = 'text',
}) => {
  return (
    <Box className={styles.container}>
      <Box className={styles.label}>
        <Typography variant="subtitle2">{label}</Typography>
        {!!label && !!required && <Typography variant="subtitle3">(*)</Typography>}
        {!!tooltip && <RenderTooltip title={tooltip} />}
      </Box>

      <TextField
        fullWidth
        helperText={error ? error.message : null}
        error={!!error}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </Box>
  )
}

export default TextInput

import { useState } from 'react'
import * as yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Fab, Modal } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFieldArray, useForm } from 'react-hook-form'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { TextInputForm } from '../../../../components/common/form'
import AddMetadataModal from './AddMetadataModal'

const KybSettingsMetadata = ({ onSave, defaultValues = [], isLoading = false }) => {
  const { t } = useTranslation(['common', 'messages'])

  const [openAddMetadata, setOpenAddMetadata] = useState(false)

  const schema = yup.object().shape({
    metadata: yup.array().of(
      yup.object().shape({
        name: yup.string().required(),
        label: yup.string().required(),
      }),
    ),
  })

  const { handleSubmit, reset, control, formState } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      metadata: defaultValues,
    },
  })

  const { remove, fields, append } = useFieldArray({
    control,
    name: 'metadata',
  })

  const handleAddMetadataItem = (item) => {
    append(item)
    setOpenAddMetadata(false)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: '1fr' }}>
        {fields.map((_, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              gap: '1rem',
            }}
          >
            <TextInputForm name={`metadata.${index}.name`} control={control} disabled />
            <TextInputForm name={`metadata.${index}.label`} control={control} />
            <Box
              sx={{
                display: 'flex',
                gap: '0.5rem',
              }}
            >
              <Fab variant="close" className="icon-fab" onClick={() => remove(index)}>
                <DeleteOutlineOutlinedIcon />
              </Fab>
            </Box>
          </Box>
        ))}
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <Button
            endIcon={<AddCircleOutlineIcon />}
            variant="outlinedGrey"
            onClick={() => setOpenAddMetadata(true)}
            disabled={fields.length >= 5}
          >
            {t('common:add')}
          </Button>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
          }}
        >
          <Button variant="outlinedGrey" onClick={() => reset()} disabled={!formState.isDirty || isLoading}>
            {t('common:cancel')}
          </Button>
          <LoadingButton variant="contained" onClick={handleSubmit(onSave)} disabled={!formState.isDirty || isLoading}>
            {t('common:save')}
          </LoadingButton>
        </Box>
      </Box>
      <Modal open={openAddMetadata} onClose={() => setOpenAddMetadata(false)}>
        <div>
          <AddMetadataModal setOpen={setOpenAddMetadata} onAdd={handleAddMetadataItem} />
        </div>
      </Modal>
    </Box>
  )
}

export default KybSettingsMetadata

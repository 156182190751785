import { Box, Button, Checkbox, Paper, Divider, MenuList, MenuItem, ListItemText } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const FilterMenuContent = ({ onApply, onReset, options = [], defaultValue }) => {
  const { t } = useTranslation(['common'])
  const [fieldValue, setFieldValue] = useState(defaultValue || '')

  const handleToggle = (value) => {
    setFieldValue((prevState) => {
      const currentStates = prevState.split(',').filter(Boolean)
      const isSelected = currentStates.includes(value)

      const updatedStates = isSelected ? currentStates.filter((label) => label !== value) : [...currentStates, value]

      return updatedStates.join(',')
    })
  }

  const isDirty = defaultValue !== fieldValue
  return (
    <Paper sx={{ width: 180, padding: 1, boxShadow: 2 }}>
      <MenuList sx={{ padding: 0 }}>
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => handleToggle(option.value)}
            sx={{
              fontSize: '0.8rem',
              padding: '4px 8px',
            }}
          >
            <Checkbox
              checked={fieldValue.split(',').includes(option.value)}
              sx={{
                padding: '4px',
                '& .MuiSvgIcon-root': { fontSize: 16 },
              }}
            />
            <ListItemText primary={option.label} primaryTypographyProps={{ fontSize: '0.8rem' }} />
          </MenuItem>
        ))}
        <Divider sx={{ margin: '4px 0' }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Button
            variant="outlined"
            size="small"
            onClick={() => onReset()}
            disabled={!fieldValue}
            sx={{ fontSize: '0.8rem', padding: '2px 12px', height: '30px' }}
          >
            {t('common:clear')}
          </Button>
          <Button
            variant="contained"
            onClick={() => onApply(fieldValue)}
            disabled={!isDirty}
            sx={{ fontSize: '0.8rem', padding: '2px 12px', height: '30px' }}
          >
            {t('common:apply')}
          </Button>
        </Box>
      </MenuList>
    </Paper>
  )
}

export default FilterMenuContent

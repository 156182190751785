import { Box, Paper } from '@mui/material'
import './chart.scss'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useTranslation } from 'react-i18next'

const BarChart2 = (inputData) => {
  const { t } = useTranslation(['common'])
  return (
    <Paper className="chart" elevation={0}>
      <Box className="title">{t('common:tsxAndUsers')}</Box>
      <Box style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer>
          <BarChart data={inputData.inputData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid stroke="#f5f5f5" vertical={false} />
            <XAxis
              style={{
                fontSize: '.8rem',
              }}
              dataKey="month"
            />
            <YAxis
              style={{
                fontSize: '.6rem',
              }}
            />
            <Tooltip />
            <Legend />
            <Bar dataKey="transactions" fill="var(--green-50)" radius={[3, 3, 0, 0]} barSize={14} barCategoryGap={10} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  )
}

export default BarChart2

import '../../../../pages/case-management/cases/case.scss'
import { useEffect, useMemo, useState } from 'react'
import { Box, IconButton, Link, TablePagination, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { buildTimeStringFromTimestamp, toLocaleUTCDateString } from '../../../../components/common/time/timeHelper'
import { TableChip } from '../../../../components/utilities/TableChip'
import { useNavigate } from 'react-router-dom'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import RefreshIcon from '@mui/icons-material/Refresh'
import CustomColumnMenu from '../../../../components/common/datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../../../../components/common/datagrid/CustomLoadingOverlay'
import { useFindCasesQuery } from '../../../../features/cases/caseSlice'

const defaultSortModel = { field: 'updatedAt', sort: 'desc' }

const TabUserCases = ({ userId }) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])
  const apiRef = useGridApiRef()
  const { accessToken, emailClient } = useSelector((state) => state.login)
  const [sortModel, setSortModel] = useState([defaultSortModel])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const { field, sort } = useMemo(() => (sortModel?.length ? sortModel[0] : defaultSortModel), [sortModel])

  const {
    data: results,
    isLoading,
    isFetching,
    refetch,
  } = useFindCasesQuery({
    emailClient,
    page,
    userId,
    limit: rowsPerPage,
    sortField: field,
    sortDirection: sort === 'desc' ? -1 : 1,
  })

  useEffect(() => {
    if (apiRef.current) {
      apiRef.current.setExpandedDetailPanels([])
    }
  }, [results, page, apiRef])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getUserAndNavigate = async (userId) => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }

    const userUrl = `${process.env.REACT_APP_BASEURL}/kyt/users/${userId}/${emailClient}`
    fetch(userUrl, options)
      .then((res) => res.json())
      .then((res) => {
        if (res.success && res.data) {
          navigate(`/users/${userId}?type=${res.data.type}`)
        }
      })
      .catch((error) => {
        console.error('[CASES: caseUser] --> ', error)
      })
  }

  const handleSortModelChange = (sortModel) => {
    setSortModel(sortModel)
  }

  const columns = [
    {
      field: 'name',
      headerName: t('common:name'),
      minWidth: 200,
      renderCell: (params) => {
        return params.row.name ?? '---'
      },
      sortable: false,
    },
    {
      field: 'transactions',
      headerName: t('common:transactions'),
      minWidth: 100,
      renderCell: (params) => {
        return <Typography variant="number">{params.row.transactions || 0}</Typography>
      },
    },
    {
      field: 'generalAlerts',
      headerName: t('common:alerts'),
      minWidth: 60,
      renderCell: (params) => {
        return <Typography variant="number">{params.row.generalAlerts || 0}</Typography>
      },
    },
    {
      field: 'uniqueAlertCase',
      headerName: t('common:uniqueAlertCase'),
      minWidth: 100,
      renderCell: (params) => {
        return <TableChip noIcon={true} action={params.row.uniqueAlertCase ? 'yes' : 'no'} />
      },
      sortable: false,
    },
    {
      field: 'status',
      headerName: t('common:caseStatus'),
      minWidth: 210,
      renderCell: (params) => {
        return <TableChip noIcon={true} action={params.row.status} />
      },
    },
    {
      field: 'category',
      headerName: t('common:category'),
      minWidth: 220,
      sortable: false,
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      minWidth: 190,
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.updatedAt)}</Typography>
      },
    },
  ]

  function getDetailPanelContent({ row }) {
    return (
      <Box
        sx={{
          p: 1,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 1,
          border: '1px solid #ddd',
          borderRadius: '6px',
          backgroundColor: '#f9f9f9',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            gap: '4px',
            alignItems: 'center',
            borderRight: '1px solid #ddd',
            pr: 2,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
            ID:
          </Typography>
          <Link href={`/case-management/case/${row.id}`} title={row.maskId ? row.maskId : row.id} target="_blank">
            <Typography variant="number">{row.maskId ? row.maskId : row.id}</Typography>
          </Link>

          <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
            {t('common:userId')}:
          </Typography>
          <Link onClick={() => getUserAndNavigate(row.userId)}>
            <Typography variant="number">{row.userId}</Typography>
          </Link>

          <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
            {t('common:checklists')}:
          </Typography>
          <Typography variant="body2">{row.checklistInstanceIds?.length || 0}</Typography>
        </Box>

        <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: '4px', alignItems: 'center', pl: 2 }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
            {t('common:createdAt')}:
          </Typography>
          <Typography variant="body2">{toLocaleUTCDateString(row.createdAt)}</Typography>

          <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
            {t('common:assignedRoles')}:
          </Typography>
          <Typography variant="body2">
            {row.assignedRoles?.length ? row.assignedRoles.map((item) => item.roleName).join(', ') : '---'}
          </Typography>

          <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
            {t('common:label')}:
          </Typography>
          <Typography variant="body2">{row.label || '---'}</Typography>
        </Box>
      </Box>
    )
  }

  function getDetailPanelHeight() {
    return 'auto'
  }

  return (
    <Box className="kyb-detail-documents">
      <Box style={{ width: '100%', height: '100%' }}>
        <Box
          className="filter-bar"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box style={{ flex: 1 }} />

          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TablePagination
              labelRowsPerPage={[]}
              component="div"
              count={results?.pagination?.totalElements || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </Box>

          <Box style={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'flex-end', flex: 1 }}>
            <IconButton
              onClick={() => {
                if (apiRef.current) apiRef.current.setExpandedDetailPanels([])
                refetch()
              }}
              disabled={isLoading}
              title={t('common:refresh')}
            >
              <RefreshIcon color="action" />
            </IconButton>
          </Box>
        </Box>

        <DataGridPro
          getRowHeight={() => 'auto'}
          getRowId={(row) => row._id || row.id}
          rows={Array.isArray(results?.data) ? results?.data : []}
          columns={columns}
          apiRef={apiRef}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          slots={{
            columnMenu: CustomColumnMenu,
            loadingOverlay: CustomLoadingOverlay,
          }}
          hideFooter={true}
          autoHeight
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          loading={isLoading || isFetching}
        />
      </Box>
    </Box>
  )
}

export default TabUserCases

import { Box, Button, Chip, Fab, Grid, Link, Paper, TableContainer, TextField, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import { updateRequestsToAoR } from '../../../features/requestsToAoRSlice/requestsToAoRSlice.js'
import TabList from '@mui/lab/TabList'
import { OCDrawer } from './OCDrawer.jsx'
import '../../../components/common/datatable/datatable.scss'
import { TableChip } from '../../../components/utilities/TableChip.jsx'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { TabPanel } from '@mui/lab'
import { FilterModal } from '../../../components/utilities/FilterModal.jsx'
import MyDatePicker from '../../../components/utilities/MyDatePicker.jsx'
import { DataGridComponent } from '../../../components/common/datagrid/DataGridComponent.jsx'
import toaster from '../../../toaster.jsx'

const DatatableOppositionControl = ({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setIsLoading,
  requestStatus,
  specificRequest,
  handleSearchButton,
  handleResetButton,
  setRequestStatus,
  requestId,
  setRequestId,
  selectedDate,
  setSelectedDate,
  requestBy,
  setRequestBy,
}) => {
  const requestsToAoR = useSelector((state) => state.requestsToAoR)
  const { accessToken, email } = useSelector((state) => state.login)
  const requestsToAoREvaluatedBy = requestsToAoR?.requestsToAoREvaluatedBy
  const requestsToAoRRequestedBy = requestsToAoR?.requestsToAoRRequestedBy
  const [requestsToAoRType, setRequestsToAoRType] = useState('EVALUATED_BY_ME')
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [currentRequestToAoR, setCurrentRequestToAoR] = useState(null)
  const statusOptions = ['PENDING', 'REJECTED', 'ACCEPTED']

  useEffect(() => {
    if (specificRequest) {
      if (requestsToAoRRequestedBy?.length === 1 && requestsToAoREvaluatedBy?.length === 0) {
        setRequestsToAoRType('REQUESTED_BY_ME')
      }
    }
  })

  const fetchSuccessProcess = (id, approve) => {
    const isEvaluatedBy = requestsToAoRType === 'EVALUATED_BY_ME'
    const requestsToAoRToUpdate = isEvaluatedBy
      ? requestsToAoR?.requestsToAoREvaluatedBy
      : requestsToAoR?.requestsToAoRRequestedBy
    const requestsToAoRUpdated = requestsToAoRToUpdate.map((r) =>
      r._id === id ? { ...r, status: approve ? 'ACCEPTED' : 'REJECTED' } : r,
    )
    const auxNewState = { isRequestedBy: !isEvaluatedBy }
    auxNewState[isEvaluatedBy ? 'evaluatedBy' : 'requestedBy'] = requestsToAoRUpdated
    dispatch(updateRequestsToAoR(auxNewState))
    setIsLoading(false)
  }

  const handleApproveOrRejectRequest = (id, approve = false) => {
    setIsLoading(true)

    if (!process.env.REACT_APP_IS_DEMO) {
      const urlAdminCPO = `${process.env.REACT_APP_BASEURL}/requestToApproveOrReject/${id}?approve=${
        approve === true ? 'true' : 'false'
      }&email=${email}`
      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(urlAdminCPO, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            fetchSuccessProcess(id, approve)
            toaster.warning(res?.message ? res?.message : t('messages:updateSuccess'), {
              autoClose: 5000,
              closeButton: true,
            })
          } else {
            setIsLoading(false)
            toaster.error(res?.message ? res?.message : t('messages:updateError'), {
              autoClose: 5000,
              closeButton: true,
            })
          }
        })
        .catch((error) => {
          console.error('[DELETE REQUESTTOAOR ERROR] --> ', error)
        })
    } else {
      fetchSuccessProcess(id, approve)
    }
  }

  const deleteSuccessProcess = (id) => {
    const isEvaluatedBy = requestsToAoRType === 'EVALUATED_BY_ME'
    const requestsToAoRToUpdate = isEvaluatedBy
      ? requestsToAoR?.requestsToAoREvaluatedBy
      : requestsToAoR?.requestsToAoRRequestedBy
    const requestsToAoRUpdated = requestsToAoRToUpdate.filter((r) => r._id !== id)
    const auxNewState = { isRequestedBy: !isEvaluatedBy }
    auxNewState[isEvaluatedBy ? 'evaluatedBy' : 'requestedBy'] = requestsToAoRUpdated
    dispatch(updateRequestsToAoR(auxNewState))
    setIsLoading(false)
  }

  const handleDeleteRequestToAoR = (id) => {
    setIsLoading(true)

    if (!process.env.REACT_APP_IS_DEMO) {
      const urlAdminCPO = `${process.env.REACT_APP_BASEURL}/requestToApproveOrReject/${id}`
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }

      fetch(urlAdminCPO, options)
        .then((res) => {
          if (res.status !== 204) {
            setIsLoading(false)
            toaster.error(res?.message ? res?.message : t('messages:deletionError'), {
              autoClose: 5000,
              closeButton: true,
            })
          } else {
            toaster.success(t('messages:deletionSuccess'), {
              autoClose: false,
              closeButton: true,
            })
            deleteSuccessProcess(id)
          }
        })
        .catch((error) => {
          console.error('[DELETE REQUESTTOAOR ERROR] --> ', error)
        })
    } else {
      deleteSuccessProcess(id)
    }
  }

  const columnsR = useMemo(
    () => [
      {
        field: '_id',
        headerName: 'ID',
        renderCell: (params) => {
          return <Typography variant="number">{params.row._id}</Typography>
        },
      },
      {
        field: 'evaluatedBy',
        headerName: t('common:evaluatedBy'),
        renderCell: (params) => {
          return <p>{params.row.evaluatedBy ?? '---'}</p>
        },
      },
      {
        field: 'updatedAt',
        headerName: t('common:updatedAt'),
        renderCell: (params) => {
          return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.updatedAt)}</Typography>
        },
      },
      {
        field: 'status',
        headerName: t('common:status'),
        renderCell: (params) => {
          return <TableChip action={params.row.status.toLowerCase()} noIcon={true} />
        },
      },
      {
        field: 'elementId',
        headerName: t('common:elementId'),
        renderCell: (params) => {
          return (
            <TableChip
              action={
                params.row.body?.ruleId ??
                params.row.body?.userId ??
                params.row.body?.transactionId ??
                params.row.body?.ruleId ??
                '---'
              }
              noIcon={true}
            />
          )
        },
      },
      {
        field: 'actions',
        headerName: t('common:actions'),
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Box style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
              <Link
                onClick={() => {
                  setIsOpen(true)
                  const isEvaluatedBy = requestsToAoRType === 'EVALUATED_BY_ME'
                  const requests = isEvaluatedBy
                    ? requestsToAoR?.requestsToAoREvaluatedBy
                    : requestsToAoR?.requestsToAoRRequestedBy
                  const currentRequest = requests.filter((r) => r._id === params.row.id)[0]
                  setCurrentRequestToAoR(currentRequest)
                }}
              >
                {t('common:viewDetails')}
              </Link>

              <Fab variant="close" className="icon-fab" onClick={() => handleDeleteRequestToAoR(params.row.id)}>
                <DeleteOutlineOutlinedIcon />
              </Fab>
            </Box>
          )
        },
      },
    ],
    [],
  )

  const columnsE = useMemo(
    () => [
      {
        field: '_id',
        headerName: 'ID',
        renderCell: (params) => {
          return <Typography variant="number">{params.row._id}</Typography>
        },
      },
      {
        field: 'email',
        headerName: t('common:requestedBy'),
        renderCell: (params) => {
          return <p>{params.row.requestedBy ?? '---'}</p>
        },
      },
      {
        field: 'updatedAt',
        headerName: t('common:updatedAt'),
        renderCell: (params) => {
          return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.updatedAt)}</Typography>
        },
      },
      {
        field: 'status',
        headerName: t('common:status'),
        renderCell: (params) => {
          return <TableChip action={params.row.status.toLowerCase()} noIcon={true} />
        },
      },
      {
        field: 'elementId',
        headerName: t('common:elementId'),
        renderCell: (params) => {
          return (
            <TableChip
              action={
                params.row.body?.ruleId ??
                params.row.body?.userId ??
                params.row.body?.transactionId ??
                params.row.body?.ruleId ??
                '---'
              }
              noIcon={true}
            />
          )
        },
      },
      {
        field: 'manage',
        headerName: t('common:manage'),
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Box>
              {params.row.status === 'PENDING' && (
                <Box className="icon-global-small" style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
                  <Button
                    endIcon={<CheckOutlinedIcon />}
                    variant="outlinedChip"
                    onClick={() => {
                      handleApproveOrRejectRequest(params.row.id, true)
                    }}
                  >
                    {t('common:approve')}
                  </Button>
                  <Button
                    endIcon={<CloseOutlinedIcon />}
                    variant="outlinedChipError"
                    onClick={() => {
                      handleApproveOrRejectRequest(params.row.id, false)
                    }}
                  >
                    {t('common:reject')}
                  </Button>
                </Box>
              )}
              {params.row.status !== 'PENDING' && <p style={{ marginLeft: '.5rem' }}>---</p>}
            </Box>
          )
        },
      },
      {
        field: 'actions',
        headerName: t('common:actions'),
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Box style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
              <Link
                onClick={() => {
                  setIsOpen(true)
                  const isEvaluatedBy = requestsToAoRType === 'EVALUATED_BY_ME'
                  const requests = isEvaluatedBy
                    ? requestsToAoR?.requestsToAoREvaluatedBy
                    : requestsToAoR?.requestsToAoRRequestedBy
                  const currentRequest = requests.filter((r) => r._id === params.row.id)[0]
                  setCurrentRequestToAoR(currentRequest)
                }}
              >
                {t('common:viewDetails')}
              </Link>

              <Fab variant="close" className="icon-fab" onClick={() => handleDeleteRequestToAoR(params.row.id)}>
                <DeleteOutlineOutlinedIcon />
              </Fab>
            </Box>
          )
        },
      },
    ],
    [],
  )

  return (
    <Box>
      <TableContainer>
        <TabContext value={requestsToAoRType}>
          <TabList
            onChange={(evt, newValue) => setRequestsToAoRType(newValue)}
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
            sx={{ marginBottom: '1rem' }}
          >
            <Tab
              label={
                <Chip
                  label={t('common:requestedByMe')}
                  variant={requestsToAoRType === 'REQUESTED_BY_ME' ? 'sliderSelected' : 'slider'}
                />
              }
              value="REQUESTED_BY_ME"
            />
            <Tab
              label={
                <Chip
                  label={t('common:evaluatedByMe')}
                  variant={requestsToAoRType === 'EVALUATED_BY_ME' ? 'sliderSelected' : 'slider'}
                />
              }
              value="EVALUATED_BY_ME"
            />
          </TabList>

          <Paper elevation={0}>
            <TabPanel value="REQUESTED_BY_ME">
              <DataGridComponent
                rows={requestsToAoRRequestedBy || []}
                columns={columnsR}
                count={requestsToAoR?.requestsToAoRRequestedByPagination?.totalElements || 0}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                setIsLoading={setIsLoading}
                setModalOpen={setModalOpen}
                hasTabs="23rem"
                tableKey="opControlReq"
              />
            </TabPanel>
            <TabPanel value="EVALUATED_BY_ME">
              <DataGridComponent
                rows={requestsToAoREvaluatedBy || []}
                columns={columnsE}
                count={requestsToAoR?.requestsToAoREvaluatedByPagination?.totalElements || 0}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                setIsLoading={setIsLoading}
                setModalOpen={setModalOpen}
                hasTabs="23rem"
                tableKey="opControlEval"
              />
            </TabPanel>
          </Paper>
        </TabContext>
      </TableContainer>

      <OCDrawer
        open={{ isOpen, setIsOpen }}
        currentRequestToAoR={currentRequestToAoR}
        isEvaluatedBy={requestsToAoRType === 'EVALUATED_BY_ME'}
        handleAoRCurrentRequest={handleApproveOrRejectRequest}
      />

      <FilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleSearchButton}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">{t('common:by')} ID:</Typography>
            <TextField
              placeholder={`${t('common:insert')} ID`}
              required
              fullWidth
              value={requestId}
              onChange={(event) => setRequestId(event.target.value)}
              size="small"
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:date')}:
            </Typography>

            <MyDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:status')}:
            </Typography>
            <Grid
              container
              spacing={1}
              sx={{ maxWidth: '28rem', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
            >
              {statusOptions?.map((item, index) => (
                <Grid item key={index}>
                  <Button
                    onClick={() => {
                      return setRequestStatus(item)
                    }}
                    variant={requestStatus === item ? 'action' : 'label'}
                  >
                    {t(`common:${item}`)}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:email')}
            </Typography>
            <TextField
              size="small"
              value={requestBy}
              onChange={(event) => setRequestBy(event.target.value)}
              placeholder={`${t('common:insert')} ${t('common:email')}`}
            />
          </Box>
        </Box>
      </FilterModal>
    </Box>
  )
}

export default DatatableOppositionControl

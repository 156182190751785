import './Tabs.scss'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography, Link, IconButton, TablePagination } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Flags } from '../../../../components/common/flag/flags'
import { toCustomUTCDateString } from '../../../../components/common/time/timeHelper'
import { TableIcons } from '../../../../components/utilities/TableIcons'
import CustomColumnMenu from '../../../../components/common/datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../../../../components/common/datagrid/CustomLoadingOverlay'
import { useFindTransactionsByCaseQuery } from '../../../../features/cases/caseSlice'
import { selectUserMaster } from '../../../../features/kyb/kybCountrySlice'

const defaultSortModel = { field: 'createdTimestamp', sort: 'desc' }

const TabTransactions = ({ id }) => {
  const { t } = useTranslation(['common'])
  const apiRef = useGridApiRef()
  const [sortModel, setSortModel] = useState([defaultSortModel])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const { emailClient } = useSelector((state) => state.login)
  const userMaster = useSelector((state) => selectUserMaster(state, emailClient))

  const { field, sort } = useMemo(() => (sortModel?.length ? sortModel[0] : defaultSortModel), [sortModel])

  const {
    data: results,
    isLoading,
    isFetching,
    refetch,
  } = useFindTransactionsByCaseQuery({
    id,
    page,
    limit: rowsPerPage,
    sortField: field,
    sortDirection: sort === 'desc' ? -1 : 1,
  })

  useEffect(() => {
    if (apiRef.current) {
      apiRef.current.setExpandedDetailPanels([])
    }
  }, [results, page, apiRef])

  function getDetailPanelContent({ row }) {
    return (
      <Box
        sx={{
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          border: '1px solid #ddd',
          borderRadius: '6px',
          backgroundColor: '#f9f9f9',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
            p: 1,
            borderBottom: '1px solid #ddd',
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.85rem', color: '#333' }}>
            {t('common:transactionId')}:
          </Typography>
          <Link href={`/transactions/${row.transactionId}`} style={{ textDecoration: 'none' }} target="_blank">
            <Typography variant="number">{row.transactionId}</Typography>
          </Link>
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 2,
            p: 1,
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              gap: 1,
              alignItems: 'center',
              borderRight: '1px solid #ddd',
              pr: 2,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
              {t('common:originMethod')}:
            </Typography>
            <Typography variant="body2"> {row?.originPaymentDetails?.method}</Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
              {t('common:originAmount')}:
            </Typography>
            <Typography variant="body2">{row?.originAmountDetails?.transactionAmount?.toLocaleString()}</Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
              {t('common:originCurrency')}:
            </Typography>
            <Typography variant="body2">{row?.originAmountDetails?.transactionCurrency}</Typography>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: 1, alignItems: 'center', pl: 2 }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
              {t('common:destinationMethod')}:
            </Typography>
            <Typography variant="body2"> {row?.destinationPaymentDetails?.method}</Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
              {t('common:destinationAmount')}:
            </Typography>
            <Typography variant="body2">
              {row?.destinationAmountDetails?.transactionAmount?.toLocaleString()}
            </Typography>

            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
              {t('common:destinationCurrency')}:
            </Typography>
            <Typography variant="body2">{row?.destinationAmountDetails?.transactionCurrency}</Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  function getDetailPanelHeight() {
    return 'auto'
  }

  const columns = [
    {
      field: 'type',
      headerName: t('common:type'),
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return <Box>{params?.row?.type ? <TableIcons type={params?.row?.type} /> : '---'}</Box>
      },
    },
    {
      field: 'transactionState',
      headerName: t('common:state'),
      flex: 1,
      minWidth: 140,
      renderCell: (params) => {
        return <Box>{params?.row?.transactionState ? <TableIcons type={params?.row?.transactionState} /> : '---'}</Box>
      },
    },
    {
      field: 'createdTimestamp',
      headerName: t('common:date'),
      flex: 1,
      minWidth: 140,
      renderCell: ({ row }) => {
        return toCustomUTCDateString(row.timestamp, userMaster?.timezone)
      },
    },
    {
      field: 'hitRules',
      headerName: t('common:hitRules'),
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return params?.row?.hitRules.length
      },
    },
    {
      field: 'originUserId',
      headerName: t('common:originUserId'),
      flex: 1,
      minWidth: 140,
      renderCell: (params) => {
        return params?.row?.originUserId
      },
    },
    {
      field: 'originCountry',
      headerName: t('common:originCountry'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {Flags(params?.row?.originAmountDetails?.country, false, true)}
          </Box>
        )
      },
    },
    {
      field: 'destinationUserId',
      headerName: t('common:destinationUserId'),
      flex: 1,
      minWidth: 190,
    },
    {
      field: 'destinationCountry',
      headerName: t('common:destinationCountry'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {Flags(params?.row?.destinationAmountDetails?.country, false, true)}
          </Box>
        )
      },
    },
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSortModelChange = (sortModel) => {
    setSortModel(sortModel)
  }

  return (
    <Box className="kyb-detail-documents">
      <Box style={{ width: '100%', height: '100%' }}>
        <Box
          className="filter-bar"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box style={{ flex: 1 }} />

          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TablePagination
              labelRowsPerPage={[]}
              component="div"
              count={results?.data?.pagination?.totalElements || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </Box>

          <Box style={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'flex-end', flex: 1 }}>
            <IconButton
              onClick={() => {
                if (apiRef.current) apiRef.current.setExpandedDetailPanels([])
                refetch()
              }}
              disabled={isLoading}
              title={t('common:refresh')}
            >
              <RefreshIcon color="action" />
            </IconButton>
          </Box>
        </Box>

        <DataGridPro
          getRowHeight={() => 'auto'}
          getRowId={(row) => row._id || row.id}
          rows={Array.isArray(results?.data?.transactions) ? results?.data?.transactions : []}
          columns={columns}
          apiRef={apiRef}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          slots={{
            columnMenu: CustomColumnMenu,
            loadingOverlay: CustomLoadingOverlay,
          }}
          hideFooter={true}
          autoHeight
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          loading={isLoading || isFetching}
        />
      </Box>
    </Box>
  )
}

export default TabTransactions

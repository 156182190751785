import { createSlice } from '@reduxjs/toolkit'
import guenoApi from '../../services/guenoApi'
import { convertToUTCDate, formatDate } from '../../components/common/time/timeHelper'

const statusMapping = {
  pending: ['PENDING'],
  closed: ['CLOSED_WITH_ACTIONS', 'CLOSED_WITHOUT_ACTIONS'],
  opened: ['REVIEW_IN_PROGRESS', 'REVIEW_FINISHED'],
}

const buildCasesParams = (query = {}) => {
  const {
    caseId,
    status,
    alertAction,
    fromDate,
    toDate,
    userId,
    firstName,
    legalName,
    documentNumber,
    userType,
    isMyCases,
    roles,
    userKey,
    userValue,
    transactionKey,
    transactionValue,
    timezone = -3,
    searchText,
    searchType,
  } = query
  const params = {
    ...(caseId && { id: caseId }),
    ...(status && {
      status: status
        .split(',')
        .flatMap((key) => statusMapping[key])
        .join(','),
    }),
    ...(alertAction && { alertAction }),
    ...(userId && { userId }),
    ...(firstName && { firstName }),
    ...(legalName && { legalName }),
    ...(documentNumber && { documentNumber }),
    ...(userType && userType !== 'ALL' && { userType }),
    ...(isMyCases && { assignedRoles: roles }),
    ...(userKey && { userKey }),
    ...(userValue && { userValue }),
    ...(transactionKey && { transactionKey }),
    ...(transactionValue && { transactionValue }),
    ...(searchType && searchText && { [searchType]: searchText }),
  }

  if (fromDate && toDate) {
    params.fromDate = formatDate(convertToUTCDate(fromDate, 0))
    params.toDate = formatDate(convertToUTCDate(toDate, 0))
  }

  return params
}

export const defaultCasesFilters = {
  fromDate: null,
  toDate: null,
  firstName: '',
  legalName: '',
  documentNumber: '',
  userType: 'ALL',
  userKey: null,
  userValue: null,
  transactionKey: null,
  transactionValue: null,
}

export const defaultCasesParams = {
  page: 0,
  limit: 10,
  offset: 0,
  caseId: '',
  status: '',
  userId: '',
  isMyCases: false,
  searchType: 'id',
  searchText: '',
  alertAction: '',
  ...defaultCasesFilters,
}

export const caseSlice = createSlice({
  name: 'case',
  initialState: {
    cases: {},
    caseDetails: {},
    caseUser: {},
    userCases: {},
    caseAdminPanel: {},
    filters: {
      ...defaultCasesParams,
    },
    selectedCases: {},
  },
  reducers: {
    updateCases: (state, cases) => {
      state.cases = cases.payload
    },
    updateCaseDetails: (state, caseDetails) => {
      state.caseDetails = caseDetails.payload
    },
    updateCaseUser: (state, caseUser) => {
      state.caseUser = caseUser.payload
    },
    updateCasesFilters: (state, filters) => {
      state.filters = filters.payload
    },
    toogleShowMyCases: (state) => {
      state.filters = { ...state.filters, isMyCases: !state.filters.isMyCases }
    },
    resetCasesFilters: (state) => {
      state.filters = {
        ...defaultCasesParams,
        status: state.filters.status,
        alertAction: state.filters.alertAction,
        searchType: state.filters.searchType,
        searchText: state.filters.searchText,
      }
    },
    updateUserCases: (state, userCases) => {
      state.userCases = userCases.payload
    },
    updateCaseAdminPanel: (state, caseAdminPanel) => {
      state.caseAdminPanel = caseAdminPanel.payload
    },
    resetSelectedCases: (state) => {
      state.selectedCases = {}
    },
    updateSelectedCases: (state, data) => {
      const { item, added } = data.payload
      if (added) {
        state.selectedCases = { ...state.selectedCases, [item.id]: item }
      } else {
        const { [item.id]: _, ...newSelectedCases } = state.selectedCases
        state.selectedCases = newSelectedCases
      }
    },
    resetStatusFilter: (state) => {
      state.filters = {
        ...state.filters,
        status: defaultCasesParams.status,
      }
    },
    resetActionAlertFilter: (state) => {
      state.filters = {
        ...state.filters,
        alertAction: defaultCasesParams.alertAction,
      }
    },
  },
})

const endpoints = guenoApi.injectEndpoints({
  endpoints: (builder) => ({
    getCaseById: builder.query({
      query: ({ id }) => {
        return {
          url: `/caseManagement/${id}`,
        }
      },
      providesTags: ['case'],
    }),

    getReportByCaseId: builder.query({
      query: ({ caseId }) => {
        return {
          url: `/caseManagement/${caseId}/report`,
        }
      },
    }),
    findCases: builder.query({
      query: ({ emailClient, limit, offset, page, sortField = 'updatedAt', sortDirection = -1, ...query }) => {
        const params = buildCasesParams(query)
        return {
          url: `/caseManagement`,
          params: {
            emailClient,
            limit,
            offset,
            page,
            sortField,
            sortDirection,
            ...params,
          },
        }
      },
    }),
    findCasesTags: builder.query({
      query: () => {
        return {
          url: `/caseManagement/tags`,
        }
      },
      keepUnusedDataFor: 60,
    }),
    findTransactionsByCase: builder.query({
      query: ({ id, page, limit, sortField, sortDirection }) => {
        return {
          url: `/caseManagement/${id}/transactions/`,
          params: {
            page,
            limit,
            sortField,
            sortDirection,
          },
        }
      },
    }),
    findTransactionsAlertsByCase: builder.query({
      query: ({ emailClient, id, limit, page, type, sortField, sortDirection }) => {
        return {
          url: `/caseManagement/${id}/alerts`,
          params: {
            emailClient,
            limit,
            page,
            type,
            sortField,
            sortDirection,
          },
        }
      },
    }),
    getTimelineByCase: builder.query({
      query: ({ id, page, limit }) => {
        return {
          url: `/caseManagement/${id}/timeline`,
          params: {
            page,
            limit,
          },
        }
      },
    }),
    updateCaseComment: builder.mutation({
      query: ({ body, caseToClose }) => ({
        url: `/caseManagement/${caseToClose.id}/comments`,
        method: 'POST',
        body,
      }),
    }),
    updateCases: builder.mutation({
      query: ({ body }) => ({
        url: `/caseManagement/updateCases`,
        method: 'PATCH',
        body,
      }),
    }),
    updateCase: builder.mutation({
      query: ({ body, caseToClose }) => ({
        url: `/caseManagement/${caseToClose.id}`,
        method: 'PATCH',
        body,
      }),
    }),
    getCaseAdminPanel: builder.query({
      query: ({ emailClient }) => {
        return {
          url: `/caseAdminPanel/${emailClient}`,
        }
      },
    }),
    summarizeCase: builder.mutation({
      query: ({ caseId, lang }) => ({
        url: `/caseManagement/${caseId}/summarize/${lang}`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: ['case'],
    }),
  }),
})

export const {
  updateCases,
  updateCaseDetails,
  updateToAssign,
  updateCaseUser,
  updateCasesFilters,
  resetCasesFilters,
  toogleShowMyCases,
  updateUserCases,
  updateCaseAdminPanel,
  updateCaseAdminToAssing,
  updateSelectedCases,
  resetSelectedCases,
  resetStatusFilter,
  resetActionAlertFilter,
} = caseSlice.actions

export default caseSlice.reducer

export const {
  useGetCaseByIdQuery,
  useFindCasesQuery,
  useLazyFindCasesTagsQuery,
  useGetCaseAdminPanelQuery,
  useUpdateCaseCommentMutation,
  useUpdateCaseMutation,
  useFindTransactionsByCaseQuery,
  useGetTimelineByCaseQuery,
  useFindTransactionsAlertsByCaseQuery,
  useGetReportByCaseIdQuery,
  useUpdateCasesMutation,
  useSummarizeCaseMutation,
} = endpoints

import { Box, Button, Fab, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

const ModalUpdateCase = ({ onClose, onSubmit, labels = [], categories = [], caseStatusFinal = [] }) => {
  const { id } = useParams()
  const { t } = useTranslation(['common', 'messages'])
  const [comment, setComment] = useState('')
  const [status, setStatus] = useState('CLOSED_WITHOUT_ACTIONS')
  const [label, setLabel] = useState('')
  const [category, setCategory] = useState('')

  return (
    <Box className="modal">
      <Box className="modal-top">
        <Box className="modal-titles">
          <Typography variant="title">{t('common:updateCase')}</Typography>
        </Box>
        <Fab variant="close" onClick={onClose}>
          <CloseOutlinedIcon />
        </Fab>
      </Box>

      <Box className="modal-box-filters">
        <Box className="modal-box">
          <Typography variant="subtitle3">
            {t('messages:doYouWantUpdate')} {id}?
          </Typography>
        </Box>
        <Box className="modal-box">
          <InputLabel>{t('common:newStatus')}:</InputLabel>
          <Select fullWidth size="small" value={status} onChange={(e) => setStatus(e.target.value)} displayEmpty>
            <MenuItem disabled value="">
              <Typography variant="subtitle3">
                {t('common:select')} {t('common:newStatus')}
              </Typography>
            </MenuItem>
            {caseStatusFinal?.map((item) => (
              <MenuItem key={item} value={item}>
                {t(`common:${item}`)}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="modal-box">
          <InputLabel>{t('common:newLabel')}:</InputLabel>
          <Select fullWidth size="small" value={label} onChange={(e) => setLabel(e.target.value)} displayEmpty>
            <MenuItem disabled value="">
              <Typography variant="subtitle3">
                {t('common:select')} {t('common:newLabel')}
              </Typography>
            </MenuItem>
            {labels.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="modal-box">
          <InputLabel>{t('common:newCategory')}:</InputLabel>
          <Select fullWidth size="small" value={category} onChange={(e) => setCategory(e.target.value)} displayEmpty>
            <MenuItem disabled value="">
              <Typography variant="subtitle3">
                {t('common:select')} {t('common:newCategory')}
              </Typography>
            </MenuItem>
            {categories.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className="modal-box">
          <InputLabel>{t('common:updatingReasons')}:</InputLabel>
          <TextField
            multiline
            rows={4}
            size="small"
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            placeholder={`${t('messages:closingReason')} - ${t('messages:minCharacters')}`}
          />
        </Box>
        <Box className="modal-filter-buttons">
          <Button variant="outlinedGrey" onClick={onClose}>
            {t('common:close')}
          </Button>
          <Button
            disabled={comment.length < 5}
            variant="contained"
            onClick={() => onSubmit({ status, label, category, comment })}
          >
            {t('common:update')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ModalUpdateCase

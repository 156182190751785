import './securityParams.scss'

import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import EditIcon from '@mui/icons-material/Edit'
import { useDispatch, useSelector } from 'react-redux'
import { performSetSecurityParameters } from '../../../features/auth/loginSlice'
import AlertFetchSpinner from '../../../components/common/alertFetchSpinner/alertFetchSpinner'
import Spinner from '../../../components/common/spinner/spinner'
import { useNavigate } from 'react-router-dom'
import DataExportModal from '../../../components/common/dataExport/DataExportModal'
import {
  securityParameterReportDataMapper,
  securityParameterReportDataMapperForPDF,
} from '../../../components/common/dataExport/dataMappers/securityParametersReportDataMapper'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import { useTranslation } from 'react-i18next'

const SecurityParams = () => {
  const { accessToken, emailClient, securityParameters, activeServices } = useSelector((state) => state.login)
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [fetchMessage, setFetchMessage] = useState('')
  const [formData, setFormData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [edit, setEdit] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'messages'])
  const [showDownloadModal, setShowDownloadModal] = useState(false)

  if (!activeServices || !activeServices.includes('usermaster:emailClient')) navigate('/', { replace: true })

  const handleFetch = (error, message) => {
    setIsFetching(true)
    setFetchError(error)
    setFetchMessage(message)
    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const handleSwitch = (type) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [type]: {
        ...prevFormData[type],
        value: !prevFormData[type].value,
      },
    }))
  }

  const handleChange = (value, type) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [type]: {
        ...prevFormData[type],
        value,
      },
    }))
  }

  useEffect(() => {
    if (!securityParameters || !Object.keys(securityParameters).length) {
      getParameters()
    }
  }, [])

  useEffect(() => {
    if (securityParameters && Object.keys(securityParameters).length) {
      const additionalInfo = {
        checkPasswordHistoryLength: {
          value: securityParameters.checkPasswordHistoryLength || false,
          title: 'Enable Password History Length',
          description: 'Enable or disable the feature that ensures new passwords are not reused from previous ones.',
        },
        passwordHistoryLength: {
          value: securityParameters.passwordHistoryLength,
          title: 'Password History Length',
          description: 'The number of previously used passwords that cannot be chosen again.',
        },
        checkPasswordExpirationDays: {
          value: securityParameters.checkPasswordExpirationDays || false,
          title: 'Enable Password Expiration',
          description: 'Enable or disable password expiration based on a specific time frame.',
        },
        passwordExpirationDays: {
          value: securityParameters.passwordExpirationDays,
          title: 'Password Expiration Days',
          description: 'The duration in days after which a password expires and requires renewal.',
        },
        requireInactiveDuration: {
          value: securityParameters.requireInactiveDuration || false,
          title: 'Enable Inactive Duration',
          description: 'Specify whether a period of inactivity is necessary for user accounts.',
        },
        maxDaysToInactive: {
          value: securityParameters.maxDaysToInactive,
          title: 'Max Days to Inactive',
          description:
            'The maximum number of days a user account can remain inactive before it is considered inactive.',
        },
        maxFailedAttemptsRequired: {
          value: securityParameters.maxFailedAttemptsRequired || false,
          title: 'Enable Max Failed Attempts',
          description: 'Enable or disable a limit on the maximum number of consecutive failed login attempts.',
        },
        maxFailedAttempts: {
          value: securityParameters.maxFailedAttempts,
          title: 'Max Failed Attempts',
          description:
            'The maximum number of consecutive failed login attempts allowed before additional action is taken.',
        },
        requirePasswordChangeOnFirstLogin: {
          value: securityParameters.requirePasswordChangeOnFirstLogin || false,
          title: 'Require Password Change on First Login',
          description:
            'Determine whether users are required to change their password when logging in for the first time. Coming soon.',
        },
        dashboardActivity: {
          value: securityParameters.dashboardActivity || false,
          title: 'Enable Dashboard Activity',
          description: 'Enable or disable dashboard activity based on a specific time frame. Coming soon.',
        },
        activityTime: {
          value: securityParameters.activityTime,
          title: 'Activity Time',
          description: 'The duration in minutes in which the session expires after dashboard inactivity. Coming soon.',
        },
      }
      setErrorMessage('')
      setFormData(additionalInfo)
    }
  }, [securityParameters])

  const getParameters = () => {
    setIsLoading(true)
    const urlUserMaster = `${process.env.REACT_APP_BASEURL}/usermaster/${emailClient}`
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }

    fetch(process.env.REACT_APP_IS_DEMO ? '../data/usermaster.json' : urlUserMaster, options)
      .then((res) => res.json())
      .then((res) => {
        if (res.data === 'Forbidden resource') {
          setErrorMessage(t('messages:dontHavePermissions'))
          setIsLoading(false)
        }
        if (!res.success && res.message && res.message.length !== 0) {
          setIsLoading(false)
          handleFetch(true, res.message ? res.message : t('messages:updateSecurityError'))
          throw Error(res.message.toString())
        } else {
          setErrorMessage('')
          dispatch(performSetSecurityParameters(res))
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error('[GET SECURITY PARAMETERS ERROR] --> ', error)
        handleFetch(true, error.message ? error.message : t('messages:updateSecurityError'))
      })
  }

  const updateParameters = (data) => {
    setIsLoading(true)
    if (!process.env.REACT_APP_IS_DEMO) {
      const urlUserMaster = `${process.env.REACT_APP_BASEURL}/usermaster/${emailClient}`
      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(urlUserMaster, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res.success && res.message && res.message.length !== 0) {
            setIsLoading(false)
            handleFetch(true, res.message ? res.message : t('messages:updateSecurityError'))
            throw Error(res.message.toString())
          }
          dispatch(performSetSecurityParameters(res.data))
          setIsLoading(false)
          handleFetch(false, t('messages:updateSecuritySuccess'))
        })
        .catch((error) => {
          console.error('[UPDATE SECURITY PARAMETERS ERROR] --> ', error)
          handleFetch(true, error.message ? error.message : t('messages:updateSecurityError'))
          getParameters()
        })
    } else {
      dispatch(performSetSecurityParameters(data))
      setIsLoading(false)
      handleFetch(false, t('messages:updateSecuritySuccess'))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      checkPasswordHistoryLength: formData.checkPasswordHistoryLength.value,
      passwordHistoryLength: formData.passwordHistoryLength.value,
      checkPasswordExpirationDays: formData.checkPasswordExpirationDays.value,
      passwordExpirationDays: formData.passwordExpirationDays.value,
      requireInactiveDuration: formData.requireInactiveDuration.value,
      maxDaysToInactive: formData.maxDaysToInactive.value,
      maxFailedAttemptsRequired: formData.maxFailedAttemptsRequired.value,
      maxFailedAttempts: formData.maxFailedAttempts.value,
      requirePasswordChangeOnFirstLogin: formData.requirePasswordChangeOnFirstLogin.value,
      dashboardActivity: formData.dashboardActivity.value,
      activityTime: formData.activityTime.value,
    }

    setEdit(false)
    updateParameters(data)
  }

  const renderTooltip = (title) => (
    <Tooltip title={title}>
      <Button>
        <InfoIcon className="security-info-icon" />
      </Button>
    </Tooltip>
  )

  const NumberSelect = ({ min, max, defaultValue, type, disabled }) => {
    const numbers = Array.from({ length: max - min + 1 }, (_, index) => index + 1)

    return (
      <Select
        disabled={!disabled || !edit}
        id={type}
        key={type}
        size="small"
        style={{ minWidth: '4rem' }}
        defaultValue={defaultValue ?? ''}
        onChange={(e) => handleChange(e.target.value, type)}
      >
        {numbers.map((number) => (
          <MenuItem key={number} value={number}>
            {number}
          </MenuItem>
        ))}
      </Select>
    )
  }

  return (
    <Box className={`${isLoading && 'spinner-transition'} filter`}>
      {isLoading && <Spinner noTransform />}

      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} />}

      <Box className="component-title-wrapper" sx={{ marginBottom: '0rem!important' }}>
        <Box className="component-title">
          <Typography variant="h2">{t('common:securityParameters')}</Typography>
        </Box>
        <Typography variant="subtitle3">
          {/* Loren ipsum dolor sit amet Loren ipsum dolor sit amet Loren  */}
        </Typography>
      </Box>

      {errorMessage ? (
        <Box className="security-error">
          <Button size="small" variant="outlined" color="error">
            {errorMessage}
          </Button>
        </Box>
      ) : (
        <Box className="alert-settings-overflow">
          <Box className="security-bar">
            <Box>
              <Button
                variant="outlined"
                disabled={!activeServices.includes('reportsitem') || !formData}
                onClick={() => setShowDownloadModal(true)}
                endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
              >
                {t('common:exportList')}
              </Button>
            </Box>
            <Button
              variant="outlinedButtonSuccess"
              onClick={() => {
                setEdit(true)
              }}
            >
              <EditIcon />
            </Button>
          </Box>

          <Paper elevation={0} className="security-buttons">
            <Box className="security-grid">
              <Box>
                <Box className="security-flex">
                  <p>{formData.checkPasswordHistoryLength?.title || ''}</p>
                  <Box className="security-flex-label">
                    {renderTooltip(
                      <Box className="back-tip">
                        <label className="security-items-info" color="inherit">
                          {formData.checkPasswordHistoryLength?.description || ''}
                        </label>
                      </Box>,
                    )}
                    <Switch
                      disabled={!edit}
                      color="secondary"
                      name="checkPasswordHistoryLength"
                      id="checkPasswordHistoryLength"
                      onChange={() => handleSwitch('checkPasswordHistoryLength')}
                      checked={formData.checkPasswordHistoryLength?.value || false}
                    />
                  </Box>
                </Box>
                <Divider style={{ margin: '.6rem 0rem' }} />
              </Box>

              <Box>
                <Box className="security-flex">
                  <p>{formData.passwordHistoryLength?.title || ''}</p>
                  <Box className="security-flex-label">
                    {renderTooltip(
                      <Box className="back-tip">
                        <label className="security-items-info" color="inherit">
                          {formData.passwordHistoryLength?.description || ''}
                        </label>
                      </Box>,
                    )}
                    <NumberSelect
                      min={1}
                      max={12}
                      defaultValue={formData.passwordHistoryLength?.value || 1}
                      type="passwordHistoryLength"
                      disabled={formData.checkPasswordHistoryLength?.value}
                    />
                  </Box>
                </Box>
                <Divider style={{ margin: '.6rem 0rem' }} />
              </Box>

              <Box>
                <Box className="security-flex">
                  <p>{formData.checkPasswordExpirationDays?.title || ''}</p>
                  <Box className="security-flex-label">
                    {renderTooltip(
                      <Box className="back-tip">
                        <label className="security-items-info" color="inherit">
                          {formData.checkPasswordExpirationDays?.description || ''}
                        </label>
                      </Box>,
                    )}
                    <Switch
                      color="secondary"
                      disabled={!edit}
                      name="checkPasswordExpirationDays"
                      onChange={() => handleSwitch('checkPasswordExpirationDays')}
                      checked={formData.checkPasswordExpirationDays?.value || false}
                    />
                  </Box>
                </Box>

                <Divider style={{ margin: '.6rem 0rem' }} />
              </Box>

              <Box>
                <Box className="security-flex">
                  <p>{formData.passwordExpirationDays?.title || ''}</p>
                  <Box className="security-flex-label">
                    {renderTooltip(
                      <Box className="back-tip">
                        <label className="security-items-info" color="inherit">
                          {formData.passwordExpirationDays?.description || ''}
                        </label>
                      </Box>,
                    )}
                    <NumberSelect
                      min={1}
                      max={180}
                      defaultValue={formData.passwordExpirationDays?.value || 1}
                      type="passwordExpirationDays"
                      disabled={formData.checkPasswordExpirationDays?.value}
                    />
                  </Box>
                </Box>
                <Divider style={{ margin: '.6rem 0rem' }} />
              </Box>

              <Box>
                <Box className="security-flex">
                  <p>{formData.requireInactiveDuration?.title || ''}</p>
                  <Box className="security-flex-label">
                    {renderTooltip(
                      <Box className="back-tip">
                        <label className="security-items-info" color="inherit">
                          {formData.requireInactiveDuration?.description || ''}
                        </label>
                      </Box>,
                    )}
                    <Switch
                      color="secondary"
                      disabled={!edit}
                      name="requireInactiveDuration"
                      onChange={() => handleSwitch('requireInactiveDuration')}
                      checked={formData.requireInactiveDuration?.value || false}
                    />
                  </Box>
                </Box>
                <Divider style={{ margin: '.6rem 0rem' }} />
              </Box>

              <Box>
                <Box className="security-flex">
                  <p>{formData.maxDaysToInactive?.title || ''}</p>
                  <Box className="security-flex-label">
                    {renderTooltip(
                      <Box className="back-tip">
                        <label className="security-items-info" color="inherit">
                          {formData.maxDaysToInactive?.description || ''}
                        </label>
                      </Box>,
                    )}
                    <NumberSelect
                      min={1}
                      max={180}
                      defaultValue={formData.maxDaysToInactive?.value || 1}
                      type="maxDaysToInactive"
                      disabled={formData.requireInactiveDuration?.value}
                    />
                  </Box>
                </Box>

                <Divider style={{ margin: '.6rem 0rem' }} />
              </Box>

              <Box>
                <Box className="security-flex">
                  <p>{formData.maxFailedAttemptsRequired?.title || ''}</p>
                  <Box className="security-flex-label">
                    {renderTooltip(
                      <Box className="back-tip">
                        <label className="security-items-info" color="inherit">
                          {formData.maxFailedAttemptsRequired?.description || ''}
                        </label>
                      </Box>,
                    )}
                    <Switch
                      color="secondary"
                      disabled={!edit}
                      name="maxFailedAttemptsRequired"
                      onChange={() => handleSwitch('maxFailedAttemptsRequired')}
                      checked={formData.maxFailedAttemptsRequired?.value || false}
                    />
                  </Box>
                </Box>

                <Divider style={{ margin: '.6rem 0rem' }} />
              </Box>

              <Box>
                <Box className="security-flex">
                  <p>{formData.maxFailedAttempts?.title || ''}</p>
                  <Box className="security-flex-label">
                    {renderTooltip(
                      <Box className="back-tip">
                        <label className="security-items-info" color="inherit">
                          {formData.maxFailedAttempts?.description || ''}
                        </label>
                      </Box>,
                    )}
                    <NumberSelect
                      min={1}
                      max={30}
                      defaultValue={formData.maxFailedAttempts?.value || 1}
                      type="maxFailedAttempts"
                      disabled={formData.maxFailedAttemptsRequired?.value}
                    />
                  </Box>
                </Box>

                <Divider style={{ margin: '.6rem 0rem' }} />
              </Box>

              <Box>
                <Box className="security-flex">
                  <p> {formData.dashboardActivity?.title || ''}</p>
                  <Box className="security-flex-label">
                    {renderTooltip(
                      <Box className="back-tip">
                        <label className="security-items-info" color="inherit">
                          {formData.dashboardActivity?.description || ''}
                        </label>
                      </Box>,
                    )}
                    <Switch
                      color="secondary"
                      disabled={!edit}
                      name="dashboardActivity"
                      onChange={() => handleSwitch('dashboardActivity')}
                      checked={formData.dashboardActivity?.value || false}
                    />
                  </Box>
                </Box>

                <Divider style={{ margin: '.6rem 0rem' }} />
              </Box>

              <Box>
                <Box className="security-flex">
                  <p>{formData.activityTime?.title || ''}</p>
                  <Box className="security-flex-label">
                    {renderTooltip(
                      <Box className="back-tip">
                        <label className="security-items-info" color="inherit">
                          {formData.activityTime?.description || ''}
                        </label>
                      </Box>,
                    )}
                    <NumberSelect
                      min={1}
                      max={30}
                      defaultValue={formData.activityTime?.value || 1}
                      type="activityTime"
                      disabled={formData.dashboardActivity?.value}
                    />
                  </Box>
                </Box>

                <Divider style={{ margin: '.6rem 0rem' }} />
              </Box>

              <Box>
                <Box className="security-flex">
                  <p>{formData.requirePasswordChangeOnFirstLogin?.title || ''}</p>
                  <Box className="security-flex-label">
                    {renderTooltip(
                      <Box className="back-tip">
                        <label className="security-items-info" color="inherit">
                          {formData.requirePasswordChangeOnFirstLogin?.description || ''}
                        </label>
                      </Box>,
                    )}
                    <Switch
                      disabled={!edit}
                      color="secondary"
                      name="requirePasswordChangeOnFirstLogin"
                      onChange={() => handleSwitch('requirePasswordChangeOnFirstLogin')}
                      checked={formData.requirePasswordChangeOnFirstLogin?.value || false}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            {edit ? (
              <Box className="modal-buttons" sx={{ marginTop: '1rem' }}>
                <Button
                  onClick={() => {
                    setEdit(false)
                    return getParameters()
                  }}
                  size="small"
                  variant="outlinedGrey"
                >
                  {t('common:cancel')}
                </Button>
                <Button size="small" variant="contained" type="submit" onClick={(e) => handleSubmit(e)}>
                  {t('common:save')}
                </Button>
              </Box>
            ) : (
              ''
            )}
          </Paper>
        </Box>
      )}
      <Modal open={showDownloadModal} onClose={() => setShowDownloadModal(false)}>
        <div>
          <DataExportModal
            show={showDownloadModal}
            onClose={() => setShowDownloadModal(false)}
            data={formData}
            dataMapper={securityParameterReportDataMapper}
            dataMapperForPDF={securityParameterReportDataMapperForPDF}
            fileName={'security_parameters_report'}
          />
        </div>
      </Modal>
    </Box>
  )
}

export default SecurityParams

import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'

const useUserTableConfig = ({ tableKey, defaultConfig = {} }) => {
  const baseUrl = process.env.REACT_APP_USER_CONFIG_BASEURL
  const { accessToken, email } = useSelector((state) => state.login)
  const localConfig = localStorage.getItem(`tableConfig-${tableKey}`)

  const [config, setConfig] = useState(localConfig ? JSON.parse(localConfig) : defaultConfig)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const fetchConfigFromDB = useCallback(async () => {
    const localIndicatorKey = `tableConfig-fetchAttempted-${tableKey}`
    const fetchAttempted = localStorage.getItem(localIndicatorKey)

    if (fetchAttempted) {
      console.warn('Fetch already attempted. Skipping further requests.')
      return // Salimos si ya intentamos hacer el fetch
    }

    try {
      setLoading(true)
      const response = await fetch(`${baseUrl}/user-config/${email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })

      if (response.ok) {
        const dbConfig = await response.json()
        const userConfig = dbConfig?.data?.[tableKey] || defaultConfig

        // Actualizar estado y guardar en localStorage
        setConfig(userConfig)
        localStorage.setItem(`tableConfig-${tableKey}`, JSON.stringify(userConfig))
      } else if (response.status === 404) {
        // Si no hay registro en la base de datos, usamos la configuración predeterminada
        console.warn('Configuration not found in DB. Using default configuration.')
        setConfig(defaultConfig)
        localStorage.setItem(`tableConfig-${tableKey}`, JSON.stringify(defaultConfig))
      } else {
        throw new Error('Failed to fetch configuration from DB')
      }
    } catch (err) {
      console.error('Error fetching configuration from DB:', err)
      setError(err.message)
    } finally {
      setLoading(false)
      localStorage.setItem(localIndicatorKey, 'true') // Marcamos que ya intentamos el fetch
    }
  }, [baseUrl, email, accessToken, defaultConfig, tableKey])

  const saveConfig = useCallback(
    async (newConfig) => {
      setLoading(true)
      setError(null)
      const updatedConfig = { ...config, ...newConfig }

      try {
        const response = await fetch(`${baseUrl}/user-config/${email}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ config: { [tableKey]: updatedConfig } }),
        })

        if (response.status === 404) {
          // Si el registro no existe, se crea
          const createResponse = await fetch(`${baseUrl}/user-config`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ email, config: { [tableKey]: updatedConfig } }),
          })

          if (!createResponse.ok) console.error('Failed to create configuration in DB')
        } else if (!response.ok) {
          console.warn('Failed to save configuration to DB')
        }
      } catch (err) {
        console.warn('Error saving configuration to DB:', err)
        setError(err.message)
      } finally {
        setLoading(false)
      }

      setConfig(updatedConfig)
      localStorage.setItem(`tableConfig-${tableKey}`, JSON.stringify(updatedConfig))
    },
    [baseUrl, email, accessToken, config, tableKey],
  )

  useEffect(() => {
    if (!localStorage.getItem(`tableConfig-${tableKey}`)) {
      // Si no hay configuración en localStorage, cargar desde la base de datos
      fetchConfigFromDB()
    }
  }, [fetchConfigFromDB, tableKey])

  return {
    config,
    saveConfig,
    loading,
    error,
  }
}

export default useUserTableConfig

import '../reports.scss'
import '../../../components/common/datatable/datatable.scss'
import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import Spinner from '../../../components/common/spinner/spinner'
import { useReports } from '../../pages-hooks/useReports'
import DataExportModal from '../../../components/common/dataExport/DataExportModal'
import { useState } from 'react'
import {
  loginReportDataMapper,
  loginReportDataMapperForPDF,
} from '../../../components/common/dataExport/dataMappers/loginReportDataMapper'
import { useSelector } from 'react-redux'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import { useTranslation } from 'react-i18next'
import CustomNoRowsOverlay from '../../../components/common/datagrid/CustomNoRowsOverlay'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'

const createRow = (report) => {
  return {
    id: report?._id,
    status: report?.activityDetails?.actionResult,
    timestamp: report?.createdAt,
    email: report?.emailUser,
    type: report?.activityDetails?.actionType,
  }
}

const LoginReport = ({
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
  isLoading,
  setIsLoading,
  setModalOpen,
}) => {
  const { activeServices } = useSelector((state) => state.login)
  const allReports = useReports('login_user_activity', setIsLoading, isLoading)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const { t } = useTranslation(['common', 'messages'])

  return (
    <Box>
      {isLoading && <Spinner noTransform />}
      <TableContainer>
        <Box className="filter-bar">
          <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setModalOpen(true)}>
            {t('common:filters')}
          </Button>
          <Box className="title">
            {activeServices?.includes('reportsitem') && allReports?.data?.length > 0 && (
              <Button
                disabled={!activeServices?.includes('reportsitem') || !allReports?.data?.length}
                onClick={() => setShowDownloadModal(true)}
                endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
                variant="outlined"
              >
                {/* <DownloadIcon /> */}
                <Typography variant="title2">{t('common:exportReport')}</Typography>
              </Button>
            )}
          </Box>
        </Box>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('common:id')}</TableCell>
              <TableCell>{t('common:user')}</TableCell>
              <TableCell>{t('common:action')}</TableCell>
              <TableCell>{t('common:status')}</TableCell>
              <TableCell>{t('common:date')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(allReports?.data) ? (
              allReports?.data
                ?.map((report) => createRow(report))
                .map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.type ? row.type : 'LOGIN'}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>{buildTimeStringFromTimestamp(row.timestamp)}</TableCell>
                    </TableRow>
                  )
                })
            ) : (
              <Box className="table-custom-overlay">
                <CustomNoRowsOverlay />
              </Box>
            )}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage={[]}
          component="div"
          count={allReports?.pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Modal open={showDownloadModal} onClose={() => setShowDownloadModal(false)}>
        <div>
          <DataExportModal
            show={showDownloadModal}
            onClose={() => setShowDownloadModal(false)}
            data={allReports?.data}
            fileName={'login-report'}
            dataMapper={loginReportDataMapper}
            dataMapperForPDF={loginReportDataMapperForPDF}
            typeDataExport={'reports'}
            keyReports={'login_user_activity'}
          />
        </div>
      </Modal>
    </Box>
  )
}

export default LoginReport

export const getOrderedColumns = (columnOrder, columns) => {
  if (!Array.isArray(columnOrder) || columnOrder.length === 0) {
    return columns
  }
  return columnOrder.map((field) => columns.find((col) => col.field === field)).filter(Boolean)
}

export const updateListOrder = (list, { targetIndex, oldIndex }) => {
  if (!Array.isArray(list)) {
    console.error('Provided list is not an array:', list)
    return list
  }

  const updatedList = [...list]
  const movedItem = updatedList.splice(oldIndex, 1)[0]
  updatedList.splice(targetIndex, 0, movedItem)

  return updatedList
}

export const getVisibilityModel = (visibleColumns, columns) => {
  if (!visibleColumns) {
    return columns.reduce((acc, col) => {
      acc[col.field] = true
      return acc
    }, {})
  }
  return visibleColumns
}

import * as yup from 'yup'
import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextInputForm } from '../../../../components/common/form'
import ModalTitle from '../../../../components/common/Modal/ModalTitle'

const AddMetadataModal = ({ setOpen, onAdd }) => {
  const { t } = useTranslation(['common', 'messages'])
  const schema = yup.object().shape({
    name: yup
      .string()
      .required()
      .matches(/^[a-z][a-z0-9]*$/, 'Only numbers and lowercase letters are allowed'),
    label: yup.string().required(),
  })
  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      label: '',
    },
  })

  return (
    <Box className="modal">
      <ModalTitle setModalOpen={setOpen} title={t('messages:kyb.settings.metadata.modal')} />

      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, marginBottom: 2 }}>
        <Box>
          <TextInputForm
            name="name"
            control={control}
            label={t('messages:kyb.settings.metadata.name')}
            placeholder={t('messages:kyb.settings.metadata.name')}
          />
        </Box>
        <Box>
          <TextInputForm
            name="label"
            control={control}
            label={t('messages:kyb.settings.metadata.label')}
            placeholder={t('messages:kyb.settings.metadata.label')}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '1rem',
        }}
      >
        <Button variant="outlinedGrey" disabled={!formState.isDirty} onClick={() => setOpen(false)}>
          {t('common:cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          disabled={!formState.isDirty || !formState.isValid}
          onClick={handleSubmit(onAdd)}
        >
          {t('common:save')}
        </LoadingButton>
      </Box>
    </Box>
  )
}

export default AddMetadataModal

import { useState } from 'react'
import { Box, Button, Popover } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import FilterMenuContent from './FilterMenuContent'

const FilterMenu = ({ onApply, onReset, label, defaultValue, options }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const isPopoverOpen = Boolean(anchorEl)
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleApply = (params) => {
    handleMenuClose()
    onApply(params)
  }

  const handleClear = () => {
    handleMenuClose()
    onReset()
  }

  return (
    <Box>
      <Button
        onClick={handleMenuClick}
        endIcon={isPopoverOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        variant="label"
      >
        {label}
      </Button>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            boxShadow: 'none',
            backgroundColor: 'transparent',
          },
        }}
      >
        <Box sx={{ p: 1 }}>
          <FilterMenuContent
            onApply={handleApply}
            onReset={handleClear}
            options={options}
            defaultValue={defaultValue}
          />
        </Box>
      </Popover>
    </Box>
  )
}

export default FilterMenu

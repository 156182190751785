import './Tabs.scss'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  Box,
  Typography,
  Link,
  IconButton,
  TablePagination,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Modal,
  Fab,
} from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { buildTimeStringFromTimestamp } from '../../../../components/common/time/timeHelper'
import { TableChip } from '../../../../components/utilities/TableChip'
import CustomColumnMenu from '../../../../components/common/datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../../../../components/common/datagrid/CustomLoadingOverlay'
import { useUpdateAlertStatusMutation } from '../../../../features/generalAlerts/generalAlertsSlice'
import toaster from '../../../../toaster'
import { useFindTransactionsAlertsByCaseQuery } from '../../../../features/cases/caseSlice'
import { Edit } from '@mui/icons-material'

const defaultSortModel = { field: 'createdAt', sort: 'desc' }

const alertStatusValues = [
  'PENDING',
  'REVIEW_IN_PROGRESS',
  'REVIEW_FINISHED',
  'CLOSED_WITH_ACTIONS',
  'CLOSED_WITHOUT_ACTIONS',
  'FALSE_POSITIVE',
]

const TabTransactionsAlerts = ({ id, hideActions }) => {
  const { t } = useTranslation(['common'])
  const apiRef = useGridApiRef()
  const { emailClient } = useSelector((state) => state.login)
  const [sortModel, setSortModel] = useState([defaultSortModel])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [status, setStatus] = useState('')
  const [alertToUpdate, setAlertToUpdate] = useState()
  const [openUpdateAlert, setOpenUpdateAlert] = useState(false)
  const { field, sort } = useMemo(() => (sortModel?.length ? sortModel[0] : defaultSortModel), [sortModel])

  const {
    data: results,
    isLoading,
    refetch,
    isFetching: isFetchingTransactionsAlerts,
  } = useFindTransactionsAlertsByCaseQuery({
    emailClient,
    id,
    limit: rowsPerPage,
    page,
    type: 'transactionRule',
    sortField: field,
    sortDirection: sort === 'desc' ? -1 : 1,
  })
  const [updateAlertStatus] = useUpdateAlertStatusMutation()
  useEffect(() => {
    if (apiRef.current) {
      apiRef.current.setExpandedDetailPanels([])
    }
  }, [results, page, apiRef])

  const handleAlertStatusChange = async () => {
    try {
      await updateAlertStatus({ alertToUpdate: alertToUpdate, status }).unwrap()
      refetch()
      setOpenUpdateAlert(!openUpdateAlert)
      toaster.success(t('messages:updateSuccess'))
    } catch (error) {
      toaster.error(t('messages:updateError'))
      console.error(error?.data?.data)
    }
  }

  const columns = [
    {
      field: 'ruleId',
      headerName: t('common:ruleId'),
      width: 70,
      renderCell: (params) => <Typography variant="number">{params?.row?.details?.ruleId ?? '---'}</Typography>,
    },
    {
      field: 'ruleName',
      headerName: t('common:ruleName'),
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return <Box>{params?.row?.details?.ruleName ?? '---'}</Box>
      },
    },
    {
      field: 'status',
      headerName: t('common:status'),
      minWidth: 120,
      renderCell: (params) => <TableChip noIcon={true} action={params?.row?.status ?? 'PENDING'} />,
    },
    {
      field: 'alertDetails',
      headerName: t('common:alertDetails'),
      width: 200,
      height: 40,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              height: '100%',
              maxWidth: 250,
              maxHeight: 60,
              overflowX: 'auto',
              overflowY: 'auto',
              whiteSpace: 'normal',
              display: 'flex',
              alignItems:
                params?.row?.details?.alertDetails && params?.row?.details?.alertDetails.length > 60
                  ? 'start'
                  : 'center',
            }}
          >
            {params?.row?.details?.alertDetails ?? '---'}
          </Box>
        )
      },
    },
    {
      field: 'ruleAction',
      headerName: t('common:ruleAction'),
      width: 120,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box className="table-body-cell">
            {params?.row?.details?.ruleAction ? <TableChip action={params?.row?.details?.ruleAction} /> : '---'}
          </Box>
        )
      },
    },
    {
      hide: hideActions,
      field: 'transactionId',
      headerName: t('common:transactionId'),
      width: 200,
      sortable: false,
      renderCell: (params) =>
        params?.row?.details?.transactionId ? (
          <Link href={`/transactions/${params.row.details.transactionId}`} target="_blank">
            {params.row.details.transactionId}
          </Link>
        ) : (
          '---'
        ),
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      width: 170,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant="number" sx={{ fontSize: '0.7rem' }}>
            {buildTimeStringFromTimestamp(params?.row?.updatedAt)?.trim()}
          </Typography>
        )
      },
    },
    {
      field: 'action',
      headerName: ' ',
      sortable: false,
      width: 10,
      resizable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <IconButton
            color="action"
            size="small"
            onClick={() => {
              setOpenUpdateAlert(true)
              setAlertToUpdate(params?.row?._id)
            }}
          >
            <Edit />
          </IconButton>
        )
      },
    },
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSortModelChange = (sortModel) => {
    setSortModel(sortModel)
  }

  function getDetailPanelContent({ row }) {
    return (
      <Box
        sx={{
          p: 1,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 0.5,
          border: '1px solid #ddd',
          borderRadius: '6px',
          backgroundColor: '#f9f9f9',
          width: '100%',
          height: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            p: 1,
            borderRight: { xs: 'none', sm: '1px solid #ddd' },
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.8rem', color: '#333' }}>
            {t('common:alertId')}:
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
            {row?._id}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, p: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.8rem', color: '#333' }}>
            {t('common:ruleInstanceId')}:
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
            {row?.details?.ruleInstanceId}
          </Typography>
        </Box>
      </Box>
    )
  }

  function getDetailPanelHeight() {
    return 'auto'
  }

  return (
    <Box className="kyb-detail-documents">
      <Box style={{ width: '100%', height: '100%' }}>
        <Box
          className="filter-bar"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box style={{ flex: 1 }} />

          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TablePagination
              labelRowsPerPage={[]}
              component="div"
              count={results?.data?.pagination?.totalElements || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </Box>

          <Box style={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'flex-end', flex: 1 }}>
            <IconButton
              onClick={() => {
                if (apiRef.current) apiRef.current.setExpandedDetailPanels([])
                refetch()
              }}
              disabled={isLoading || isFetchingTransactionsAlerts}
              title={t('common:refresh')}
            >
              <RefreshIcon color="action" />
            </IconButton>
          </Box>
        </Box>

        <DataGridPro
          getRowId={(row) => row._id || row.id}
          rows={Array.isArray(results?.data?.transactionsAlerts) ? results?.data?.transactionsAlerts : []}
          columns={columns}
          apiRef={apiRef}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          getRowHeight={() => 'auto'}
          slots={{
            columnMenu: CustomColumnMenu,
            loadingOverlay: CustomLoadingOverlay,
          }}
          hideFooter={true}
          autoHeight
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          loading={isLoading || isFetchingTransactionsAlerts}
        />
      </Box>
      <Modal
        open={openUpdateAlert}
        onClose={() => {
          setOpenUpdateAlert(!openUpdateAlert)
        }}
      >
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:updateAlert')}</Typography>
            </Box>
            <Fab variant="close" onClick={() => setOpenUpdateAlert(false)}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="modal-box-filters">
            <Box className="modal-box">
              <Typography variant="subtitle3">
                {t('messages:doYouWantUpdateAlert')} {alertToUpdate}?
              </Typography>
            </Box>
          </Box>

          <Box className="modal-box">
            <InputLabel>{t('common:newStatus')}:</InputLabel>
            <Select
              fullWidth
              size="small"
              value={status}
              onChange={(e) => {
                if (e.target.value) {
                  setStatus(e.target.value)
                } else setStatus('')
              }}
              displayEmpty
            >
              <MenuItem disabled value="">
                <Typography variant="subtitle3">
                  {t('common:select')} {t('common:newStatus')}
                </Typography>
              </MenuItem>
              {alertStatusValues?.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className="modal-filter-buttons">
            <Button variant="outlinedGrey" onClick={() => setOpenUpdateAlert(!openUpdateAlert)}>
              {t('common:close')}
            </Button>
            <Button disabled={status === ''} variant="contained" onClick={handleAlertStatusChange}>
              {t('common:save')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default TabTransactionsAlerts

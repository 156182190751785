import { useEffect, useMemo, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFindUserMasterQuery, useUpdateUserMasterMutation } from '../../../services/guenoApi'
import KybListOfStrings from './KybListOfStrings'
import KybChecksDefaultOrder from './KybChecksDefaultOrder.json'
import toaster from '../../../toaster'
import KybRiskMatrixSettings from './KybRiskMatrixSettings'
import KybSettingsMetadata from './KybSettingsMetadata/KybSettingsMetadata'

const KybSettingsTabPanel = ({ currentCountry }) => {
  const { t } = useTranslation(['common', 'messages'])
  const { emailClient } = useSelector((state) => state.login)
  const { data: userMaster, refetch } = useFindUserMasterQuery({ emailClient })
  const [updateUserMaster, { isLoading: isLoadingUpdateUserMaster }] = useUpdateUserMasterMutation({
    fixedCacheKey: 'userMasterUpdate',
  })
  const [currentBussinesChecks, setCurrentBussinesChecks] = useState([])
  const [currentPersonChecks, setCurrentPersonChecks] = useState([])

  const setKybData = async (field, value) => {
    try {
      const { data } = await updateUserMaster({
        emailClient,
        body: {
          [field]: value,
        },
      })
      if (data.success && data.data) {
        toaster.success(t('messages:updateSuccess'))
        refetch()
      } else {
        toaster.error(t('messages:updateError'))
      }
    } catch (error) {
      toaster.error(t('messages:updateError'))
    }
  }

  const setKybChecks = async (subject, kybChecks) => {
    try {
      const { data } = await updateUserMaster({
        emailClient,
        body: {
          kybChecks: { [currentCountry]: { [subject]: kybChecks } },
        },
      })
      if (data.success && data.data) {
        toaster.success(t('messages:updateSuccess'))
        refetch()
      } else {
        toaster.error(t('messages:updateError'))
      }
    } catch (error) {
      toaster.error(t('messages:updateError'))
    }
  }

  const setRiskMatrixSettings = async (riskMatrixSettings) => {
    try {
      const { data } = await updateUserMaster({
        emailClient,
        body: {
          riskMatrixSkipNoDataFound: riskMatrixSettings.riskMatrixSkipNoDataFound,
          runRiskMatrixOnUpdate: riskMatrixSettings.runRiskMatrixOnUpdate,
        },
      })
      if (data.success && data.data) {
        toaster.success(t('messages:updateSuccess'))
        refetch()
      } else {
        toaster.error(t('messages:updateError'))
      }
    } catch (error) {
      console.error('Error updating userMaster', error)
      toaster.error(t('messages:updateError'))
    }
  }

  const onSaveKybMetadata = async ({ metadata }) => {
    setKybData(
      'kybMetadata',
      metadata.reduce((acc, item) => {
        acc[item.name] = item.label
        return acc
      }, {}),
    )
  }

  const riskMatrixSkipNoDataFound = useMemo(() => userMaster?.riskMatrixSkipNoDataFound ?? false, [userMaster])
  const runRiskMatrixOnUpdate = useMemo(() => userMaster?.runRiskMatrixOnUpdate ?? false, [userMaster])
  const kybMetadata = useMemo(() => {
    if (userMaster?.kybMetadata) {
      return Object.keys(userMaster.kybMetadata).map((key) => ({ name: key, label: userMaster.kybMetadata[key] }))
    }

    return []
  }, [userMaster?.kybMetadata])

  useEffect(() => {
    if (userMaster?.kybChecks) {
      setCurrentBussinesChecks(userMaster.kybChecks[currentCountry]?.BUSINESS || [])
      setCurrentPersonChecks(userMaster.kybChecks[currentCountry]?.PERSON || [])
    } else {
      setCurrentBussinesChecks(KybChecksDefaultOrder[currentCountry]?.BUSINESS || [])
      setCurrentPersonChecks(KybChecksDefaultOrder[currentCountry]?.PERSON || [])
    }
  }, [userMaster?.kybChecks, currentCountry])

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Accordion variant="medium" defaultExpanded sx={{ marginBottom: '10px' }}>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <Typography variant="h3">{t('common:riskMatrix')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <KybRiskMatrixSettings
              riskMatrixSkipNoDataFound={riskMatrixSkipNoDataFound}
              runRiskMatrixOnUpdate={runRiskMatrixOnUpdate}
              setRiskMatrixSettings={setRiskMatrixSettings}
              isLoading={isLoadingUpdateUserMaster}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion variant="medium" defaultExpanded sx={{ marginBottom: '10px' }}>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <Typography variant="h3">{t('messages:kyb.settings.metadata.title')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <KybSettingsMetadata
              onSave={onSaveKybMetadata}
              defaultValues={kybMetadata}
              isLoading={isLoadingUpdateUserMaster}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion variant="medium" defaultExpanded sx={{ marginBottom: '10px' }}>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <Typography variant="h3">{t('common:businessSegments')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <KybListOfStrings
              stringsList={userMaster?.businessSegments}
              setStringsList={(value) => setKybData('businessSegments', value)}
              columns={2}
              isLoading={isLoadingUpdateUserMaster}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion variant="medium" defaultExpanded sx={{ marginBottom: '10px' }}>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <Typography variant="h3">{t('common:clientTypes')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <KybListOfStrings
              stringsList={userMaster?.clientTypes}
              setStringsList={(value) => setKybData('clientTypes', value)}
              columns={2}
              isLoading={isLoadingUpdateUserMaster}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion variant="medium" defaultExpanded sx={{ marginBottom: '10px' }}>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <Typography variant="h3">{t('common:clusters')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <KybListOfStrings
              stringsList={userMaster?.clusters}
              setStringsList={(value) => setKybData('clusters', value)}
              columns={2}
              isLoading={isLoadingUpdateUserMaster}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion variant="medium" defaultExpanded sx={{ marginBottom: '10px', '&:before': { display: 'none' } }}>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography variant="h3">{t('common:checks')}</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '2rem',
            }}
          >
            <Box width="100%">
              <KybListOfStrings
                title="business"
                stringsList={currentBussinesChecks}
                setStringsList={(newChecks) => setKybChecks('BUSINESS', newChecks)}
                isLoading={isLoadingUpdateUserMaster}
                translateStrings
                inputsDisabled
                noAddRemove
              />
            </Box>
            <Box width="100%">
              <KybListOfStrings
                title="person"
                stringsList={currentPersonChecks}
                setStringsList={(newChecks) => setKybChecks('PERSON', newChecks)}
                isLoading={isLoadingUpdateUserMaster}
                translateStrings
                inputsDisabled
                noAddRemove
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  )
}

export default KybSettingsTabPanel

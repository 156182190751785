import './generalAlerts.scss'
import { Box, Button, IconButton, Link, Modal, Paper, TablePagination, Typography } from '@mui/material'
import { buildTimeStringFromTimestamp, convertToUTCDate, formatDate } from '../../../components/common/time/timeHelper'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { TableChip } from '../../../components/utilities/TableChip'
import {
  resetGeneralAlertsFilters,
  updateGeneralAlertsFilters,
  useFindGeneralAlertsQuery,
  useUpdateAlertStatusMutation,
} from '../../../features/generalAlerts/generalAlertsSlice'
import toaster from '../../../toaster'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import RefreshIcon from '@mui/icons-material/Refresh'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import FiltersAlertsForm from './FiltersAlertsForm'
import EditAlertForm from './EditAlertForm'
import dayjs from 'dayjs'
import { selectUserMaster } from '../../../features/kyb/kybCountrySlice'
import CustomColumnMenu from '../../../components/common/datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../../../components/common/datagrid/CustomLoadingOverlay'
import useUserTableConfig from '../../../components/hooks/useUserTableConfig'
import {
  getOrderedColumns,
  getVisibilityModel,
  updateListOrder,
} from '../../../components/common/datatable/tableUtils/tableUtils'

const AlertsPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'messages'])
  const { emailClient } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.generalAlerts.filters)
  const { fromDate, toDate, id, status, type } = queryState
  const [openUpdateAlert, setOpenUpdateAlert] = useState(false)
  const [alertToUpdate, setAlertToUpdate] = useState()
  const [page, setPage] = useState(0)
  const [modalFiltersOpen, setModalFiltersOpen] = useState(false)
  const apiRef = useGridApiRef()
  const userMaster = useSelector((state) => selectUserMaster(state, emailClient))
  const timezone = useMemo(
    () => (typeof userMaster?.timezone !== 'number' ? 0 : userMaster?.timezone),
    [userMaster?.timezone],
  )

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('common:alertId'),
        flex: queryState.type ? undefined : 1,
        minWidth: queryState.type ? 220 : undefined,
        renderCell: (params) => <Typography variant="number">{params.row.id}</Typography>,
        sortable: false,
      },
      {
        field: 'updatedAt',
        headerName: t('common:updatedAt'),
        flex: queryState.type ? undefined : 1,
        minWidth: queryState.type ? 180 : undefined,
        renderCell: (params) => (
          <Typography variant="number">{buildTimeStringFromTimestamp(params.row.updatedAt)}</Typography>
        ),
        sortable: false,
      },
      {
        field: 'createdAt',
        headerName: t('common:createdAt'),
        flex: queryState.type ? undefined : 1,
        minWidth: queryState.type ? 180 : undefined,
        renderCell: (params) => (
          <Typography variant="number">{buildTimeStringFromTimestamp(params.row.createdAt)}</Typography>
        ),
        sortable: false,
      },
      {
        field: 'status',
        headerName: t('common:status'),
        flex: queryState.type ? undefined : 1,
        minWidth: queryState.type ? 120 : undefined,
        renderCell: (params) => <TableChip noIcon={true} action={params.row.status ?? 'PENDING'} />,
        sortable: false,
      },
      ...(queryState.type === 'aml'
        ? [
            {
              field: 'userId',
              headerName: t('common:userId'),
              minWidth: 220,
              sortable: false,
              renderCell: (params) => <Box className="cellOverflow">{params.row.details?.consumerUserId}</Box>,
            },
            {
              field: 'name',
              headerName: t('common:name'),
              minWidth: 210,
              sortable: false,
              renderCell: (params) => <Box className="cellOverflow">{params.row.details?.name}</Box>,
            },
            {
              field: 'countries',
              headerName: t('common:country'),
              minWidth: 150,
              sortable: false,
              renderCell: (params) => <Box className="cellOverflow">{params.row.details?.countries}</Box>,
            },
            {
              field: 'types',
              headerName: t('common:types'),
              minWidth: 150,
              sortable: false,
              renderCell: (params) => <Box className="cellOverflow">{params.row.details?.types?.join(', ')}</Box>,
            },
            {
              field: 'details',
              headerName: t('common:details'),
              minWidth: 210,
              sortable: false,
              filterable: false,
              renderCell: (params) => (
                <Link
                  onClick={() => window.open(params.row.details?.shareUrl, '_blank', 'rel=noopener noreferrer')}
                  disabled={!(params.row.details && params.row.details?.shareUrl)}
                >
                  {t('common:viewAmlDetails')}
                </Link>
              ),
            },
          ]
        : []),
      ...(queryState.type === 'amlCrypto'
        ? [
            {
              field: 'protocol',
              headerName: t('common:protocol'),
              minWidth: 120,
              sortable: false,
              renderCell: (params) => <Box>{params.row.protocol ?? '---'}</Box>,
            },
            {
              field: 'address',
              headerName: t('common:address'),
              minWidth: 120,
              sortable: false,
              renderCell: (params) => <Box>{params.row.address ?? '---'}</Box>,
            },
            {
              field: 'score',
              headerName: t('common:score'),
              minWidth: 120,
              sortable: false,
              renderCell: (params) => <Box>{params.row?.check?.risk?.score ?? '---'}</Box>,
            },
            {
              field: 'level',
              headerName: t('common:level'),
              minWidth: 120,
              sortable: false,
              renderCell: (params) => <Box>{params.row?.check?.risk?.level ?? '---'}</Box>,
            },
            {
              field: 'category',
              headerName: t('common:category'),
              minWidth: 120,
              sortable: false,
              renderCell: (params) => (
                <Box>
                  {params.row.details.category
                    ? params.row.check.activity.suspicious_activity.category?.join(', ')
                    : '---'}
                </Box>
              ),
            },
          ]
        : []),
      ...(queryState.type === 'transactionRule'
        ? [
            {
              field: 'transactionId',
              headerName: t('common:transactionId'),
              minWidth: 300,
              sortable: false,
              renderCell: ({ row }) => {
                return row.details?.transactionId ? (
                  <Link component={RouterLink} to={`/transactions/${row.details.transactionId}`} target="_blank">
                    {row.details?.transactionId}
                  </Link>
                ) : (
                  '---'
                )
              },
            },
            {
              field: 'ruleId',
              headerName: t('common:ruleId'),
              minWidth: 160,
              sortable: false,
              renderCell: (params) => <Typography variant="number">{params.row.details?.ruleId ?? '---'}</Typography>,
            },
            {
              field: 'ruleInstanceId',
              headerName: t('common:ruleInstanceId'),
              minWidth: 180,
              sortable: false,
              renderCell: (params) => (
                <Typography variant="number">{params.row.details?.ruleInstanceId ?? '---'}</Typography>
              ),
            },
            {
              field: 'ruleName',
              headerName: t('common:ruleName'),
              minWidth: 120,
              renderCell: (params) => <Box>{params.row.details?.ruleName ?? '---'}</Box>,
              sortable: false,
            },
            {
              field: 'alertDetails',
              headerName: t('common:alertDetails'),
              minWidth: 180,
              sortable: false,
              renderCell: (params) => (
                <Box
                  sx={{
                    maxWidth: 250,
                    overflowX: 'auto',
                    overflowY: 'auto',
                    whiteSpace: 'normal',
                    display: 'flex',
                    alignItems:
                      params.row.details.alertDetails && params.row.details?.alertDetails?.length > 60
                        ? 'start'
                        : 'center',
                  }}
                >
                  {params.row.details?.alertDetails ?? '---'}
                </Box>
              ),
            },
          ]
        : []),
      {
        field: 'update',
        headerName: t('common:updateStatus'),
        minWidth: 140,
        renderCell: (params) => (
          <Button
            variant="outlinedChip"
            onClick={() => {
              setOpenUpdateAlert(true)
              setAlertToUpdate(params.row.id)
            }}
          >
            {t('common:update')}
          </Button>
        ),
        sortable: false,
      },
    ],
    [],
  )

  const {
    config,
    saveConfig,
    loading: configLoading,
  } = useUserTableConfig({
    tableKey: 'caseAlerts',
    defaultConfig: {
      rowsPerPage: 25,
      pinnedColumns: { left: [], right: [] },
      columnOrder: columns.map((c) => c.field),
    },
  })

  const {
    data: generalAlertsResponse,
    isLoading,
    refetch,
    isFetching: isFetchingGeneralAlert,
  } = useFindGeneralAlertsQuery({
    emailClient,
    id,
    limit: config.rowsPerPage,
    offset: config.rowsPerPage * page,
    page,
    fromDate,
    toDate,
    type,
    status,
    timezone,
  })
  const defaultFromDate = queryState.fromDate ? dayjs(queryState.fromDate) : null
  const defaultToDate = queryState.toDate ? dayjs(queryState.toDate) : null

  const { generalAlerts, pagination } = useMemo(
    () => ({
      generalAlerts: generalAlertsResponse?.data,
      pagination: generalAlertsResponse?.pagination,
    }),
    [generalAlertsResponse],
  )

  const [updateAlertStatus, { isLoading: isLoadingUpdateStatus }] = useUpdateAlertStatusMutation()

  const handleAlertStatusChange = async (values) => {
    const { status } = values
    try {
      await updateAlertStatus({ alertToUpdate: alertToUpdate, status }).unwrap()
      refetch()
      setOpenUpdateAlert(!openUpdateAlert)
      toaster.success(t('messages:updateSuccess'))
    } catch (error) {
      toaster.error(t('messages:updateError'))
      console.error(error)
    }
  }

  const handleDispatch = (values) => {
    const { fromDate, fromTime, toDate, toTime, ...filters } = values

    const combinedFromDate = fromDate
      ? dayjs(fromDate)
          .hour(parseInt(fromTime.split(':')[0]))
          .minute(parseInt(fromTime.split(':')[1]))
          .second(0)
      : null

    const combinedToDate = toDate
      ? dayjs(toDate)
          .hour(parseInt(toTime.split(':')[0]))
          .minute(parseInt(toTime.split(':')[1]))
          .second(59)
      : null

    return dispatch(
      updateGeneralAlertsFilters({
        fromDate: combinedFromDate ? formatDate(convertToUTCDate(combinedFromDate, timezone)) : null,
        toDate: combinedToDate ? formatDate(convertToUTCDate(combinedToDate, timezone)) : null,
        ...filters,
      }),
    )
  }

  const handleResetButton = () => {
    setPage(0)
    dispatch(resetGeneralAlertsFilters())
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    saveConfig({ rowsPerPage: newRowsPerPage })

    setPage(0)
  }

  const orderedColumns = useMemo(
    () => getOrderedColumns(config.columnOrder, columns),
    [JSON.stringify(config.columnOrder), columns],
  )

  const handleColumnOrderChange = (params) => {
    const updatedOrder = updateListOrder(config.columnOrder, params)
    if (JSON.stringify(config.columnOrder) !== JSON.stringify(updatedOrder)) {
      saveConfig({ columnOrder: updatedOrder })
    }
  }

  const visibleColumnsModel = useMemo(
    () => getVisibilityModel(config.visibleColumns, columns),
    [config.visibleColumns, columns],
  )

  return (
    <Box className="filter">
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t('common:alerts')}</Typography>
        </Box>
        <Paper elevation={0}>
          <Box style={{ width: '100%', height: '100%' }}>
            <Box className="filter-bar">
              <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setModalFiltersOpen(true)}>
                {t('common:filters')}
              </Button>

              <TablePagination
                labelRowsPerPage={[]}
                component="div"
                count={pagination?.totalElements || 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={config.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
              />

              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Button
                  variant="label"
                  endIcon={<MenuOutlinedIcon />}
                  onClick={() => apiRef.current.showPreferences('columns')}
                >
                  {t('common:showHideColumns')}
                </Button>
                <IconButton onClick={refetch} disabled={isLoading} title={t('common:refresh')}>
                  <RefreshIcon color="action" />
                </IconButton>
              </Box>
            </Box>

            <DataGridPro
              getRowHeight={() => 'auto'}
              getRowId={(row) => row._id || row.id}
              rows={generalAlerts || []}
              columns={orderedColumns}
              apiRef={apiRef}
              slots={{
                columnMenu: CustomColumnMenu,
                loadingOverlay: CustomLoadingOverlay,
              }}
              hideFooter={true}
              autoHeight
              loading={isLoading || isFetchingGeneralAlert || configLoading}
              disableColumnResize={true}
              columnOrder={config.columnOrder}
              onColumnOrderChange={handleColumnOrderChange}
              pinnedColumns={config.pinnedColumns}
              onPinnedColumnsChange={(newPinnedColumns) => saveConfig({ pinnedColumns: newPinnedColumns })}
              columnVisibilityModel={visibleColumnsModel}
              onColumnVisibilityModelChange={(newModel) => saveConfig({ visibleColumns: newModel })}
            />
          </Box>

          <Modal open={!!openUpdateAlert} onClose={() => setOpenUpdateAlert()}>
            <div>
              <EditAlertForm
                setModalOpen={setOpenUpdateAlert}
                alertToUpdate={alertToUpdate}
                onSubmit={handleAlertStatusChange}
                isLoading={isLoadingUpdateStatus}
              />
            </div>
          </Modal>

          <Modal open={modalFiltersOpen} onClose={() => setModalFiltersOpen(false)}>
            <div>
              <FiltersAlertsForm
                defaultValues={{
                  id: queryState.id,
                  status: queryState.status,
                  type: queryState.type,
                  fromDate: defaultFromDate || null,
                  fromTime: defaultFromDate ? defaultFromDate.format('HH:mm') : '00:00',
                  toDate: defaultToDate || null,
                  toTime: defaultToDate ? defaultToDate.format('HH:mm') : '23:59',
                }}
                setModalOpen={setModalFiltersOpen}
                handleResetButton={handleResetButton}
                handleDispatch={handleDispatch}
                setPage={setPage}
              />
            </div>
          </Modal>
        </Paper>
      </Box>
    </Box>
  )
}

export default AlertsPage

import './datatable.scss'
import { useSelector } from 'react-redux'
import { Link, Typography } from '@mui/material'
import { useEcommerceRisks } from '../../../pages/pages-hooks/useEcommerceRisks'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { useTranslation } from 'react-i18next'
import { DataGridComponent } from '../datagrid/DataGridComponent'
import { useMemo } from 'react'

const DatatableEcommerceRisk = ({ page, setPage, rowsPerPage, setRowsPerPage, setIsLoading, setModalOpen }) => {
  const { emailClient } = useSelector((state) => state.login)
  const ecommerceRisks = useEcommerceRisks(emailClient, setIsLoading)
  const { t } = useTranslation(['common'])

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        renderCell: (params) => {
          return (
            <Link href={`/ecommerceRisk/${params.row._id}`}>
              <Typography variant="number">{params.row._id}</Typography>
            </Link>
          )
        },
      },
      {
        field: 'name',
        headerName: 'Fullname',
        renderCell: (params) => {
          return (
            <p>
              {params.row.userDetails?.name?.lastName ?? '---'} ${params.row.userDetails?.name?.firstName ?? '---'}
            </p>
          )
        },
      },
      {
        field: 'documentNumber',
        headerName: t('common:documentNumber'),
        renderCell: (params) => {
          return <Typography variant="number">{params.row.userDetails?.documentNumber ?? '---'}</Typography>
        },
      },
      {
        field: 'address',
        headerName: t('common:shippingAddress'),
        renderCell: (params) => {
          return params.row.userDetails?.address
            ? params.row.userDetails.address.firstLine +
                ' ' +
                params.row.userDetails.address.city +
                ' ' +
                params.row.userDetails.address.state +
                ' ' +
                params.row.userDetails.address.postcode +
                ' ' +
                params.row.userDetails.address.country
            : '---'
        },
      },
      {
        field: 'alerts',
        headerName: t('common:alerts'),
        renderCell: (params) => {
          return <p>{params.row.alerts ? params.row.alerts?.length : 0}</p>
        },
      },
      {
        field: 'createdAt',
        headerName: t('common:createdAt'),
        renderCell: (params) => {
          return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.createdAt)}</Typography>
        },
      },
    ],
    [],
  )

  return (
    <DataGridComponent
      rows={(Array.isArray(ecommerceRisks?.data) && ecommerceRisks?.data.length !== 0 && ecommerceRisks?.data) || []}
      columns={columns}
      count={ecommerceRisks?.paginationResult?.totalElements || 0}
      page={page}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      setIsLoading={setIsLoading}
      setModalOpen={setModalOpen}
      tableKey="eccomerceRisk"
    />
  )
}

export default DatatableEcommerceRisk

import '../home/home.scss'
import '../onboarding/onboarding.scss'
import './kyb.scss'
import { Box, Tab, Typography, Paper } from '@mui/material'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import '../../components/common/alertFetchSpinner/alertFetchSpinner.scss'
import AlertFetchSpinner from '../../components/common/alertFetchSpinner/alertFetchSpinner'
import Spinner from '../../components/common/spinner/spinner'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { setIsLoading } from '../../features/kyb/kybCountrySlice'
import SettingsIcon from '@mui/icons-material/Settings'
import KybNotificationsSettings from './components/KybNotificationsSettings'
import { useRestartKybMutation } from '../../services/guenoApi'
import AlertFetchSpinnerAutoClose from '../../components/common/alertFetchSpinner/AlertFetchSpinnerAutoClose'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import { KybNotificationsDatatable } from './components/KybNotifications/KybNotificationsDatatable'

const CustomTabList = styled(TabList)({
  borderBottom: '1px solid #1C282026',
  '& .MuiTabs-flexContainer': {
    gap: '1rem !important',
    display: 'flex',
  },
})

const KybNotifications = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { tab } = useParams()
  const { isLoading } = useSelector((state) => state.kybCountry)
  const queryState = useSelector((state) => state.kybNotifications.filters)
  const { limit, offset } = queryState
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [isLocallyLoading, setIsLocallyLoading] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation(['common', 'messages'])
  const actualTab = useMemo(() => tab || 'pending', [tab])
  const today = new Date().setDate(new Date().getDate())
  const formattedDate = dayjs(today).endOf('day').format('YYYY-MM-DDTHH:mm:ss')

  const [
    restartKyb,
    { isLoading: restartIsLoading, originalArgs, isError: restartIsError, isSuccess: restartIsSuccess },
  ] = useRestartKybMutation()

  const handleChange = (_, newValue) => {
    navigate(`/kyb/notifications/${newValue}`)
  }

  useEffect(() => {
    if (isLoading !== isLocallyLoading) dispatch(setIsLoading(isLocallyLoading))
  }, [dispatch, isLoading, isLocallyLoading])

  return (
    <Box className={`${isLoading && 'spinner-transition'} filter`}>
      {isLoading && <Spinner noTransform />}
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} spinner={isProcessing} />}
      {(restartIsError || restartIsSuccess) && (
        <AlertFetchSpinnerAutoClose
          message={restartIsError ? 'There was an error restarting the KYB' : 'KYB restarted successfully'}
          error={restartIsError}
          spinner={true}
        />
      )}

      <Box className="component-title-wrapper">
        <Box className="component-title" display="flex">
          <Typography variant="h2">{t('messages:kyb.menu.notifications')}</Typography>
        </Box>
        <Typography variant="subtitle3"></Typography>
      </Box>
      <Paper elevation={0}>
        <TabContext value={actualTab}>
          <Box
            className="component-title"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: '1.5rem' }}
          >
            <CustomTabList onChange={handleChange}>
              <Tab label={t('common:pending')} value="pending" />
              <Tab label={t('common:completed')} value="completed" />
              <Tab label={t('common:canceled')} value="canceled" />
              <Tab value="settings" />
            </CustomTabList>
            <Button
              variant="outlined"
              onClick={() => navigate(`/kyb/notifications/settings`)}
              endIcon={<SettingsIcon />}
            >
              <Typography variant="title2">{t('common:settings')}</Typography>
            </Button>
          </Box>
          <TabPanel value="pending">
            <KybNotificationsDatatable
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setModalOpen={setModalOpen}
              modalOpen={modalOpen}
              formattedDate={formattedDate}
              tableKey="kybPendingNotifications"
              filterStatus="PENDING"
              isProcessing={isProcessing}
              setFetchMessage={setFetchMessage}
              setIsProcessing={setIsProcessing}
              setIsFetching={setIsFetching}
              setFetchError={setFetchError}
            />
          </TabPanel>
          <TabPanel value="completed">
            <KybNotificationsDatatable
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              limit={limit}
              offset={offset}
              setModalOpen={setModalOpen}
              modalOpen={modalOpen}
              formattedDate={formattedDate}
              tableKey="kybCompletedNotifications"
              filterStatus="COMPLETED"
              isProcessing={isProcessing}
              setFetchMessage={setFetchMessage}
              setIsProcessing={setIsProcessing}
              setIsFetching={setIsFetching}
              setFetchError={setFetchError}
            />
          </TabPanel>
          <TabPanel value="canceled">
            <KybNotificationsDatatable
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              limit={limit}
              offset={offset}
              setModalOpen={setModalOpen}
              modalOpen={modalOpen}
              formattedDate={formattedDate}
              tableKey="kybCanceledNotifications"
              filterStatus="CANCELED"
              isProcessing={isProcessing}
              setFetchMessage={setFetchMessage}
              setIsProcessing={setIsProcessing}
              setIsFetching={setIsFetching}
              setFetchError={setFetchError}
            />
          </TabPanel>
          <TabPanel value="settings">
            <KybNotificationsSettings
              setIsProcessing={setIsProcessing}
              setFetchError={setFetchError}
              setFetchMessage={setFetchMessage}
              setIsFetching={setIsFetching}
            />
          </TabPanel>
        </TabContext>
      </Paper>
    </Box>
  )
}

export default KybNotifications

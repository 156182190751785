import { Box, TextField, Typography } from '@mui/material'
import { useController } from 'react-hook-form'
import { RenderTooltip } from '../../../utilities/RenderTooltip'
import styles from './RiskRangeNumberInputForm.module.scss'
import SelectInputForm from '../SelectInputForm/SelectInputForm'
import { useTranslation } from 'react-i18next'

const options = [
  { value: 'APPROVED', label: 'APPROVED' },
  { value: 'DENIED', label: 'DENIED' },
  { value: 'IN_PROGRESS', label: 'IN_PROGRESS' },
  { value: 'NOT_STARTED', label: 'NOT_STARTED' },
  { value: 'PROCESSED', label: 'PROCESSED' },
]

const RiskRangeNumberInputForm = ({ range, rangesName, control, label, required, tooltip }) => {
  const { t } = useTranslation(['common'])

  const {
    field: { value: ranges, onChange: onChangeRanges },
  } = useController({
    name: rangesName,
    control,
  })

  const onMinChange = (newValue) => {
    let updatedRanges = { ...ranges }

    updatedRanges[range].min = +newValue

    if (newValue && range !== 'low' && !isNaN(+newValue)) {
      const previousKey = Object.keys(updatedRanges)[Object.keys(updatedRanges).indexOf(range) - 1]
      updatedRanges[previousKey].max = Math.round((parseFloat(newValue) - 0.01) * 100) / 100
    }

    onChangeRanges(updatedRanges)
  }

  const onMaxChange = (newValue) => {
    let updatedRanges = { ...ranges }

    updatedRanges[range].max = +newValue

    if (newValue && range !== 'veryHigh' && !isNaN(+newValue)) {
      const nextKey = Object.keys(updatedRanges)[Object.keys(updatedRanges).indexOf(range) + 1]
      updatedRanges[nextKey].min = Math.round((parseFloat(newValue) + 0.01) * 100) / 100
    }

    onChangeRanges(updatedRanges)
  }

  const onActionChange = (value) => {
    let updatedRanges = { ...ranges }
    updatedRanges[range].action = value
    onChangeRanges(updatedRanges)
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.label}>
        {!!label && <Typography variant="subtitle2">{label}</Typography>}
        {!!label && !!required && <Typography variant="subtitle3">(*)</Typography>}
        {!!tooltip && <RenderTooltip title={tooltip} />}
      </Box>
      <Box className={styles.items}>
        <TextField
          fullWidth
          type="number"
          value={ranges[range]?.min}
          onChange={(e) => onMinChange(e.target.value)}
          inputProps={{ step: 0.1 }}
        />
        <TextField
          fullWidth
          type="number"
          value={ranges[range]?.max}
          onChange={(e) => onMaxChange(e.target.value)}
          inputProps={{ step: 0.1 }}
        />
        <SelectInputForm
          options={options}
          control={control}
          name={`${rangesName}.${range}.action`}
          onChange={(e) => onActionChange(e.target.value)}
          emptyLabel={`${t('common:select')} ${t('common:status')}`}
          defaultValue=""
        />
      </Box>
    </Box>
  )
}

export default RiskRangeNumberInputForm

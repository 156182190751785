import { useMemo, useState } from 'react'
import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import ModalTitle from '../../../../components/common/Modal/ModalTitle'
import { useCreateKycPersonCheckMutation, useFindKycPersonsQuery } from '../../../../features/kyc/kycSlice'
import { countryDictionary } from '../../../../components/common/flag/countries'
import { FileUploaderBox } from '../../kycPerson/components/FileUploaderBox'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'

import { AutocompleteInputForm, SelectInputForm, TextInputForm } from '../../../../components/common/form'
import useDebounce from '../../../../components/hooks/useDebounce'

import styles from './styles.module.scss'

const DOCUMENT_TYPES = [
  'passport',
  'driving_license',
  'national_insurance_number',
  'social_security_number',
  'tax_identification_number',
  'utility_bill',
  'national_identity_card',
  'visa',
  'polling_card',
  'residence_permit',
  'birth_certificate',
  'bank_statement',
  'change_of_name',
  'tax_document',
  'company_confirmation_statement',
  'company_annual_accounts',
  'company_statement_of_capital',
  'company_change_of_address',
  'company_incorporation',
  'company_change_of_officers',
  'company_change_of_beneficial_owners',
  'unknown',
  'other',
]

const CHECK_TYPES = ['identity_check', 'document_check']

const FILE_TYPES = ['JPG', 'JPEG', 'PNG', 'GIF']

export const CreateCheckForm = ({ id, onSubmit = () => {}, onCancel = () => {} }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [searchText, setSearchText] = useState()
  const searchTextDebounced = useDebounce(searchText, 500)

  const [_, { isLoading }] = useCreateKycPersonCheckMutation({ fixedCacheKey: 'create-check' })

  const { isFetching, __, ___, data } = useFindKycPersonsQuery({
    limit: 5,
    page: 0,
    text: searchTextDebounced,
  })

  const schema = yup.object().shape({
    id: yup.string().required(),
    checkTypes: yup.array().min(1),
    documentType: yup.string().required(),
    country: yup.string().required(),
    webhook: yup.string().url(),
    selfie: yup
      .mixed()
      .nullable()
      .test('selfie-required', 'Selfie is required for identity check', function (value) {
        const { checkTypes } = this.parent
        return !checkTypes.includes('identity_check') || !!value
      }),
    documentFront: yup.mixed().required(),
    documentBack: yup.mixed().nullable(),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      id: id,
      checkTypes: ['identity_check'],
      documentType: '',
      country: '',
      webhook: '',
      selfie: null,
      documentFront: null,
      documentBack: null,
    },
  })

  const [checkTypes] = watch(['checkTypes'])

  const countryOptions = useMemo(() => {
    return Object.keys(countryDictionary).map((country) => ({
      value: country,
      label: countryDictionary[country],
    }))
  }, [])

  return (
    <Box component="form" className="modal" onSubmit={handleSubmit(onSubmit)}>
      <ModalTitle setModalOpen={onCancel} title={`${t('common:create')} ${t('common:check')}`} />
      <Box className={styles.formContainer}>
        <Grid container spacing={2} style={{ maxHeight: '120px' }} direction="row">
          {!id && (
            <Grid xs={6}>
              <Controller
                name="id"
                control={control}
                render={({ field }) => (
                  <>
                    <Typography variant="subtitle2">{t('common:person')}</Typography>
                    <Autocomplete
                      value={field.value}
                      onInputChange={(event, newInputValue) => setSearchText(newInputValue)}
                      onChange={(event, newValue) => field.onChange(newValue ? newValue._id : '')}
                      options={data ? data.data.items : []}
                      getOptionLabel={(option) =>
                        option ? `${option.email || ''} (${option.firstname || ''} ${option.lastname || ''})` : ''
                      }
                      isOptionEqualToValue={(option, value) => option._id === value._id && option.email === value.email}
                      loading={isFetching}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t('messages:searchKycPersonByEmailOrName')}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isFetching ? <CircularProgress size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      sx={{
                        '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                          padding: 0,
                          marginLeft: '1rem',
                        },
                      }}
                    />
                  </>
                )}
              />
            </Grid>
          )}
          <Grid xs={6}>
            <AutocompleteInputForm
              name="checkTypes"
              label={t('common:checkType')}
              control={control}
              options={CHECK_TYPES}
              getOptionDisabled={(option) => checkTypes.includes(option)}
              multiple
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} direction="row">
          <Grid xs={6}>
            <SelectInputForm
              name="country"
              label={t('common:country')}
              control={control}
              options={countryOptions}
              emptyLabel={`${t('common:select')} ${t('common:country')}`}
            />
          </Grid>
          <Grid xs={6}>
            <SelectInputForm
              name="documentType"
              label={`${t('common:document')} ${t('common:type')}`}
              control={control}
              options={DOCUMENT_TYPES.map((documentType) => ({
                value: documentType,
                label: documentType?.replaceAll('_', ' '),
              }))}
              emptyLabel={`${t('common:select')} ${t('common:document')}`}
            />
          </Grid>
        </Grid>

        {checkTypes.includes('identity_check') && (
          <Box className={styles.selfieContainer}>
            <Controller
              name="selfie"
              control={control}
              render={({ field }) => (
                <FileUploaderBox
                  title={t('common:addASelfie')}
                  file={field.value}
                  setFile={field.onChange}
                  fileTypes={FILE_TYPES}
                />
              )}
            />
            {errors.selfie && <Typography color="error">{errors.selfie.message}</Typography>}
          </Box>
        )}

        <Box className={styles.documentContainer}>
          <Box width="45%">
            <Controller
              name="documentFront"
              control={control}
              render={({ field }) => (
                <FileUploaderBox
                  title={t('common:documentFrontSide')}
                  file={field.value}
                  setFile={field.onChange}
                  fileTypes={FILE_TYPES}
                />
              )}
            />
            {errors.documentFront && <Typography color="error">{errors.documentFront.message}</Typography>}
          </Box>

          <Box width="45%">
            <Controller
              name="documentBack"
              control={control}
              render={({ field }) => (
                <FileUploaderBox
                  title={t('common:documentBackSide')}
                  file={field.value}
                  setFile={field.onChange}
                  fileTypes={FILE_TYPES}
                />
              )}
            />
            {errors.documentBack && <Typography color="error">{errors.documentBack.message}</Typography>}
          </Box>
        </Box>
      </Box>

      <Box className={styles.actionButtons}>
        <LoadingButton variant="contained" color="primary" type="submit" loading={isLoading}>
          {t('common:create')}
        </LoadingButton>
      </Box>
    </Box>
  )
}

import '../reports.scss'
import { useState } from 'react'
import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { ruleQByClientReportDataMapper } from '../../../components/common/dataExport/dataMappers/ruleQByClientReportDataMapper'
import Spinner from '../../../components/common/spinner/spinner'
import { useReports } from '../../pages-hooks/useReports'
import DataExportModal from '../../../components/common/dataExport/DataExportModal'
import { useTranslation } from 'react-i18next'
import CustomNoRowsOverlay from '../../../components/common/datagrid/CustomNoRowsOverlay'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'

const RulesQReportByClient = ({
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
  reportKey,
  isLoading,
  setIsLoading,
  setModalOpen,
}) => {
  const { activeServices } = useSelector((state) => state.login)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const { t } = useTranslation(['common'])

  const allReports = useReports(reportKey, setIsLoading, isLoading)

  return (
    <Box>
      {isLoading && <Spinner noTransform />}

      <TableContainer>
        <Box className="filter-bar">
          <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setModalOpen(true)}>
            {t('common:filters')}
          </Button>
          {activeServices?.includes('reportsitem') && allReports?.data?.length > 0 && (
            <Button
              disabled={!activeServices?.includes('reportsitem') || !allReports?.data?.length}
              onClick={() => setShowDownloadModal(true)}
              endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
              variant="outlined"
            >
              {/* <DownloadIcon /> */}
              <Typography variant="title2">{t('common:exportReport')}</Typography>
            </Button>
          )}
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('common:userId')}</TableCell>
              {reportKey !== 'rules_total_report_by_client' && <TableCell>{t('common:ruleId')}</TableCell>}
              {reportKey !== 'rules_total_report_by_client' && <TableCell>{t('common:ruleInstanceId')}</TableCell>}
              <TableCell>{t('common:filteredTxsQty')}</TableCell>
              <TableCell>{t('common:exeQ')}</TableCell>
              <TableCell>{t('common:hitQ')}</TableCell>
              <TableCell>{t('common:hitExeRatio')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Array.isArray(allReports?.data) && allReports?.data?.length > 0 ? (
              allReports.data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.userId}</TableCell>
                  {reportKey !== 'rules_total_report_by_client' && <TableCell>{row.ruleId}</TableCell>}
                  {reportKey !== 'rules_total_report_by_client' && <TableCell>{row.ruleInstanceId}</TableCell>}
                  <TableCell>{row.filteredTxsQty}</TableCell>
                  <TableCell>{row.exeQ}</TableCell>
                  <TableCell>{row.hitQ}</TableCell>
                  <TableCell>
                    {typeof row.hitExeRate === 'number' && row.hitExeRate !== -1
                      ? row.hitExeRate.toFixed(4)
                      : 'not-defined value'}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <Box className="table-custom-overlay">
                <CustomNoRowsOverlay />
              </Box>
            )}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage={[]}
          component="div"
          count={allReports?.pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Modal open={showDownloadModal} onClose={() => setShowDownloadModal(false)}>
        <div>
          <DataExportModal
            show={showDownloadModal}
            onClose={() => setShowDownloadModal(false)}
            data={allReports?.data}
            dataMapper={ruleQByClientReportDataMapper(reportKey)}
            dataMapperForPDF={ruleQByClientReportDataMapper(reportKey)}
            fileName={reportKey}
          />
        </div>
      </Modal>
    </Box>
  )
}

export default RulesQReportByClient

import './riskMatrix.scss'
import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import RiskMatrixForm from './components/RiskMatrixForm'
import {
  useCreateRiskMatrixMutation,
  useGetRiskMatrixByIdQuery,
  useUpdateRiskMatrixMutation,
} from '../../../features/riskMatrix/riskMatrixSlice'
import toaster from '../../../toaster'

const RiskMatrixCreation = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { data, isLoading, isFetching } = useGetRiskMatrixByIdQuery({ id }, { skip: !id })
  const [createRiskMatrix, { isLoading: isCreatingRiskMatrix }] = useCreateRiskMatrixMutation()
  const [updateRiskMatrix, { isLoading: isUpdatingRiskMatrix }] = useUpdateRiskMatrixMutation()

  const { t } = useTranslation(['common', 'messages'])

  const defaultValues = useMemo(() => {
    if (!id) {
      return {
        name: '',
        description: '',
        target: 'KYB',
        country: 'BR',
        ranges: {
          low: { min: 0, max: 250 },
          medium: { min: 260, max: 500 },
          high: { min: 510, max: 750 },
          veryHigh: { min: 760, max: 1000 },
        },
        params: {
          combinator: 'none',
          rules: [],
        },
      }
    }
    if (data?.data) {
      const riskMatrix = data?.data
      return {
        name: riskMatrix.name,
        description: riskMatrix.description,
        target: riskMatrix.target,
        country: riskMatrix.country,
        ranges: riskMatrix.ranges,
        params: {
          combinator: 'none',
          rules: riskMatrix.params,
        },
      }
    }
  }, [id, data])

  const handleUpdateRiskMatrix = async (values) => {
    try {
      const response = await updateRiskMatrix({ id, ...values }).unwrap()
      if (response.success) {
        toaster.success(t('messages:updateSuccess'))
      } else {
        toaster.error(response.message ? response.message : 'Risk Matrix update error')
      }
    } catch (error) {
      toaster.error(error.message ? error.message : 'Risk Matrix update error')
    }
  }

  const handleCreateRiskMatrix = async (values) => {
    try {
      const response = await createRiskMatrix(values).unwrap()
      if (response.success) {
        toaster.success(t('messages:creationSuccess'))
        navigate(`/admin/riskMatrix/${response.data._id}`, { replace: true })
      } else {
        toaster.error(response.message ? response.message : 'Risk Matrix create error')
      }
    } catch (error) {
      toaster.error(error.message ? error.message : 'Risk Matrix creation error')
    }
  }

  const onSubmit = async (values) => {
    if (id) {
      await handleUpdateRiskMatrix(values)
    } else {
      await handleCreateRiskMatrix(values)
    }
  }

  if (isLoading || isFetching) {
    return <Box>Loading...</Box>
  }

  return (
    <Box className="filter">
      <Box className="component-wrapper">
        <Box>
          <Box className="component-title">
            <Typography variant="h2">
              {id ? t('common:update') : t('common:create')} {t('common:riskMatrix')}
            </Typography>
          </Box>
          <Typography variant="subtitle3"></Typography>
        </Box>

        <Box>
          {!!id && (
            <Box>
              <Typography>Status: {data?.data?.status}</Typography>
            </Box>
          )}
          <RiskMatrixForm
            defaultValues={defaultValues}
            isLoading={isCreatingRiskMatrix || isUpdatingRiskMatrix}
            onSubmit={onSubmit}
            isEdit={!!id}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default RiskMatrixCreation

import { Box, FormControl, FormHelperText, MenuItem, Select, Typography } from '@mui/material'
import styles from './styles.module.scss'
import { RenderTooltip } from '../../../../../components/utilities/RenderTooltip'

const SelectInput = ({
  value,
  onChange,
  error,
  label,
  placeholder = '',
  tooltip = '',
  defaultValue,
  options = [],
  required = false,
  disabled = false,
  emptyLabel = '',
}) => {
  return (
    <Box className={styles.container}>
      <Box className={styles.label}>
        {!!label && <Typography variant="subtitle2">{label}</Typography>}
        {!!label && !!required && <Typography variant="subtitle3">(*)</Typography>}
        {!!tooltip && <RenderTooltip title={tooltip} />}
      </Box>
      <FormControl error={!!error}>
        <Select
          displayEmpty={!!emptyLabel}
          disabled={disabled}
          placeholder={placeholder}
          defaultValue={emptyLabel ? '' : defaultValue}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          {!!emptyLabel && (
            <MenuItem disabled value="">
              <Typography variant="subtitle3">{emptyLabel}</Typography>
            </MenuItem>
          )}
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>
    </Box>
  )
}

export default SelectInput

import { Box, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import KybSettingsTabPanel from '../components/KybSettingsTabPanel'
import CountrySelector from '../../../components/kyb/countrySelector'

const KybSettingsPage = () => {
  const { t } = useTranslation(['common', 'messages'])
  const { currentCountry } = useSelector((state) => state.kybCountry)

  return (
    <Box className={`filter`}>
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t(`messages:kyb.menu.settings`)}</Typography>
          <Box sx={{ display: 'flex', textAlign: 'center', justifyContent: 'center', gap: 2 }}>
            <CountrySelector />
          </Box>
        </Box>
      </Box>
      <Paper elevation={0}>
        <KybSettingsTabPanel currentCountry={currentCountry} />
      </Paper>
    </Box>
  )
}

export default KybSettingsPage

import { Box, TextField, Typography } from '@mui/material'
import styles from './styles.module.scss'
import { RenderTooltip } from '../../../../../components/utilities/RenderTooltip'

const RangeNumberInput = ({ value, onChange, label, required, tooltip }) => {
  return (
    <Box className={styles.container}>
      <Box className={styles.label}>
        {!!label && <Typography variant="subtitle2">{label}</Typography>}
        {!!label && !!required && <Typography variant="subtitle3">(*)</Typography>}
        {!!tooltip && <RenderTooltip title={tooltip} />}
      </Box>
      <Box className={styles.items}>
        <TextField
          fullWidth
          type="number"
          value={value?.min || 0}
          onChange={(e) => onChange({ min: Number(e.target.value), max: value.max })}
        />
        <TextField
          fullWidth
          type="number"
          value={value?.max || 0}
          onChange={(e) => onChange({ min: value.min, max: Number(e.target.value) })}
        />
      </Box>
    </Box>
  )
}

export default RangeNumberInput

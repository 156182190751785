import { useMemo } from 'react'
import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { CaseStatus } from '../../case.status'
import { useGetCaseAdminPanelQuery } from '../../../../features/cases/caseSlice'
import { AutocompleteInputForm, TextInputForm } from '../../../../components/common/form'
import ModalTitle from '../../../../components/common/Modal/ModalTitle'

const EditCasesForm = ({ onSubmit, casesToEdit, setModalOpen, isLoading }) => {
  const { t } = useTranslation(['common', 'messages'])
  const { emailClient } = useSelector((state) => state.login)
  const { data: caseAdminPanelData } = useGetCaseAdminPanelQuery({ emailClient })

  const { categories, labels } = useMemo(() => {
    if (caseAdminPanelData?.data) {
      return {
        labels: caseAdminPanelData?.data?.labels || [],
        categories: caseAdminPanelData?.data?.categories || [],
      }
    }
    return {
      labels: [],
      categories: [],
    }
  }, [caseAdminPanelData])

  const schema = yup.object().shape({
    comment: yup
      .string()
      .test('len', 'Type at least 6 characters', (val) => val.length >= 6)
      .required(),
  })
  const { handleSubmit, reset, control, formState } = useForm({
    mode: 'onTouched',
    defaultValues: {
      status: null,
      label: null,
      category: null,
      comment: '',
    },
    resolver: yupResolver(schema),
  })

  return (
    <Box className="modal">
      <ModalTitle setModalOpen={setModalOpen} title={t('common:updateCase')} />
      <Box className="modal-box-filters">
        <Box className="modal-box">
          <Typography variant="subtitle3">
            {t('messages:doYouWantUpdateSeveral')}{' '}
            {Object.values(casesToEdit)
              .map((caseObj) => caseObj.maskId || caseObj.id)
              .filter((value) => !!value)
              .join(', ')}
            ?
          </Typography>
        </Box>
        <Box className="modal-box">
          <AutocompleteInputForm
            name="status"
            control={control}
            label={t('common:newStatus')}
            placeholder={`${t('common:select')} ${t('common:newStatus')}`}
            options={CaseStatus}
          />
        </Box>
        <Box className="modal-box">
          <AutocompleteInputForm
            name="label"
            control={control}
            label={t('common:newLabel')}
            placeholder={`${t('common:select')} ${t('common:newLabel')}`}
            options={labels}
          />
        </Box>
        <Box className="modal-box">
          <AutocompleteInputForm
            name="category"
            control={control}
            label={t('common:newCategory')}
            placeholder={`${t('common:select')} ${t('common:newCategory')}`}
            options={categories}
          />
        </Box>
        <Box className="modal-box">
          <TextInputForm
            name="comment"
            control={control}
            label={t('common:updatingReasons')}
            placeholder={t('messages:closingReason')}
            rows={4}
            multiline
          />
        </Box>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
          <Box>
            <LoadingButton
              disabled={!formState.isDirty}
              variant="contained"
              loading={isLoading}
              onClick={() => reset()}
            >
              {t('common:clear')}
            </LoadingButton>
          </Box>
          <Box>
            <LoadingButton
              disabled={!formState.isValid || !formState.isDirty}
              variant="contained"
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t('common:update')}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default EditCasesForm

import { Box, Button, Modal, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import ModalTitle from '../../../components/common/Modal/ModalTitle'
import DatatableCsv from './DatatableCsv'
import CreateKytImportForm from './CreateKytImportModal'
import { useCreateKytImportItemMutation } from '../../../features/kyt-import/kytImportSlice'
import toaster from '../../../toaster'

const CsvPage = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const { t } = useTranslation(['common', 'messages'])
  const [createKytImportItem, { isLoading }] = useCreateKytImportItemMutation({
    fixedCacheKey: 'create-kyt-import-item',
  })

  const onCreateKytImportItem = async (values) => {
    try {
      await createKytImportItem(values).unwrap()
      setOpenCreateModal(false)
      toaster.success(t('messages:creationSuccess'))
    } catch (error) {
      toaster.error(`${t('messages:creationError')}: ${error?.data?.message}`)
    }
  }

  return (
    <Box className="filter">
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t('messages:kyt-import.title')}</Typography>
          <Button variant="contained" onClick={() => setOpenCreateModal(true)}>
            {t('common:createNew')}
          </Button>
        </Box>
      </Box>

      <Paper elevation={0}>
        <DatatableCsv />
      </Paper>

      <Modal
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Box className="modal">
            <ModalTitle setModalOpen={setOpenCreateModal} title="Create import process" closeDisabled={isLoading} />
            <CreateKytImportForm onSubmit={onCreateKytImportItem} />
          </Box>
        </div>
      </Modal>
    </Box>
  )
}

export default CsvPage

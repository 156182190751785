import { Box, Button, Fab, InputLabel, Link, MenuItem, Modal, Select, TablePagination, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useGetKybNotificationsQuery, useRestartKybMutation } from '../../../../services/guenoApi'
import useUserTableConfig from '../../../../components/hooks/useUserTableConfig'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import {
  getOrderedColumns,
  getVisibilityModel,
  updateListOrder,
} from '../../../../components/common/datatable/tableUtils/tableUtils'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TableIcons } from '../../../../components/utilities/TableIcons'
import { RiskLevel } from '../../../../components/utilities/RiskLevel'
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'
import { buildTimeStringFromTimestamp, toLocaleUTCDateString } from '../../../../components/common/time/timeHelper'
import { LoadingButton } from '@mui/lab'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import CustomColumnMenu from '../../../../components/common/datagrid/CustomColumnMenu'
import { FilterModal } from '../../../../components/utilities/FilterModal'
import dayjs from 'dayjs'
import MyDatePicker from '../../../../components/utilities/MyDatePicker'
import { kybRiskLevelEnum, kybStatusEnum } from '../../kyb.enums'
import { updateKybNotificationsFilters } from '../../../../features/kybNotifications/kybNotificationsSlice'
import { getCountrySegment } from '../../kyb.hook-helpers'
import ProcessingSpinner from '../../../../components/common/alertFetchSpinner/processingSpinner'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { KybOutdatedDatatable } from './KybOutdatedDatatable'

export const KybNotificationsDatatable = ({
  isLoading,
  setIsLoading,
  setModalOpen,
  modalOpen,
  formattedDate,
  tableKey,
  filterStatus,
  setFetchMessage,
  setIsProcessing,
  setIsFetching,
  setFetchError,
  isProcessing,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { accessToken } = useSelector((state) => state.login)
  const [activeNotification, setActiveNotification] = useState('')
  const [newExpirationDate, setNewExpirationDate] = useState('')
  const queryState = useSelector((state) => state.kybNotifications.filters)
  const { limit, offset } = queryState
  const [toDate, setToDate] = useState(queryState.toDate)
  const [riskLevel, setRiskLevel] = useState(queryState.riskLevel ?? '')
  const [selectedDate, setSelectedDate] = useState(queryState.fromDate)
  const [page, setPage] = useState(queryState.page)
  const [id, setId] = useState(queryState.id)
  const [status, setStatus] = useState(queryState.status ?? 'INACTIVE')
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const apiRef = useGridApiRef()
  const { countrySegment } = useSelector((state) => state.kybCountry)
  const { t } = useTranslation(['common', 'messages'])
  const [newStatus, setNewStatus] = useState('')

  const [restartKyb, { isLoading: restartIsLoading, originalArgs }] = useRestartKybMutation()
  const handleResetButton = () => {
    if (id !== '') setId('')
    if (status) setStatus('')
    if (riskLevel) setRiskLevel('')
    if (toDate) setToDate(null)

    setSelectedDate()
    setPage(0)
    dispatch(setIsLoading(true))

    return dispatch(
      updateKybNotificationsFilters({
        page: 0,
        limit: 25,
        offset: 0,
        id: '',
        status: null,
        riskLevel: null,
        riskLevelOutDate: null,
        expirationDate: '',
        name: '',
        fromDate: null,
        toDate: null,
      }),
    )
  }
  const handleRestart = async (kybId) => {
    try {
      await restartKyb({ countrySegment, kybId })
    } catch (error) {
      console.error(error)
    }
  }

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('common:name'),
        sortable: false,
        flex: 3,
        renderCell: ({ row }) => (
          <Link
            component={ReactRouterLink}
            title={row.kyb?._id}
            to={`/kyb/${row?.countryCode ?? 'BR'}/detail/${row.kyb?._id}`}
          >
            {row.kyb?.companyDetails?.name}
          </Link>
        ),
      },
      {
        field: 'status',
        headerName: t('common:status'),
        sortable: false,
        flex: 1.5,
        renderCell: (params) => {
          return <TableIcons type={params.row.status.toUpperCase()} />
        },
      },
      {
        field: 'countryCode',
        headerName: t('common:country'),
        sortable: false,
        flex: 1,
        renderCell: (params) => {
          return params.row.countryCode
        },
      },
      {
        field: 'riskLevel',
        headerName: t('common:riskLevel'),
        sortable: false,
        flex: 1.5,
        renderCell: (params) => {
          return <Box>{params.row.riskLevel ? <RiskLevel type={params.row.riskLevel} /> : ''}</Box>
        },
      },
      {
        field: 'expirationDate',
        headerName: t('common:expirationDate'),
        flex: 1.5,
        renderCell: (params) => {
          const expirationDate = new Date(params.row.expirationDate)
          const today = new Date()

          const timeDifference = expirationDate.getTime() - today.getTime()

          const daysLeft = timeDifference / (1000 * 3600 * 24)

          const isExpiringSoon = daysLeft <= 7

          return (
            <Typography variant="number" style={{ color: isExpiringSoon ? 'red' : 'inherit' }}>
              {toLocaleUTCDateString(params.row.expirationDate)}
            </Typography>
          )
        },
      },
      {
        field: 'action',
        headerName: t('common:actions'),
        headerAlign: 'center',
        sortable: false,
        flex: 2,
        renderCell: ({ row }) => {
          return (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, alignItems: 'center' }}>
                <LoadingButton
                  variant="outlined"
                  onClick={() => {
                    setActiveNotification(row)
                    setModalOpen(!modalOpen)
                  }}
                >
                  {t('common:edit')}
                </LoadingButton>
                {row.status === 'PENDING' && (
                  <LoadingButton
                    loading={restartIsLoading && originalArgs?.kybId === row.kyb?._id}
                    onClick={() => handleRestart(row.kyb?._id)}
                    variant="outlined"
                  >
                    {t('common:reset')}
                  </LoadingButton>
                )}
              </Box>
            </Box>
          )
        },
      },
    ],
    [],
  )

  const {
    config,
    saveConfig,
    loading: configLoading,
  } = useUserTableConfig({
    tableKey,
    defaultConfig: {
      sortModel: [{ field: 'expirationDate', sort: filterStatus === 'PENDING' ? 'asc' : 'desc' }],
      rowsPerPage: 25,
      pinnedColumns: { left: [], right: [] },
      columnOrder: columns.map((c) => c.field),
    },
  })

  const { field, sort } = useMemo(
    () => (config.sortModel?.length ? config.sortModel[0] : { field: '', sort: '' }),
    [config.sortModel],
  )

  const commonParams = {
    countrySegment,
    limit,
    offset,
    page,
    sortDirection: sort === 'desc' ? -1 : 1,
    sortField: field,
  }

  const params = useMemo(
    () => ({
      status: filterStatus,
      ...(filterStatus === 'PENDING' && { fromDate: formattedDate }),
      ...(toDate && { toDate }),
      ...(riskLevel && { riskLevel }),
    }),
    [formattedDate, toDate, riskLevel],
  )

  const { data: data, refetch } = useGetKybNotificationsQuery({
    ...commonParams,
    ...params,
  })

  const orderedColumns = useMemo(
    () => getOrderedColumns(config.columnOrder, columns),
    [JSON.stringify(config.columnOrder), columns],
  )

  const handleColumnOrderChange = (params) => {
    const updatedOrder = updateListOrder(config.columnOrder, params)
    if (JSON.stringify(config.columnOrder) !== JSON.stringify(updatedOrder)) {
      saveConfig({ columnOrder: updatedOrder })
    }
  }

  const visibleColumnsModel = useMemo(
    () => getVisibilityModel(config.visibleColumns, columns),
    [config.visibleColumns, columns],
  )

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setIsLoading(true)
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    saveConfig({ rowsPerPage: newRowsPerPage })
    setPage(0)
    setIsLoading(true)
  }

  const handleDispatch = () => {
    dispatch(setIsLoading(true))

    const fromDate = selectedDate ? dayjs(selectedDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss') : null

    const adjustedToDate = selectedDate ? dayjs(selectedDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss') : null
    setToDate(adjustedToDate)

    refetch()

    return dispatch(
      updateKybNotificationsFilters({
        limit: config.rowsPerPage,
        offset: config.rowsPerPage * page,
        page,
        id,
        status,
        riskLevel,
        fromDate,
        toDate: adjustedToDate,
      }),
    )
  }

  const handleUpdate = (e, data, id) => {
    e.preventDefault()
    setIsFetching(true)
    setFetchMessage('')
    setIsProcessing(true)
    dispatch(setIsLoading(true))

    if (!process.env.REACT_APP_IS_DEMO) {
      const country = getCountrySegment(activeNotification.countryCode)

      let url = `${process.env.REACT_APP_BASEURL}/kyb${country}notification/${id}`

      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setIsProcessing(false)
            setModalOpen(false)
            setFetchMessage('Update success.')
            setNewStatus('')
            setActiveNotification('')
            dispatch(setIsLoading(false))
            setPage(0)
            refetch()
          } else {
            throw new Error(res.message ? res.message : t('messages:unexpectedError'))
          }
        })
        .catch((err) => {
          console.error('[UPDATE NOTIFICATION ERROR] --> ', err)
          setIsProcessing(false)
          setFetchError(true)
          setModalOpen(false)
          setNewStatus('')
          dispatch(setIsLoading(false))
          setActiveNotification('')
          setFetchMessage(err.message ? err.message : t('messages:unexpectedErrorValues'))
        })
    } else {
      setIsProcessing(false)
      setModalOpen(false)
      setFetchMessage('Update success.')
      setNewStatus('')
      setActiveNotification('')
      dispatch(setIsLoading(false))
      refetch()
    }

    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  useEffect(() => {
    if (activeNotification) {
      setNewStatus(activeNotification.status)
      setNewExpirationDate(new Date(activeNotification.expirationDate))
    }
  }, [activeNotification])

  return (
    <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <Box className="filter-bar">
        <Button
          variant="label"
          endIcon={<MenuOutlinedIcon />}
          onClick={() => {
            setFilterModalOpen(true)
          }}
        >
          {t('common:filters')}
        </Button>
        <Button
          variant="label"
          endIcon={<MenuOutlinedIcon />}
          onClick={() => apiRef.current.showPreferences('columns')}
        >
          {t('common:showHideColumns')}
        </Button>
      </Box>
      <DataGridPro
        getRowHeight={() => 'auto'}
        getRowId={(row) => row._id || row.id}
        rows={data?.data || []}
        columns={orderedColumns}
        apiRef={apiRef}
        slots={{
          columnMenu: CustomColumnMenu,
        }}
        hideFooter={true}
        autoHeight
        loading={isLoading || configLoading}
        sx={{
          height: 'calc(100vh - 24rem)',
          '& .MuiDataGrid-virtualScroller': {
            overflow: 'auto',
          },
        }}
        sortingMode="server"
        sortModel={config.sortModel}
        onSortModelChange={(newSortModel) => saveConfig({ sortModel: newSortModel })}
        columnOrder={config.columnOrder}
        onColumnOrderChange={handleColumnOrderChange}
        pinnedColumns={config.pinnedColumns}
        onPinnedColumnsChange={(newPinnedColumns) => saveConfig({ pinnedColumns: newPinnedColumns })}
        columnVisibilityModel={visibleColumnsModel}
        onColumnVisibilityModelChange={(newModel) => saveConfig({ visibleColumns: newModel })}
      />
      <TablePagination
        labelRowsPerPage={[]}
        component="div"
        count={data?.pagination?.totalElements || 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={config.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {filterStatus === 'PENDING' && (
        <Box sx={{ marginTop: '1.5rem' }}>
          <KybOutdatedDatatable
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            limit={limit}
            offset={offset}
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
            formattedDate={formattedDate}
          />
        </Box>
      )}

      <FilterModal
        modalOpen={filterModalOpen}
        setModalOpen={setFilterModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:to')} {t('common:date')}:
            </Typography>

            <MyDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:riskLevel')}:
            </Typography>
            <Select
              size="small"
              value={riskLevel}
              fullWidth
              displayEmpty
              onChange={(e) => setRiskLevel(e.target.value)}
            >
              <MenuItem disabled value="">
                <Typography variant="subtitle3">
                  {t('common:select')} {t('common:riskLevel')}
                </Typography>
              </MenuItem>
              {kybRiskLevelEnum.map((item) => (
                <MenuItem key={item} value={item}>
                  {t(`common:${item}`)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </FilterModal>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box className="modal">
          {isProcessing ? (
            <ProcessingSpinner message={t('common:processing')} />
          ) : (
            <Box className="send-btn">
              <Box className="modal-top">
                <Box className="modal-titles">
                  <Typography variant="title">
                    {t('common:edit')} {t('common:status')}
                  </Typography>
                </Box>
                <Fab variant="close" onClick={() => setModalOpen(false)}>
                  <CloseOutlinedIcon />
                </Fab>
              </Box>
              <Box>
                <Box className="notifications-row">
                  <p> KYB ID:</p>
                  <Box className="id-action" onClick={() => navigate(`/kyb/detail/${activeNotification?.kyb?._id}`)}>
                    {activeNotification?.kyb?._id}
                  </Box>
                </Box>
                <Box className="notifications-row">
                  <p>{t('common:createdAt')}:</p> {buildTimeStringFromTimestamp(activeNotification?.createdAt)}
                </Box>
                <Box className="notifications-row">
                  <p>{t('common:updatedAt')}:</p> {buildTimeStringFromTimestamp(activeNotification?.updatedAt)}
                </Box>
                <Box className="notifications-row">
                  <p>{t('common:riskLevel')}:</p>
                  {activeNotification?.riskLevel ? <RiskLevel type={activeNotification?.riskLevel} /> : ''}
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" gap="1rem">
                <Box className="modal-box">
                  <InputLabel htmlFor="status">{t('common:status')}:</InputLabel>
                  <Select
                    id="status"
                    size="small"
                    fullWidth
                    displayEmpty
                    value={newStatus}
                    onChange={(e) => setNewStatus(e.target.value)}
                  >
                    <MenuItem disabled value="">
                      <Typography variant="subtitle3">
                        {t('common:select')} {t('common:status')}
                      </Typography>
                    </MenuItem>
                    {kybStatusEnum.map((item) => (
                      <MenuItem key={item} value={item}>
                        {t(`common:${item}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box className="modal-box">
                  <InputLabel>{t('common:expirationDate')}</InputLabel>
                  <Box className="notifications-row">
                    {buildTimeStringFromTimestamp(activeNotification?.expirationDate)}
                  </Box>
                  <MyDatePicker selectedDate={newExpirationDate} setSelectedDate={setNewExpirationDate} />
                </Box>
              </Box>
              <Box className="modal-filter-buttons">
                <Button size="small" variant="outlinedGrey" onClick={() => setFilterModalOpen(false)}>
                  {t('common:cancel')}
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  onClick={(e) =>
                    handleUpdate(
                      e,
                      {
                        status: newStatus,
                        expirationDate: new Date(newExpirationDate),
                      },
                      activeNotification?._id,
                    )
                  }
                >
                  {t('common:save')}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  )
}

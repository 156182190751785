import '../home/home.scss'
import { Box, Button, IconButton, Modal, Paper, TablePagination, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { toLocaleUTCDateString } from '../../components/common/time/timeHelper'
import { Status } from '../../components/utilities/Status'
import RefreshIcon from '@mui/icons-material/Refresh'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutline'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { useState } from 'react'
import CustomColumnMenu from '../../components/common/datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../../components/common/datagrid/CustomLoadingOverlay'
import ModalCreteKybBatch from './components/ModalCreateKybBatch'
import { useCreateKybBatchMutation, useFindKybBatchesQuery } from '../../services/guenoApi'
import CountrySelector from '../../components/kyb/countrySelector'
import { useSelector } from 'react-redux'
import toaster from '../../toaster'

const defaultSortModel = { field: 'createdAt', sort: 'desc' }

const KybLoadInBatches = () => {
  const { t } = useTranslation(['common', 'messages'])
  const apiRef = useGridApiRef()
  const [sortModel, setSortModel] = useState([defaultSortModel])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const { currentCountry } = useSelector((state) => state.kybCountry)

  const [showCreateBatch, setShowCreateBatch] = useState(false)
  const [createKybBatch] = useCreateKybBatchMutation({ fixedCacheKey: 'create-kyb-batch' })

  const {
    data: results,
    isLoading,
    refetch,
    isFetching: isFetchingKybBatches,
  } = useFindKybBatchesQuery({
    currentCountry: currentCountry,
    limit: rowsPerPage,
    page,
  })

  const handleCreateKybBatch = async (file) => {
    try {
      await createKybBatch({
        file,
        currentCountry: currentCountry,
      })
      setShowCreateBatch(false)
      toaster.success(t('messages:createKybBatchSuccess'))
    } catch (error) {
      console.error('[CREATE NEW KYB BATCH ERROR] --> ', error?.data?.data)
      toaster.error(t('messages:createKybBatchError'))
    }
  }

  const columns = [
    {
      field: 'id',
      headerName: t('common:id'),
      flex: 3,
      renderCell: ({ row }) => {
        return <Typography variant="number">{row._id || row.id}</Typography>
      },
    },
    {
      field: 'fileName',
      headerName: t('common:file'),
      flex: 3,
      renderCell: ({ row }) => {
        return <Typography variant="number">{row.fileName}</Typography>
      },
    },
    {
      field: 'status',
      headerName: t('common:status'),
      flex: 3,
      renderCell: ({ row }) => {
        return <Status type={row.status} />
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:creationDate'),
      flex: 3,
      renderCell: ({ row }) => {
        return <Typography variant="number">{toLocaleUTCDateString(row.createdAt)}</Typography>
      },
    },
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSortModelChange = (sortModel) => {
    setSortModel(sortModel)
  }

  return (
    <Box className={`${false && 'spinner-transition'} filter`}>
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t(`messages:kyb.menu.loadInBatches`)}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', gap: 2 }}>
            <CountrySelector />
            {currentCountry === 'BR' && (
              <Button
                variant="outlined"
                onClick={() => {
                  setShowCreateBatch(true)
                }}
                startIcon={<AddCircleOutlineOutlinedIcon />}
              >
                {`${t('common:CREATE_BATCH')}`}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Paper elevation={0}>
        <Box style={{ width: '100%', height: '100%' }}>
          <Box
            className="filter-bar"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <TablePagination
                labelRowsPerPage={[]}
                component="div"
                count={results?.data?.pagination?.totalElements || 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
              />
            </Box>

            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={() => refetch()}
                disabled={isLoading || isFetchingKybBatches}
                title={t('common:refresh')}
              >
                <RefreshIcon color="action" />
              </IconButton>
            </Box>
          </Box>

          <DataGridPro
            getRowHeight={() => 'auto'}
            getRowId={(row) => row._id || row.id}
            rows={results?.data?.items || []}
            columns={columns}
            apiRef={apiRef}
            slots={{
              columnMenu: CustomColumnMenu,
              loadingOverlay: CustomLoadingOverlay,
            }}
            hideFooter={true}
            autoHeight
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            isLoading={isLoading || isFetchingKybBatches}
          />
        </Box>
      </Paper>
      <Modal open={showCreateBatch} onClose={() => setShowCreateBatch(false)}>
        <div>
          <ModalCreteKybBatch setShowCreateBatch={setShowCreateBatch} handleCreateBatch={handleCreateKybBatch} />
        </div>
      </Modal>
    </Box>
  )
}

export default KybLoadInBatches

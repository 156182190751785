import '../../home/home.scss'
import './transaction.scss'
import { Box, Modal, Paper, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Datatable from '../../../components/common/datatable/Datatable'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetTransactionsFilters,
  updateTransactionsFilters,
  useFindTransactionsQuery,
} from '../../../features/transactions/transactionSlice'
import DataExportModal from '../../../components/common/dataExport/DataExportModal'
import {
  kytTransactionDataMapper,
  kytTransactionDataMapperForPDF,
} from '../../../components/common/dataExport/dataMappers/kytTransactionDataMapper'
import { useTranslation } from 'react-i18next'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import { selectUserMaster } from '../../../features/kyb/kybCountrySlice'
import FiltersTransactionsForm from '../components/FiltersTransactionsForm'
import { formatDate } from '../utils'
import useUserTableConfig from '../../../components/hooks/useUserTableConfig'

const Transaction = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const { emailClient } = useSelector((state) => state.login)
  const userMaster = useSelector((state) => selectUserMaster(state, emailClient))
  const timezone = useMemo(
    () => (typeof userMaster?.timezone !== 'number' ? 0 : userMaster?.timezone),
    [userMaster?.timezone],
  )
  const queryState = useSelector((state) => state.transaction.filters)
  const shadowRuleInstanceId = useMemo(
    () => (searchParams.get('shadowRuleId') ? searchParams.get('shadowRuleId') : queryState.shadowRuleInstanceId),
    [searchParams, queryState.shadowRuleInstanceId],
  )

  const {
    config,
    saveConfig,
    loading: configLoading,
  } = useUserTableConfig({
    tableKey: 'transactionTable',
    defaultConfig: {
      sortModel: [{ field: 'createdTimestamp', sort: 'desc' }],
      rowsPerPage: 25,
      pinnedColumns: { left: [], right: [] },
      columnOrder: [
        'id',
        'transactionType',
        'transactionState',
        'createdTimestamp',
        'alert',
        'hitRules',
        'originUserId',
        'originMethod',
        'originAmount',
        'originCurrency',
        'originCountry',
        'destinationUserId',
        'destinationMethod',
        'destinationAmount',
        'destinationCurrency',
        'destinationCountry',
      ],
    },
  })

  const [page, setPage] = useState(0)
  const [filtersOpen, setFiltersOpen] = useState(false)

  const { t } = useTranslation(['common'])
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const { field, sort } = useMemo(
    () => (config.sortModel?.length ? config.sortModel[0] : { field: '', sort: '' }),
    [config.sortModel],
  )
  const {
    data: allTransactions,
    isLoading,
    isFetching,
    refetch,
  } = useFindTransactionsQuery({
    ...queryState,
    emailClient,
    page,
    limit: config.rowsPerPage,
    offset: config.rowsPerPage * page,
    sortField: field,
    sortDirection: sort === 'desc' ? -1 : 1,
    timezone,
    shadowRuleInstanceId: queryState.shadowRuleInstanceId || shadowRuleInstanceId,
  })

  const defaultFromDate = queryState.fromDate ? dayjs(queryState.fromDate) : null
  const defaultToDate = queryState.toDate ? dayjs(queryState.toDate) : null

  const { activeServices } = useSelector((state) => state.login)

  const handleResetButton = () => {
    setPage(0)
    dispatch(resetTransactionsFilters())
  }

  const handleDispatch = (values) => {
    const { fromDate, toDate, fromTime, toTime, ...filters } = values

    return dispatch(
      updateTransactionsFilters({
        viewMode: true,
        page,
        limit: config.rowsPerPage,
        offset: config.rowsPerPage * page,
        fromDate: formatDate(fromDate, fromTime),
        toDate: formatDate(toDate, toTime),
        ...filters,
      }),
    )
  }

  return (
    <Box className="filter">
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t('common:transactions')}</Typography>
          {activeServices.includes('reportsitem') && (
            <Button
              variant="outlined"
              disabled={!activeServices.includes('reportsitem') || !allTransactions?.data?.length}
              onClick={() => setShowDownloadModal(true)}
              endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
            >
              <Typography variant="title2">{t('common:exportReport')}</Typography>
            </Button>
          )}
        </Box>
      </Box>
      <Paper elevation={0}>
        <Datatable
          transactionsPage={allTransactions}
          page={page}
          setPage={setPage}
          setModalOpen={setFiltersOpen}
          isLoading={isLoading || isFetching || configLoading}
          refetch={refetch}
          config={config}
          saveConfig={saveConfig}
        />
      </Paper>

      <Modal open={showDownloadModal} onClose={() => setShowDownloadModal(false)}>
        <div>
          <DataExportModal
            show={showDownloadModal}
            onClose={() => setShowDownloadModal(false)}
            data={allTransactions?.data}
            fileName={'transactions'}
            dataMapper={kytTransactionDataMapper}
            dataMapperForPDF={kytTransactionDataMapperForPDF}
            typeDataExport={'transactions'}
          />
        </div>
      </Modal>

      <Modal open={filtersOpen} onClose={() => setFiltersOpen(false)}>
        <div>
          <FiltersTransactionsForm
            defaultValues={{
              transactionId: queryState.transactionId,
              fromDate: defaultFromDate || null,
              fromTime: defaultFromDate ? defaultFromDate.format('HH:mm') : '00:00',
              toDate: defaultToDate || null,
              toTime: defaultToDate ? defaultToDate.format('HH:mm') : '23:59',
              transactionType: queryState.transactionType,
              transactionState: queryState.transactionState,
              originUserId: queryState.originUserId,
              destinationUserId: queryState.destinationUserId,
              originCurrency: queryState.originCurrency,
              originMethod: queryState.originMethod,
              destinationCurrency: queryState.destinationCurrency,
              destinationMethod: queryState.destinationMethod,
              ruleId: queryState.ruleId,
              ruleInstanceId: queryState.ruleInstanceId,
              shadowRuleInstanceId,
              maxRuleAction: queryState.maxRuleAction,
              documentNumber: queryState.documentNumber,
              legalName: queryState.legalName,
              transactionValue: queryState.transactionValue,
            }}
            setModalOpen={setFiltersOpen}
            handleResetButton={handleResetButton}
            handleDispatch={handleDispatch}
            setPage={setPage}
          />
        </div>
      </Modal>
    </Box>
  )
}

export default Transaction

import { memo } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import '../case.scss'

const ConfigByStatus = {
  REVIEW_IN_PROGRESS: {
    backgroundColor: 'var(--alert-light)',
    color: 'var(--alert-dark)',
  },
  REVIEW_FINISHED: {
    backgroundColor: 'var(--alert-light)',
    color: 'var(--alert-dark)',
  },
  CLOSED_WITH_ACTIONS: {
    backgroundColor: 'var(--success-light)',
    color: 'var(--success-dark)',
  },
  CLOSED_WITHOUT_ACTIONS: {
    backgroundColor: 'var(--success-light)',
    color: 'var(--success-dark)',
  },
  PENDING: {
    backgroundColor: 'var(--gray-40)',
    color: 'var(--gray-70)',
  },
}

const Status = ({ status }) => {
  const { t } = useTranslation(['common'])

  const config = ConfigByStatus[status] || { backgroundColor: '', color: '' }

  return (
    <Box style={{ backgroundColor: config.backgroundColor }} className="status-icon">
      <p style={{ color: config.color }}>{t(`common:${status}`)}</p>
    </Box>
  )
}

export default memo(Status)

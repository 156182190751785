import { Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { useTranslation } from 'react-i18next'

import RiskMatrixEvaluatedData from './RiskMatrixEvaluatedData'
import RiskMatrixValueData from './RiskMatrixValueData'
import { useMemo } from 'react'

const RiskMatrixResultItem = ({
  country,
  groupByCategories = false,
  category,
  attribute,
  operatorFound,
  operator,
  value,
  dataToEvaluate,
  isHit,
  score,
  list,
}) => {
  const { t } = useTranslation(['common', 'messages'])

  const dataToEvaluateNotFound = dataToEvaluate === null || dataToEvaluate === undefined

  const highlights = useMemo(() => {
    if (operator === 'any_includes_any' && Array.isArray(value)) {
      return value
    } else if (operator === 'any_includes' && typeof value === 'string') {
      return [value]
    }

    return []
  }, [operator, value])

  return (
    <Grid
      container
      columnSpacing={2}
      sx={{ backgroundColor: dataToEvaluateNotFound ? 'lightpink' : 'unset', borderTop: '1px solid gray' }}
      minHeight={50}
      alignItems="center"
    >
      <Grid xs={1.5}>
        <Typography>{category}</Typography>
      </Grid>
      <Grid xs={3}>
        <Typography>{attribute && t(`messages:riskmatrix.attribute.${country}.${attribute}`)}</Typography>
      </Grid>
      <Grid xs={2}>
        {dataToEvaluateNotFound ? (
          <Typography>{t(`messages:riskmatrix.errors.dataToEvaluateNotFound`)}</Typography>
        ) : (
          <RiskMatrixEvaluatedData data={dataToEvaluate} highlights={highlights} />
        )}
      </Grid>
      <Grid xs={1.5}>
        <Typography>{operator && t(`messages:riskmatrix.operator.${operator}`)}</Typography>
      </Grid>
      <Grid xs={2}>
        <RiskMatrixValueData data={value} list={operator === 'in_custom_list' ? list : undefined} />
      </Grid>
      <Grid xs={1} textAlign="center">
        {!operatorFound ? (
          <Typography>Error</Typography>
        ) : (
          <Typography>{t(`common:${isHit ? 'yes' : 'no'}`)}</Typography>
        )}
      </Grid>
      <Grid xs={1} textAlign="center">
        <Typography>{score}</Typography>
      </Grid>
    </Grid>
  )
}

export default RiskMatrixResultItem

import { buildTimeStringFromTimestamp } from '../../time/timeHelper'

export const userAdminDataMapperForPDF = (data) => {
  const header = ['Id', 'Email', 'Roles', 'Status', 'Created', 'Updated']
  const parsedData = data.map((user) => {
    return [
      user._id,
      user.email,
      user.roles.map((role) => `${role}`),
      user.status,
      buildTimeStringFromTimestamp(user.createdAt),
      buildTimeStringFromTimestamp(user.updatedAt),
    ]
  })

  return {
    columns: header,
    body: parsedData,
  }
}

export const userAdminDataMapper = (data) => {
  return data.map((user) => {
    return {
      Id: user._id,
      Email: user.email,
      Roles: user.roles.map((role) => `${role}`),
      Services: user.services,
      Status: user.status,
      Created: buildTimeStringFromTimestamp(user.createdAt),
      Updated: buildTimeStringFromTimestamp(user.updatedAt),
      LastLogin: buildTimeStringFromTimestamp(user.lastLogin),
    }
  })
}

import './datatable.scss'
import Spinner from '../../../components/common/spinner/spinner'
import { Autocomplete, Box, Button, Paper, TextField, Typography } from '@mui/material'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useBatchTemporalItems } from '../../../pages/pages-hooks/useBatches'
import { updateTemporalFilters } from '../../../features/batch/batchSlice'
import { FilterModal } from '../../utilities/FilterModal'
import { DataGridComponent } from '../datagrid/DataGridComponent'
import DeleteIcon from '@mui/icons-material/Delete'

const DatatableBatchTemporalItems = ({ id, internalType }) => {
  const { accessToken, activeServices } = useSelector((state) => state.login)
  const [isLoading, setIsLoading] = useState(true)
  const [status, setStatus] = useState('')
  const temporalItems = useSelector((state) => state.batch.temporalItems)
  const batchItems = Array.isArray(temporalItems?.data) ? [...temporalItems.data] : []
  const { t } = useTranslation(['common', 'messages'])
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)

  useBatchTemporalItems(setIsLoading, isLoading, id)

  const handleTemporalItemDelte = (id) => {
    const urlUser = `${process.env.REACT_APP_BASEURL}/batch/temporalItems/${id}`
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }

    fetch(urlUser, options)
      .then((res) => res.json())
      .then((res) => {
        if (!res.success) throw Error(typeof res.data === 'string' ? res.data : res.message)
        setIsLoading(true)
      })
      .catch((error) => {
        console.error('[UPDATE WORKFLOW ERROR] --> ', error)
        setIsLoading(true)
      })
  }

  const columnsConsumers = useMemo(
    () => [
      {
        field: 'status',
        headerName: t('common:status'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.status ?? '---'}</Box>,
      },
      {
        field: 'actions',
        headerName: t('common:actions'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <Button
            disabled={!activeServices?.includes('batchtemporalItems:itemId')}
            variant="outlinedButtonError"
            onClick={() => {
              handleTemporalItemDelte(params?.id)
            }}
          >
            <DeleteIcon />
          </Button>
        ),
      },
      {
        field: 'userId',
        headerName: t('common:userId'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.data.userId ?? '---'}</Box>,
      },
      {
        field: 'createdTimestamp',
        headerName: t('common:createdTimestamp'),
        flex: 1,
        minWidth: 180,
        renderCell: (params) => {
          return buildTimeStringFromTimestamp(params.row.data.createdTimestamp)
        },
      },
      {
        field: 'firstName',
        headerName: t('common:firstName'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <Box className="cellOverflow">{params.row.data.userDetails?.name?.firstName ?? '---'}</Box>
        ),
      },
      {
        field: 'middleName',
        headerName: t('common:middleName'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <Box className="cellOverflow">{params.row.data.userDetails?.name?.middleName ?? '---'}</Box>
        ),
      },
      {
        field: 'lastName',
        headerName: t('common:lastName'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <Box className="cellOverflow">{params.row.data.userDetails?.name?.lastName ?? '---'}</Box>
        ),
      },
      {
        field: 'dateOfBirth',
        headerName: t('common:dateOfBirth'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.data.userDetails?.dateOfBirth ?? '---'}</Box>,
      },
      {
        field: 'countryOfResidence',
        headerName: t('common:countryOfResidence'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <Box className="cellOverflow">{params.row.data.userDetails?.countryOfResidence ?? '---'}</Box>
        ),
      },
      {
        field: 'countryOfNationality',
        headerName: t('common:countryOfNationality'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <Box className="cellOverflow">{params.row.data.userDetails?.countryOfNationality ?? '---'}</Box>
        ),
      },
      {
        field: 'gender',
        headerName: t('common:gender'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.data.userDetails?.gender ?? '---'}</Box>,
      },
      {
        field: 'riskLevel',
        headerName: t('common:riskLevel'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.data.riskLevel ?? '---'}</Box>,
      },
      {
        field: 'kycStatusDetails',
        headerName: t('common:kycStatusDetails'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.data.kycStatusDetails?.status ?? '---'}</Box>,
      },
      {
        field: 'userStateDetails',
        headerName: t('common:userStateDetails'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.data.userStateDetails?.state ?? '---'}</Box>,
      },
      {
        field: 'description',
        headerName: t('common:description'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.description ?? '---'}</Box>,
      },
    ],
    [],
  )

  const columnsBusiness = useMemo(
    () => [
      {
        field: 'status',
        headerName: t('common:status'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.status ?? '---'}</Box>,
      },
      {
        field: 'userId',
        headerName: t('common:userId'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.data.userId ?? '---'}</Box>,
      },
      {
        field: 'createdTimestamp',
        headerName: t('common:createdTimestamp'),
        flex: 1,
        minWidth: 180,
        renderCell: (params) => {
          return buildTimeStringFromTimestamp(params.row.data.createdTimestamp)
        },
      },
      {
        field: 'legalName',
        headerName: t('common:legalName'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <Box className="cellOverflow">{params.row.data.legalEntity?.companyGeneralDetails?.legalName ?? '---'}</Box>
        ),
      },
      {
        field: 'registrationIdentifier',
        headerName: t('common:registrationIdentifier'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <Box className="cellOverflow">
            {params.row.data.legalEntity?.companyRegistrationDetails?.registrationIdentifier ?? '---'}
          </Box>
        ),
      },
      {
        field: 'registrationCountry',
        headerName: t('common:registrationCountry'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <Box className="cellOverflow">
            {params.row.data.legalEntity?.companyRegistrationDetails?.registrationCountry ?? '---'}
          </Box>
        ),
      },
      {
        field: 'taxIdentifier',
        headerName: t('common:taxIdentifier'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <Box className="cellOverflow">
            {params.row.data.legalEntity?.companyRegistrationDetails?.taxIdentifier ?? '---'}
          </Box>
        ),
      },
      {
        field: 'dateOfRegistration',
        headerName: t('common:dateOfRegistration'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <Box className="cellOverflow">
            {params.row.data.legalEntity?.companyRegistrationDetails?.dateOfRegistration ?? '---'}
          </Box>
        ),
      },
      {
        field: 'kycStatusDetails',
        headerName: t('common:kycStatusDetails'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.data.kycStatusDetails?.status ?? '---'}</Box>,
      },
      {
        field: 'userStateDetails',
        headerName: t('common:userStateDetails'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.data.userStateDetails?.state ?? '---'}</Box>,
      },
      {
        field: 'description',
        headerName: t('common:description'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.description ?? '---'}</Box>,
      },
    ],
    [],
  )

  const columnsTransactions = useMemo(
    () => [
      {
        field: 'status',
        headerName: t('common:status'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.status ?? '---'}</Box>,
      },
      {
        field: 'transactionId',
        headerName: t('common:transactionId'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.data.transactionId ?? '---'}</Box>,
      },
      {
        field: 'timestamp',
        headerName: t('common:timestamp'),
        flex: 1,
        minWidth: 180,
        renderCell: (params) => {
          return buildTimeStringFromTimestamp(params.row.data.timestamp)
        },
      },
      {
        field: 'type',
        headerName: t('common:type'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.data.type ?? '---'}</Box>,
      },
      {
        field: 'originUserId',
        headerName: t('common:originUserId'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.data.originUserId ?? '---'}</Box>,
      },
      {
        field: 'destinationUserId',
        headerName: t('common:destinationUserId'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.data.destinationUserId ?? '---'}</Box>,
      },
      {
        field: 'originAmount',
        headerName: t('common:originAmount'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <Box className="cellOverflow">{params.row.data.originAmountDetails?.transactionAmount ?? '---'}</Box>
        ),
      },
      {
        field: 'originCurrency',
        headerName: t('common:originCurrency'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <Box className="cellOverflow">{params.row.data.originAmountDetails?.transactionCurrency ?? '---'}</Box>
        ),
      },
      {
        field: 'originCountry',
        headerName: t('common:originCountry'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <Box className="cellOverflow">{params.row.data.originAmountDetails?.country ?? '---'}</Box>
        ),
      },
      {
        field: 'destinationAmount',
        headerName: t('common:destinationAmount'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <Box className="cellOverflow">{params.row.data.destinationAmountDetails?.transactionAmount ?? '---'}</Box>
        ),
      },
      {
        field: 'destinationCurrency',
        headerName: t('common:destinationCurrency'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <Box className="cellOverflow">{params.row.data.destinationAmountDetails?.transactionCurrency ?? '---'}</Box>
        ),
      },
      {
        field: 'destinationCountry',
        headerName: t('common:destinationCountry'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <Box className="cellOverflow">{params.row.data.destinationAmountDetails?.country ?? '---'}</Box>
        ),
      },
      {
        field: 'description',
        headerName: t('common:description'),
        flex: 2,
        minWidth: 150,
        renderCell: (params) => <Box className="cellOverflow">{params.row.description ?? '---'}</Box>,
      },
    ],
    [],
  )

  const handleResetButton = () => {
    if (status !== '') setStatus('')
    setPage(0)
    setIsLoading(true)

    return dispatch(
      updateTemporalFilters({
        viewMode: true,
        page: 0,
        limit: 50,
        offset: 0,
        status: '',
      }),
    )
  }

  const handleDispatch = () => {
    setPage(0)
    setIsLoading(true)

    return dispatch(
      updateTemporalFilters({
        page,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        status,
      }),
    )
  }

  return (
    <Box className={`${isLoading && 'spinner-transition'} filter`}>
      {isLoading && <Spinner noTransform />}

      <Paper elevation={0}>
        <DataGridComponent
          rows={Array.isArray(batchItems) && batchItems.length > 0 ? batchItems : []}
          columns={
            internalType === 'TRANSACTION'
              ? columnsTransactions
              : internalType === 'CONSUMER'
              ? columnsConsumers
              : columnsBusiness
          }
          count={temporalItems?.pagination?.totalElements || 0}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          setIsLoading={setIsLoading}
          setModalOpen={setModalOpen}
          tableKey="batchTemporalItems"
        />
      </Paper>

      <FilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:status')}:
            </Typography>

            <Autocomplete
              size="small"
              value={status}
              onChange={(event, value) => {
                if (value) {
                  setStatus(value)
                } else {
                  setStatus(null)
                }
              }}
              disablePortal
              options={['READY', 'WRONG', 'PROCESSED', 'FAILED']}
              renderInput={(params) => <TextField variant="outlined" {...params} placeholder={t('common:status')} />}
            />
          </Box>
        </Box>
      </FilterModal>
    </Box>
  )
}

export default DatatableBatchTemporalItems

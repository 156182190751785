import React, { useMemo } from 'react'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { Box, Button, TablePagination } from '@mui/material'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import { useTranslation } from 'react-i18next'
import CustomLoadingOverlay from './CustomLoadingOverlay'
import useUserTableConfig from '../../hooks/useUserTableConfig'
import CustomColumnMenu from './CustomColumnMenu'
import { getOrderedColumns, getVisibilityModel, updateListOrder } from '../datatable/tableUtils/tableUtils'

export const DataGridComponent = ({
  rows,
  columns,
  count,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  setIsLoading,
  setModalOpen,
  hasTabs,
  isLoading = false,
  showFilterButton = true,
  showHideColumnsButton = true,
  tableKey,
}) => {
  const apiRef = useGridApiRef()
  const { t } = useTranslation(['common'])
  const {
    config,
    saveConfig,
    loading: configLoading,
  } = useUserTableConfig({
    tableKey: tableKey,
    defaultConfig: {
      sortModel: [{ field: 'createdTimestamp', sort: 'desc' }],
      rowsPerPage: rowsPerPage,
      pinnedColumns: { left: [], right: [] },
      columnOrder: columns?.map((c) => c.field),
      visibleColumns: columns?.reduce((acc, col) => {
        acc[col.field] = true
        return acc
      }, {}),
    },
  })

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
    setIsLoading(true)
  }

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    saveConfig({ rowsPerPage: newRowsPerPage })
  }

  const orderedColumns = useMemo(
    () => getOrderedColumns(config.columnOrder, columns),
    [JSON.stringify(config.columnOrder), columns],
  )

  const handleColumnOrderChange = (params) => {
    const updatedOrder = updateListOrder(config.columnOrder, params)
    if (JSON.stringify(config.columnOrder) !== JSON.stringify(updatedOrder)) {
      saveConfig({ columnOrder: updatedOrder })
    }
  }

  const handleColumnVisibilityModelChange = (newModel) => {
    saveConfig({ visibleColumns: newModel })
  }

  const visibleColumnsModel = useMemo(
    () => getVisibilityModel(config.visibleColumns, columns),
    [config.visibleColumns, columns],
  )

  return (
    <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      {(showFilterButton || showHideColumnsButton) && (
        <Box className="filter-bar">
          {showFilterButton && (
            <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setModalOpen(true)}>
              {t('common:filters')}
            </Button>
          )}
          {showHideColumnsButton && (
            <Button
              variant="label"
              endIcon={<MenuOutlinedIcon />}
              onClick={() => apiRef.current.showPreferences('columns')}
            >
              {t('common:showHideColumns')}
            </Button>
          )}
        </Box>
      )}
      <DataGridPro
        getRowHeight={() => 'auto'}
        getRowId={(row) => row._id || row.id}
        rows={rows || []}
        columns={orderedColumns}
        apiRef={apiRef}
        slots={{
          columnMenu: CustomColumnMenu,
          loadingOverlay: CustomLoadingOverlay,
        }}
        hideFooter={true}
        autoHeight
        sortModel={config.sortModel}
        onSortModelChange={(newSortModel) => saveConfig({ sortModel: newSortModel })}
        columnOrder={config.columnOrder}
        onColumnOrderChange={handleColumnOrderChange}
        pinnedColumns={config.pinnedColumns}
        onPinnedColumnsChange={(newPinnedColumns) => saveConfig({ pinnedColumns: newPinnedColumns })}
        columnVisibilityModel={visibleColumnsModel}
        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        loading={isLoading || configLoading}
        sx={{
          height: hasTabs ? `calc(100vh - ${hasTabs})` : 'calc(100vh - 19.5rem)',
          '& .MuiDataGrid-virtualScroller': {
            overflow: 'auto',
          },
        }}
      />

      <TablePagination
        labelRowsPerPage={[]}
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={config.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton
        showLastButton
      />
    </Box>
  )
}

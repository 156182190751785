import { Autocomplete, Box, Chip, FormControl, FormHelperText, TextField, Typography } from '@mui/material'
import styles from './styles.module.scss'
import { RenderTooltip } from '../../../../../components/utilities/RenderTooltip'

const TagsInput = ({
  value,
  onChange,
  error,
  label,
  placeholder = '',
  tooltip = '',
  options = [],
  required = false,
  disabled = false,
}) => {
  return (
    <Box className={styles.container}>
      <Box className={styles.label}>
        {!!label && <Typography variant="subtitle2">{label}</Typography>}
        {!!label && !!required && <Typography variant="subtitle3">(*)</Typography>}
        {!!tooltip && <RenderTooltip title={tooltip} />}
      </Box>
      <FormControl error={!!error}>
        <Autocomplete
          size="small"
          multiple
          disabled={disabled}
          placeholder={placeholder}
          value={value || []}
          onChange={(_, values) => onChange(values)}
          options={options}
          renderInput={(params) => <TextField variant="outlined" {...params} placeholder={placeholder} />}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip key={option} variant="outlined" color="primary" label={option} {...getTagProps({ index })} />
            ))
          }
          freeSolo
          disablePortal
        />
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>
    </Box>
  )
}

export default TagsInput

import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, TablePagination, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useMemo, useState } from 'react'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { useFindAllKytImportItemsQuery } from '../../../features/kyt-import/kytImportSlice'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import CustomColumnMenu from '../../../components/common/datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../../../components/common/datagrid/CustomLoadingOverlay'
import { useProcessTransactionsMutation } from '../../../services/guenoApiRv'
import {
  getOrderedColumns,
  getVisibilityModel,
  updateListOrder,
} from '../../../components/common/datatable/tableUtils/tableUtils'
import useUserTableConfig from '../../../components/hooks/useUserTableConfig'

const DatatableCsv = () => {
  const { t } = useTranslation(['common', 'messages'])
  const [page, setPage] = useState(0)
  const [processItem, { isLoading: isProcessingItem, originalArgs }] = useProcessTransactionsMutation()

  const columns = useMemo(
    () => [
      {
        field: '_id',
        headerName: 'ID',
        flex: 2,
        renderCell: ({ row }) => <Typography variant="number">{row._id}</Typography>,
        sortable: false,
      },
      {
        field: 'status',
        headerName: t('common:status'),
        flex: 1,
        minWidth: 100,
        renderCell: ({ row }) => <Typography variant="body2">{row.status || '---'}</Typography>,
        sortable: false,
      },
      {
        field: 'type',
        headerName: t('common:type'),
        flex: 1,
        minWidth: 100,
        renderCell: ({ row }) => <Typography variant="body2">{row.type}</Typography>,
        sortable: false,
      },
      {
        field: 'name',
        headerName: t('common:name'),
        flex: 3,
        minWidth: 100,
        renderCell: ({ row }) => <Typography variant="body2">{row.originalName || '---'}</Typography>,
        sortable: false,
      },
      {
        field: 'createdAt',
        headerName: t('common:creationDate'),
        flex: 3,
        renderCell: ({ row }) => (
          <Typography variant="number">{buildTimeStringFromTimestamp(row.createdAt)}</Typography>
        ),
        sortable: false,
      },
      {
        field: 'actions',
        headerName: t('common:actions'),
        flex: 2,
        renderCell: ({ row }) => {
          if (row.status === 'IMPORTED' && row.type === 'transactions') {
            return (
              <LoadingButton
                loading={isProcessingItem && row._id === originalArgs.itemId}
                disabled={isProcessingItem}
                variant="outlined"
                onClick={() => handleProcess(row._id)}
              >
                {t('messages:kyt-import.actions.process')}
              </LoadingButton>
            )
          }

          return '---'
        },
        sortable: false,
      },
    ],
    [],
  )

  const {
    config,
    saveConfig,
    loading: configLoading,
  } = useUserTableConfig({
    tableKey: 'kytImportCSV',
    defaultConfig: {
      sortModel: [{ field: 'updatedAt', sort: 'desc' }],
      rowsPerPage: 10,
      pinnedColumns: { left: [], right: [] },
      columnOrder: columns.map((c) => c.field),
    },
  })

  const { data, isFetching, isLoading, refetch } = useFindAllKytImportItemsQuery({ page, limit: config.rowsPerPage })

  const { items, pagination } = useMemo(() => {
    if (data?.data) {
      return data?.data
    }
    return { items: [], pagination: null }
  }, [data])

  const apiRef = useGridApiRef()

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    saveConfig({ rowsPerPage: newRowsPerPage })

    setPage(0)
  }

  const handleProcess = async (id) => {
    await processItem({ itemId: id }).unwrap()
    refetch()
  }

  const orderedColumns = useMemo(
    () => getOrderedColumns(config.columnOrder, columns),
    [JSON.stringify(config.columnOrder), columns],
  )

  const handleColumnOrderChange = (params) => {
    const updatedOrder = updateListOrder(config.columnOrder, params)
    if (JSON.stringify(config.columnOrder) !== JSON.stringify(updatedOrder)) {
      saveConfig({ columnOrder: updatedOrder })
    }
  }

  const visibleColumnsModel = useMemo(
    () => getVisibilityModel(config.visibleColumns, columns),
    [config.visibleColumns, columns],
  )

  return (
    <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <Box className="filter-bar">
        <Button
          variant="label"
          endIcon={<MenuOutlinedIcon />}
          // onClick={() => setModalOpen(true)}
          disabled
        >
          {t('common:filters')}
        </Button>
        <TablePagination
          labelRowsPerPage={[]}
          component="div"
          count={pagination?.totalElements || 0}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          rowsPerPage={config.rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <IconButton onClick={refetch} disabled={isLoading} title={t('common:refresh')}>
          <RefreshIcon color="action" />
        </IconButton>
      </Box>
      <DataGridPro
        getRowHeight={() => 'auto'}
        getRowId={(row) => row._id}
        rows={items}
        columns={orderedColumns}
        apiRef={apiRef}
        slots={{
          columnMenu: CustomColumnMenu,
          loadingOverlay: CustomLoadingOverlay,
        }}
        hideFooter={true}
        autoHeight
        loading={isLoading || isFetching || configLoading}
        columnOrder={config.columnOrder}
        onColumnOrderChange={handleColumnOrderChange}
        pinnedColumns={config.pinnedColumns}
        onPinnedColumnsChange={(newPinnedColumns) => saveConfig({ pinnedColumns: newPinnedColumns })}
        columnVisibilityModel={visibleColumnsModel}
        onColumnVisibilityModelChange={(newModel) => saveConfig({ visibleColumns: newModel })}
      />
    </Box>
  )
}

export default DatatableCsv

import './datatable.scss'
import { useSelector } from 'react-redux'
import { Box, Link, Typography } from '@mui/material'
import { toLocaleUTCDateString } from '../time/timeHelper'
import { TableChip } from '../../utilities/TableChip'
import { useAmlCrypto } from '../../../pages/pages-hooks/useAmlCrypto'
import { useTranslation } from 'react-i18next'
import { DataGridComponent } from '../datagrid/DataGridComponent'
import { useMemo } from 'react'

const DatatableAmlCrypto = ({ page, setPage, rowsPerPage, setRowsPerPage, setIsLoading, setModalOpen }) => {
  const { emailClient } = useSelector((state) => state.login)
  const amlCrypto = useAmlCrypto(emailClient, setIsLoading)
  const { t } = useTranslation(['common'])

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'Id',
        renderCell: (params) => {
          return (
            <Link href={`/amlCrypto/${params.row._id}`} title={params.row._id}>
              <Typography variant="number">{params.row._id}</Typography>
            </Link>
          )
        },
      },
      {
        field: 'address',
        headerName: t('common:address'),
        renderCell: (params) => {
          return <Box>{params.row.address ?? '---'}</Box>
        },
      },
      {
        field: 'protocol',
        headerName: t('common:protocol'),
        renderCell: (params) => {
          return (
            <Typography variant="number">{params.row.protocol ? params.row.protocol.toUpperCase() : '---'}</Typography>
          )
        },
      },
      {
        field: 'type',
        headerName: t('common:type'),
        renderCell: (params) => {
          return <Box>{params.row.type ?? '---'}</Box>
        },
      },
      {
        field: 'alert',
        headerName: t('common:isValid'),
        renderCell: (params) => {
          return (
            <Box>
              {params.row.check ? (
                <TableChip action={params.row.check?.is_address_valid ? 'valid' : 'invalid'} noIcon={true} />
              ) : (
                '---'
              )}
            </Box>
          )
        },
      },
      {
        field: 'category',
        headerName: t('common:categories'),
        renderCell: (params) => {
          return <Box>{params.row.check?.self?.category ? params.row.check?.self?.category.toString() : '---'}</Box>
        },
      },
      {
        field: 'score',
        headerName: t('common:score'),
        renderCell: (params) => {
          return <Box>{params.row.score ?? '---'}</Box>
        },
      },
      {
        field: 'level',
        headerName: t('common:level'),
        renderCell: (params) => {
          return params.row.check?.risk?.level ? (
            <TableChip action={params.row.check?.risk?.level} noIcon={true} />
          ) : (
            '---'
          )
        },
      },
      {
        field: 'activity',
        headerName: t('common:activity'),
        renderCell: (params) => {
          return <Box>{params.row.activity ?? '---'}</Box>
        },
      },
      {
        field: 'date',
        headerName: t('common:date'),
        renderCell: (params) => {
          return <Typography variant="number">{toLocaleUTCDateString(params.row.createdAt)}</Typography>
        },
      },
    ],
    [],
  )

  return (
    <DataGridComponent
      rows={Array.isArray(amlCrypto?.data) && amlCrypto?.data.length !== 0 ? amlCrypto?.data : []}
      columns={columns}
      count={amlCrypto?.pagination?.totalElements || 0}
      page={page}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      setIsLoading={setIsLoading}
      setModalOpen={setModalOpen}
      tableKey="amlCrypto"
    />
  )
}

export default DatatableAmlCrypto

import '../reports.scss'
import '../../../components/common/datatable/datatable.scss'
import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import Spinner from '../../../components/common/spinner/spinner'
import { useReports } from '../../pages-hooks/useReports'
import { useState } from 'react'
import DataExportModal from '../../../components/common/dataExport/DataExportModal'
import {
  dataExportReportDataMapper,
  dataExportReportDataMapperForPDF,
} from '../../../components/common/dataExport/dataMappers/dataExportReportDataMapper'
import { useSelector } from 'react-redux'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import { useTranslation } from 'react-i18next'
import CustomNoRowsOverlay from '../../../components/common/datagrid/CustomNoRowsOverlay'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'

const createRow = (report) => {
  let result = ''
  for (const key in report?.activityDetails?.filters) {
    if (report.activityDetails.filters.hasOwnProperty(key)) {
      result += `${key}: ${report.activityDetails.filters[key]}, `
    }
  }
  return {
    _id: report?._id,
    status: report?.activityDetails?.actionResult,
    timestamp: report?.createdAt,
    email: report?.emailUser,
    extension: report?.activityDetails?.extension,
    filters: result,
    collection: report?.activityDetails?.data,
  }
}

const GeneratedReport = ({
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
  isLoading,
  setIsLoading,
  setModalOpen,
}) => {
  const { activeServices } = useSelector((state) => state.login)
  const allReports = useReports('generated_reports', setIsLoading, isLoading)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const { t } = useTranslation(['common', 'messages'])

  return (
    <Box className="datatable">
      {isLoading && <Spinner noTransform />}

      <TableContainer className="wrapper">
        <Box className="filter-bar">
          <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setModalOpen(true)}>
            {t('common:filters')}
          </Button>
          <Box className="title">
            {activeServices?.includes('reportsitem') && allReports?.data?.length > 0 && (
              <Button
                disabled={!activeServices?.includes('reportsitem') || !allReports?.data?.length}
                onClick={() => setShowDownloadModal(true)}
                endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
                variant="outlined"
              >
                {/* <DownloadIcon /> */}
                <Typography variant="title2">{t('common:exportReport')}</Typography>
              </Button>
            )}
          </Box>
        </Box>
        <Table className="table">
          <TableHead className="table-head">
            <TableRow>
              <TableCell>{t('common:Id')}</TableCell>
              <TableCell>{t('common:user')}</TableCell>
              <TableCell>{t('common:status')}</TableCell>
              <TableCell>{t('common:filters')}</TableCell>
              <TableCell>{t('common:extension')}</TableCell>
              <TableCell>{t('common:collection')}</TableCell>
              <TableCell>{t('common:date')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(allReports?.data) && allReports.data.length > 0 ? (
              allReports.data
                .map((report) => createRow(report))
                .map((row, index) => {
                  const back = index % 2 === 0 ? '' : 'gray-back'
                  return (
                    <TableRow className={back} key={index}>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>{row.filters}</TableCell>
                      <TableCell>{row.extension}</TableCell>
                      <TableCell>{row.collection}</TableCell>
                      <TableCell>{buildTimeStringFromTimestamp(row.timestamp)}</TableCell>
                    </TableRow>
                  )
                })
            ) : (
              <Box className="table-custom-overlay">
                <CustomNoRowsOverlay />
              </Box>
            )}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage={[]}
          component="div"
          count={allReports?.pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Modal open={showDownloadModal} onClose={() => setShowDownloadModal(false)}>
        <div>
          <DataExportModal
            show={showDownloadModal}
            onClose={() => setShowDownloadModal(false)}
            data={allReports?.data}
            dataMapper={dataExportReportDataMapper}
            dataMapperForPDF={dataExportReportDataMapperForPDF}
            fileName={'data-export-report'}
            typeDataExport={'reports'}
            keyReports={'generated_reports'}
          />
        </div>
      </Modal>
    </Box>
  )
}

export default GeneratedReport

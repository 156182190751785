import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserDetails } from '../../features/users/userSlice'

export const useUsersById = (userId, type) => {
  const dispatch = useDispatch()
  const queryState = useSelector((state) => state.user.filters)
  const { accessToken } = useSelector((state) => state.login)

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  useEffect(() => {
    if (type === 'Consumer' || type === 'Business') {
      let url = `${process.env.REACT_APP_BASEURL}/kyt/retrieve${type}User/${userId}?viewMode=true`

      if (process.env.REACT_APP_IS_DEMO) {
        if (type === 'Consumer') url = '../data/kyt.consumer.json'
        else url = '../data/kyt.business.json'
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res && res.success) {
            dispatch(updateUserDetails(res.data))
          }
        })
        .catch((error) => {
          console.error('[HOOK: useUsersById] --> ', error)
        })
    }
  }, [queryState])
}

export const usersExport = (paramLimit, accessToken, queryState, type) => {
  const {
    userId,
    fromDate,
    toDate,
    firstName,
    middleName,
    lastName,
    countryOfResidence,
    countryOfNationality,
    legalName,
    countryOfRegistration,
    userKey,
    userValue,
    documentNumber,
    selectedUserState,
    userKycStatus,
  } = queryState

  const baseUrlUsers = `${process.env.REACT_APP_BASEURL}/dataExport/retrieveData/${type}`

  const buildUrl = (baseUrl) => {
    let url = baseUrl
    let hasQueryParams = false
    if (paramLimit !== undefined) {
      url += `?limit=${paramLimit}`
      hasQueryParams = true
    }
    if (userId?.length) {
      url += `${hasQueryParams ? '&' : '?'}userId=${userId}`
      hasQueryParams = true
    }
    if (fromDate && toDate) {
      url += `${hasQueryParams ? '&' : '?'}fromDate=${fromDate}&toDate=${toDate}`
      hasQueryParams = true
    }
    if (firstName?.length) {
      url += `${hasQueryParams ? '&' : '?'}firstName=${firstName}`
      hasQueryParams = true
    }
    if (middleName?.length) {
      url += `${hasQueryParams ? '&' : '?'}middleName=${middleName}`
      hasQueryParams = true
    }
    if (lastName?.length) {
      url += `${hasQueryParams ? '&' : '?'}lastName=${lastName}`
      hasQueryParams = true
    }
    if (countryOfResidence?.length) {
      url += `${hasQueryParams ? '&' : '?'}countryOfResidence=${countryOfResidence}`
      hasQueryParams = true
    }
    if (countryOfNationality?.length) {
      url += `${hasQueryParams ? '&' : '?'}countryOfNationality=${countryOfNationality}`
      hasQueryParams = true
    }
    if (legalName?.length) {
      url += `${hasQueryParams ? '&' : '?'}legalName=${legalName}`
      hasQueryParams = true
    }
    if (countryOfRegistration?.length) {
      url += `${hasQueryParams ? '&' : '?'}countryOfRegistration=${countryOfRegistration}`
      hasQueryParams = true
    }
    if (userKey?.length) {
      url += `${hasQueryParams ? '&' : '?'}userKey=${userKey}`
      hasQueryParams = true
    }
    if (userValue?.length) {
      url += `${hasQueryParams ? '&' : '?'}userValue=${userValue}`
      hasQueryParams = true
    }
    if (selectedUserState?.length) {
      url += `${hasQueryParams ? '&' : '?'}userState=${selectedUserState}`
      hasQueryParams = true
    }
    if (userKycStatus?.length) {
      url += `${hasQueryParams ? '&' : '?'}userKycStatus=${userKycStatus}`
      hasQueryParams = true
    }
    if (documentNumber?.length) {
      url += `${hasQueryParams ? '&' : '?'}documentNumber=${documentNumber}`
      hasQueryParams = true
    }
    return url
  }

  let urlGetExportUsers = buildUrl(baseUrlUsers)

  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  const url = process.env.REACT_APP_IS_DEMO
    ? type === 'consumerUsers'
      ? './data/kyt.consumer.view.json'
      : './data/kyt.business.view.json'
    : urlGetExportUsers

  fetch(url, options)
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`)
      }
    })
    .catch((error) => {
      console.error('[HOOK: transactionExport] --> ', error)
    })
}

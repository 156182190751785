import { useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import RichTextEditor from '../RichTextEditor'
import { useSummarizeCaseMutation } from '../../../../features/cases/caseSlice'
import toaster from '../../../../toaster'
import { LoadingButton } from '@mui/lab'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'

const SectionCreateReport = ({
  onCloseReport,
  onCreateReport,
  onUpdateReport,
  hasPermissions,
  lastReport,
  isAssigned,
  caseIsClosed,
  caseId,
}) => {
  const { activeServices } = useSelector((state) => state.login)
  const languageState = useSelector((state) => state.language.lang)
  const { t } = useTranslation(['common', 'messages'])
  const [report, setReport] = useState(lastReport?.description || '')

  useEffect(() => {
    if (lastReport?.description) setReport(lastReport.description)
  }, [lastReport?.description])

  const [summarizeCase, { isLoading: isSummarizingCase }] = useSummarizeCaseMutation()

  const handleSummarizeCase = async () => {
    await summarizeCase({ caseId, lang: languageState }).unwrap()
    toaster.success(t('messages:creationSuccess'), { autoClose: 5000, closeButton: true })
  }

  return (
    <Box className="kyb-info">
      <Box className="reports-title-row">
        <Typography variant="title">{t('common:report')}</Typography>
        {activeServices.includes('caseManagement:idsummarize') && !lastReport && (
          <LoadingButton
            color="secondary"
            size="small"
            disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
            onClick={handleSummarizeCase}
            loading={isSummarizingCase}
            endIcon={<AutoAwesomeIcon />}
          >
            {t('common:summarize')}
          </LoadingButton>
        )}
      </Box>
      <Box>
        <RichTextEditor
          value={report}
          onChange={setReport}
          disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
        />
      </Box>
      <Box className="reports-row">
        {lastReport && (
          <Button
            variant="outlinedGrey"
            size="small"
            disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
            onClick={() => {
              onCloseReport({ report })
              setReport('')
            }}
          >
            {t('common:close')} {t('common:report')}
          </Button>
        )}
        <Button
          color="secondary"
          variant="contained"
          disabled={
            (!hasPermissions && !isAssigned) ||
            caseIsClosed ||
            (!activeServices.includes('caseManagement:idreports') && !!activeServices.includes('caseReports:id'))
          }
          size="small"
          onClick={() => {
            !lastReport ? onCreateReport({ report }) : onUpdateReport({ report })
          }}
        >
          {!lastReport ? `${t('common:create')}` : `${t('common:update')}`} {t('common:report')}
        </Button>
      </Box>
    </Box>
  )
}

export default SectionCreateReport

import '../reports.scss'
import '../../../components/common/datatable/datatable.scss'
import { useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useSelector } from 'react-redux'
import Spinner from '../../../components/common/spinner/spinner'
import { useReports } from '../../pages-hooks/useReports'
import DiffComponent from '../../../components/utilities/DiffComponent'
import DataExportModal from '../../../components/common/dataExport/DataExportModal'
import { abmMapper, abmMapperForPDF } from '../../../components/common/dataExport/dataMappers/abmGenericMap'
import { buildTimeStringFromTimestamp } from '../../../components/common/time/timeHelper'
import { useTranslation } from 'react-i18next'
import CustomNoRowsOverlay from '../../../components/common/datagrid/CustomNoRowsOverlay'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'

const AbmGenericReport = ({
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
  reportKey,
  isLoading,
  setIsLoading,
  setModalOpen,
}) => {
  const { activeServices } = useSelector((state) => state.login)
  const [previous, setPrevious] = useState({})
  const [newImage, setNewImage] = useState({})
  const [info, setInfo] = useState({})
  const [open, setOpen] = useState(false)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const { t } = useTranslation(['common'])

  const allReports = useReports(reportKey, setIsLoading, isLoading)

  const handleClose = () => {
    setOpen(false)
    setPrevious({})
    setNewImage({})
    setInfo({})
  }

  return (
    <Box>
      {isLoading && <Spinner noTransform />}

      <TableContainer>
        <Box className="filter-bar">
          <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setModalOpen(true)}>
            {t('common:filters')}
          </Button>
          {activeServices?.includes('reportsitem') && allReports?.data?.length > 0 && (
            <Button
              disabled={!activeServices?.includes('reportsitem') || !allReports?.data?.length}
              onClick={() => setShowDownloadModal(true)}
              endIcon={<IosShareOutlinedIcon sx={{ fill: 'var(--lime-04)' }} />}
              variant="outlined"
            >
              {/* <DownloadIcon /> */}
              <Typography variant="title2">{t('common:exportReport')}</Typography>
            </Button>
          )}
        </Box>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('common:id')}</TableCell>
              <TableCell>
                {t('common:action')} {t('common:owner')}
              </TableCell>
              {reportKey === 'abm_users' && <TableCell>{t('common:user')}</TableCell>}
              {reportKey === 'abm_roles' && <TableCell>{t('common:role')}</TableCell>}
              <TableCell>{t('common:action')}</TableCell>
              <TableCell>{t('common:status')}</TableCell>
              <TableCell>{t('common:date')}</TableCell>
              <TableCell>{t('common:details')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Array.isArray(allReports?.data) && allReports?.data?.length > 0 ? (
              allReports.data.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{row.emailUser}</TableCell>
                    {reportKey === 'abm_users' && <TableCell>{row.activityDetails?.userABM}</TableCell>}
                    {reportKey === 'abm_roles' && (
                      <TableCell>
                        {row.activityDetails?.newRecord?.roleName
                          ? row.activityDetails.newRecord.roleName
                          : row.activityDetails?.previousRecord?.roleName}
                      </TableCell>
                    )}
                    <TableCell>{row.activityDetails?.abmAction}</TableCell>
                    <TableCell>{row.activityDetails?.actionResult}</TableCell>
                    <TableCell>{buildTimeStringFromTimestamp(row.createdAt)}</TableCell>
                    {(row.activityDetails?.previousRecord || row.activityDetails?.newRecord) && (
                      <TableCell>
                        <Box style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
                          <IconButton
                            onClick={() => {
                              setOpen(true)
                              setPrevious(row.activityDetails?.previousRecord)
                              setNewImage(row.activityDetails?.newRecord)
                              setInfo({
                                email: row.emailUser,
                                action: row.activityDetails?.abmAction,
                                result: row.activityDetails?.actionResult,
                                date: row.createdAt,
                              })
                            }}
                            style={{ color: '#0DDE4E' }}
                          >
                            <VisibilityIcon style={{ height: '1.45rem' }} />
                          </IconButton>
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                )
              })
            ) : (
              <Box className="table-custom-overlay">
                <CustomNoRowsOverlay />
              </Box>
            )}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage={[]}
          component="div"
          count={allReports?.pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Modal open={showDownloadModal} onClose={() => setShowDownloadModal(false)}>
        <div>
          <DataExportModal
            show={showDownloadModal}
            onClose={() => setShowDownloadModal(false)}
            data={allReports?.data}
            dataMapper={abmMapper(reportKey)}
            dataMapperForPDF={abmMapperForPDF(reportKey)}
            fileName={reportKey}
            typeDataExport={'reports'}
            keyReports={reportKey}
          />
        </div>
      </Modal>

      <Modal open={open} onClose={handleClose}>
        <Box className="modal">
          <DiffComponent
            previousData={previous}
            newData={newImage}
            info={info}
            reportKey={reportKey}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </Box>
  )
}

export default AbmGenericReport

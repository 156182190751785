import './datatable.scss'
import { Box, Typography, Link, Tooltip } from '@mui/material'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DataGridComponent } from '../datagrid/DataGridComponent'
import { TableIcons } from '../../utilities/TableIcons'
import { setPaymentMethodDetailUsersFilters } from '../../../features/paymentMethodDetailUsers/paymentMethodDetailUsersSlice'

const columnsByUserType = {
  business: {
    firstName: false,
    lastName: false,
    countryOfNationality: false,
    riskLevel: false,
  },
  consumer: {
    legalName: false,
    dateOfRegistration: false,
  },
}

const DatatableExternalPaymentMethodsDetailUsers = ({ setIsLoading, userType }) => {
  const { data: items, pagination } = useSelector((state) => state.paymentMethodDetailUsers)
  const { t } = useTranslation(['common', 'messages'])
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isFirstRender) setIsFirstRender(false)
  }, [])

  useEffect(() => {
    if (!isFirstRender) {
      handleDispatch()
    }
  }, [rowsPerPage, page])

  const handleDispatch = () => {
    const filters = {
      page,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    }
    dispatch(setPaymentMethodDetailUsersFilters(filters))
  }

  const columns = useMemo(
    () => [
      {
        field: 'userId',
        flex: 3,
        headerName: t('common:userId'),
        renderCell: (params) => {
          const userIdResume = params.row.userId?.slice(-20)
          return (
            <Tooltip title={params.row.userId}>
              <Link href={`/users/${params.row.userId}?type=${userType}`} target="_blank">
                <Typography variant="number">..{userIdResume}</Typography>
              </Link>
            </Tooltip>
          )
        },
      },
      {
        field: 'firstName',
        flex: 2,
        headerName: t('common:firstName'),
        renderCell: (params) => <Box className="cellOverflow">{params.row.firstName ?? '---'}</Box>,
      },
      {
        field: 'lastName',
        flex: 2,
        headerName: t('common:lastName'),
        renderCell: (params) => <Box className="cellOverflow">{params.row.lastName ?? '---'}</Box>,
      },
      {
        field: 'countryOfNationality',
        flex: 1,
        headerName: t('common:country'),
        renderCell: (params) => <Box className="cellOverflow">{params.row.countryOfNationality ?? '---'}</Box>,
      },

      {
        field: 'legalName',
        flex: 2,
        headerName: t('common:legalName'),
        renderCell: (params) => <Box className="cellOverflow">{params.row.legalName ?? '---'}</Box>,
      },
      {
        field: 'dateOfRegistration',
        flex: 2,
        headerName: t('common:dateOfRegistration'),
        renderCell: (params) => (
          <Box className="cellOverflow">
            {params.row.dateOfRegistration ? buildTimeStringFromTimestamp(params.row.dateOfRegistration) : '---'}
          </Box>
        ),
      },
      {
        field: 'state',
        flex: 1,
        headerName: t('common:state'),
        renderCell: (params) => {
          return params.row.state ? <TableIcons type={params.row.state} /> : '---'
        },
      },
      {
        field: 'createdTimestamp',
        flex: 2,
        headerName: t('common:createdAt'),
        renderCell: (params) => (
          <Box className="cellOverflow">{buildTimeStringFromTimestamp(params.row.createdTimestamp) ?? '---'}</Box>
        ),
      },
      {
        field: 'riskLevel',
        flex: 1.5,
        headerName: t('common:riskLevel'),
        renderCell: (params) => <Box className="cellOverflow">{params.row.riskLevel ?? '---'}</Box>,
      },
    ],
    [],
  )

  return (
    <DataGridComponent
      rows={Array.isArray(items) && items.length > 0 ? items : []}
      columns={columns}
      count={pagination?.totalElements || 0}
      page={page}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      showFilterButton={false}
      showHideColumnsButton={false}
      setIsLoading={setIsLoading}
      columnVisibilityModel={columnsByUserType[userType] || {}}
      tableKey="externalPMU"
    />
  )
}

export default DatatableExternalPaymentMethodsDetailUsers

import './CreateFilters.scss'
import '../../common/spinner/spinner.scss'
import SelectMultiple from './filterParameters/SelectMultiple/SelectMultiple'
import TimeRange from './filterParameters/TimeRange/TimeRange'
import { Box, Button, Divider, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import { useRuleFiltersGueno } from '../../../pages/pages-hooks/useRuleFilters'
import SelectSingle from './filterParameters/selectSingle/selectSingle'
import FatherListOfStrings from './filterParameters/fatherListOfStrings/fatherListOfStrings'
import StringArrayInput from './filterParameters/stringArrayInput/stringArrayInput'
import LowTransactionValues from './filterParameters/lowTransactionValues/lowTransactionValues'
import Tags from './filterParameters/tags/tags'
import PaymentFilters from './filterParameters/paymentFilters/paymentFilters'
import Lists from './filterParameters/lists/lists'
import { camelCaseFormatter } from '../../utilities/formatters/camelCase'
import MCCBusinessHours from './filterParameters/mccListBusinessHours/mccListBusinessHours'
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined'
import { useTranslation } from 'react-i18next'

const CreateGuenoFilters = ({ filterParameters, setFilterParameters, setError, isEditing, ruleId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [filters, setFilters] = useState({})
  useRuleFiltersGueno(isLoading, setIsLoading, filters, setFilters)
  const { t } = useTranslation(['common'])

  const removeFilterParameterById = (filterId) => {
    const updatedFilterParameters = filterParameters.filter((parameter) => parameter.filterTemplate !== filterId)
    setFilterParameters(updatedFilterParameters)
  }

  return (
    <Box>
      {isLoading && (
        <Box className="spinner-filters">
          <img src="/guenoSpinnerMini-blue.gif" alt="spinner-loading-data" width="120px" />
        </Box>
      )}
      {!isLoading &&
        Array.isArray(filters) &&
        filters.length &&
        filters[0]?.data
          ?.filter((f) => !f.isHistoricFilter || (f.isHistoricFilter && f.ruleTemplatesAvailable?.includes(ruleId)))
          .map((filter, index) => {
            return (
              <Box key={index}>
                <Box>
                  <Box sx={{ display: 'grid', gridTemplateColumns: '1fr auto', alignItems: 'center', gap: '1rem' }}>
                    <Box>
                      <Box className="title-parameter">
                        {filter.parametersSchema?.title && (
                          <Typography variant="title3">{camelCaseFormatter(filter.parametersSchema?.title)}</Typography>
                        )}
                      </Box>
                      {filter.parametersSchema?.type === 'array' &&
                        filter.parametersSchema?.items?.type === 'string' &&
                        filter.parametersSchema?.enum && (
                          <SelectMultiple
                            isEditing={isEditing}
                            propertie={filter.parametersSchema?.key}
                            ruleParameters={filterParameters}
                            setRuleParameters={setFilterParameters}
                            posibleValues={filter.parametersSchema?.enum}
                            filterTemplate={filter.id}
                          />
                        )}
                      {(filter.filterClass === 'TargetAgeRangeFilter' ||
                        filter.filterClass === 'CreationAgeRangeFilter' ||
                        filter.filterClass === 'CreationAgeRangeDestinationFilter') && (
                        <TimeRange
                          isEditing={isEditing}
                          propertie={filter.parametersSchema?.key}
                          ruleParameters={filterParameters}
                          setRuleParameters={setFilterParameters}
                          schema={filter}
                          desc={filter.description}
                          setError={setError}
                          filterTemplate={filter.id}
                        />
                      )}

                      {(filter.filterClass === 'UserTypeFilter' || filter.filterClass === 'CashDirectionFilter') && (
                        <SelectSingle
                          isEditing={isEditing}
                          propertie={filter.parametersSchema?.key}
                          ruleParameters={filterParameters}
                          setRuleParameters={setFilterParameters}
                          posibleValues={filter.parametersSchema?.enum}
                          schema={filter}
                          desc={filter.description}
                          filterTemplate={filter.id}
                        />
                      )}

                      {filter.filterClass === 'UserIdsExclusionFilter' && (
                        <FatherListOfStrings
                          isEditing={isEditing}
                          propertie={filter.parametersSchema?.key}
                          ruleParameters={filterParameters}
                          setRuleParameters={setFilterParameters}
                          schema={filter}
                          isRequired={filter.nullable}
                          setError={setError}
                          filterTemplate={filter.id}
                        />
                      )}

                      {(filter.filterClass === 'UserIdsTargetFilter' ||
                        filter.filterClass === 'ProductTypesFilter') && (
                        <StringArrayInput
                          isEditing={isEditing}
                          propertie={filter.parametersSchema?.key}
                          ruleParameters={filterParameters}
                          setRuleParameters={setFilterParameters}
                          filterTemplate={filter.id}
                        />
                      )}

                      {filter.filterClass === 'TransactionAmountRangeFilter' && (
                        <LowTransactionValues
                          isEditing={isEditing}
                          propertie={filter.parametersSchema?.key}
                          ruleParameters={filterParameters}
                          setRuleParameters={setFilterParameters}
                          filterTemplate={filter.id}
                        />
                      )}

                      {(filter.filterClass === 'OriginPaymentMethodsFilter' ||
                        filter.filterClass === 'DestinationPaymentMethodsFilter') && (
                        <PaymentFilters
                          isEditing={isEditing}
                          propertie={filter.parametersSchema?.key}
                          ruleParameters={filterParameters}
                          setRuleParameters={setFilterParameters}
                          possibleValues={filter?.parametersSchema?.properties?.paymentMethods?.enum}
                          possibleCountries={filter?.parametersSchema?.properties?.cardIssuedCountries?.enum}
                          possibleChannels={filter?.parametersSchema?.properties?.cardPaymentChannels?.enum}
                          filterTemplate={filter.id}
                        />
                      )}

                      {(filter.filterClass === 'UserTagsFilter' ||
                        filter.filterClass === 'TransactionTagsFilter' ||
                        filter.filterClass === 'TransactionTagsExclusionFilter') && (
                        <Tags
                          isEditing={isEditing}
                          propertie={filter.parametersSchema?.key}
                          ruleParameters={filterParameters}
                          setRuleParameters={setFilterParameters}
                          filterTemplate={filter.id}
                          description={filter.description}
                          type={filter.filterClass === 'UserTagsFilter' ? 'users' : 'transactions'}
                        />
                      )}
                      {filter.filterClass === 'WhitelistIdsExclusionFilter' && (
                        <Lists
                          isEditing={isEditing}
                          propertie={filter.parametersSchema?.key}
                          ruleParameters={filterParameters}
                          setRuleParameters={setFilterParameters}
                          schema={filter}
                          isRequired={filter.nullable}
                          setError={setError}
                          filterTemplate={filter.id}
                        />
                      )}

                      {filter.filterClass === 'MCCBusinessHoursFilter' && (
                        <MCCBusinessHours
                          isEditing={isEditing}
                          propertie={filter.parametersSchema?.key}
                          ruleParameters={filterParameters}
                          setRuleParameters={setFilterParameters}
                          schema={filter}
                          isRequired={filter.nullable}
                          filterTemplate={filter.id}
                          description={filter.description}
                        />
                      )}
                    </Box>
                    <Tooltip title={t('common:clear')}>
                      <>
                        <Button
                          variant="outlinedButton"
                          className="icon-fab"
                          onClick={() => removeFilterParameterById(filter.id)}
                          disabled={!isEditing}
                        >
                          <FilterAltOffOutlinedIcon />
                        </Button>
                      </>
                    </Tooltip>
                  </Box>

                  <Divider sx={{ margin: '1rem 0' }} />
                </Box>
              </Box>
            )
          })}
    </Box>
  )
}

export default CreateGuenoFilters

import { Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { useTranslation } from 'react-i18next'

import RiskMatrixEvaluatedData from './RiskMatrixEvaluatedData'
import RiskMatrixValueData from './RiskMatrixValueData'

const RiskMatrixResultGroup = ({
  country,
  groupByCategories = false,
  category,
  groupResults,
  score,
  list,
  combinator,
}) => {
  const { t } = useTranslation(['common', 'messages'])

  return (
    <Grid
      container
      rowSpacing={groupByCategories ? 2 : 1}
      columnSpacing={2}
      alignItems="center"
      sx={{ borderTop: '1px solid gray' }}
    >
      <Grid xs={1.5} rowSpacing={2}>
        <Typography>
          {category} ({combinator})
        </Typography>
      </Grid>

      <Grid container xs={9.5} flexDirection="column">
        {groupResults.map(({ attribute, dataToEvaluate, isHit, operator, operatorFound, value }) => {
          const key = `${category}-${attribute}-${operator}-${
            typeof value === 'string' ? value : JSON.stringify(value)
          }`
          const dataToEvaluateNotFound = dataToEvaluate === null || dataToEvaluate === undefined

          let highlights = []
          if (operator === 'any_includes_any' && Array.isArray(value)) {
            highlights = value
          } else if (operator === 'any_includes' && typeof value === 'string') {
            highlights = [value]
          }

          return (
            <Grid
              key={key}
              container
              rowSpacing={1}
              columnSpacing={2}
              sx={{ backgroundColor: dataToEvaluateNotFound ? 'lightpink' : 'unset' }}
              height={60}
              alignItems="center"
            >
              <Grid xs={3.8}>
                <Typography>{attribute && t(`messages:riskmatrix.attribute.${country}.${attribute}`)}</Typography>
              </Grid>
              <Grid xs={2.5}>
                {dataToEvaluateNotFound ? (
                  <Typography>{t(`messages:riskmatrix.errors.dataToEvaluateNotFound`)}</Typography>
                ) : (
                  <RiskMatrixEvaluatedData data={dataToEvaluate} highlights={highlights} />
                )}
              </Grid>
              <Grid xs={1.9}>
                <Typography>{operator && t(`messages:riskmatrix.operator.${operator}`)}</Typography>
              </Grid>
              <Grid xs={2.5}>
                <RiskMatrixValueData data={value} list={operator === 'in_custom_list' ? list : undefined} />
              </Grid>
              <Grid xs={1.3} textAlign="center">
                {!operatorFound ? (
                  <Typography>Error</Typography>
                ) : (
                  <Typography>{t(`common:${isHit ? 'yes' : 'no'}`)}</Typography>
                )}
              </Grid>
            </Grid>
          )
        })}
      </Grid>

      <Grid xs={1} rowSpacing={2} textAlign="center">
        <Typography>{score}</Typography>
      </Grid>
    </Grid>
  )
}

export default RiskMatrixResultGroup

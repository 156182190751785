import { useCallback } from 'react'
import 'react-querybuilder/dist/query-builder.css'
import { QueryBuilder } from 'react-querybuilder'

import { customOperators, riskMatrixAttributes } from '../constantsV2'
import CustomRule from './CustomRule'
import CustomGroup from './CustomGroup'

const RiskMatrixParameterV2 = ({ query, setQuery, country, target }) => {
  const CustomRuleWithCountry = useCallback((props) => <CustomRule {...props} country={country} />, [country])
  const CustomGroupWithCountry = useCallback((props) => <CustomGroup {...props} country={country} />, [country])

  return (
    <QueryBuilder
      fields={riskMatrixAttributes[target][country]}
      query={query}
      onQueryChange={setQuery}
      operators={customOperators}
      controlElements={{
        rule: CustomRuleWithCountry,
        ruleGroup: CustomGroupWithCountry,
      }}
    />
  )
}

export default RiskMatrixParameterV2

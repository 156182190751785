import { Box } from '@mui/material'
import AlertsPage from './alerts/AlertsPage'

const CaseManagement = () => {
  return (
    <Box>
      <AlertsPage />
    </Box>
  )
}

export default CaseManagement

import {
  Box,
  Button,
  Chip,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  Modal,
  Paper,
  Radio,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import './rulesDrawer.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { updateCreation } from '../../../../features/rules/rulesSlice'
import CreateRuleEditParameters2 from '../CreateRuleEditParameters2/CreateRuleEditParameters2'
import CreateFilters from '../../filters/CreateFilters'
import CreateRulePreview from '../createRulePreview/createRulePreview'
import AlertFetchSpinner from '../../../common/alertFetchSpinner/alertFetchSpinner'
import Spinner from '../../../common/spinner/spinner'
import CreateGuenoRuleEditParameters from '../CreateRuleEditParameters2/CreateGuenoRuleEditParameters'
import { camelCaseFormatter } from '../../../utilities/formatters/camelCase'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import CreateGuenoFilters from '../../filters/CreateGuenoFilters'
import ListOfCheckIds from '../ruleParameters2/listOfCheckIds/ListOfCheckIds'
import { useChecklist } from '../../../../pages/pages-hooks/useChecklist'
import { useTranslation } from 'react-i18next'
import RulesAdvanceSettings from '../rulesAdvancedSettings/ruleAdvancedSettings'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import CloseIcon from '@mui/icons-material/Close'
import { useWorkflows } from '../../../../pages/pages-hooks/useWorkflows'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { TableChip } from '../../../utilities/TableChip'
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined'

export const RulesDrawer = ({
  selectedRule,
  setSelectedRule,
  open,
  setOpen,
  myRule,
  ruleToEdit,
  ruleList,
  setInstanceRules,
  verifyEdition,
  isLoading,
  setIsLoading,
  isCopy,
  setIsCopy,
  handleCopy,
}) => {
  const { activeServices, accessToken, emailClient } = useSelector((state) => state.login)
  useChecklist(emailClient, null)
  const dispatch = useDispatch()
  const checkListsState = useSelector((state) => state.checklists?.checklists?.data)
  const [ruleParameters, setRuleParameters] = useState({})
  const [filterParameters, setFilterParameters] = useState({})
  const [filterGuenoParameters, setFilterGuenoParameters] = useState([])
  const [error, setError] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [ruleSuccess, setRuleSuccess] = useState(false)
  const [errorList, setErrorList] = useState({})
  const [editedName, setEditedName] = useState('')
  const [editedDescription, setEditedDescription] = useState('')
  const [selectedAction, setSelectedAction] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [fetchError, setFechError] = useState(false)
  const [fetched, setFetched] = useState(false)
  const [message, setMessage] = useState('')
  const { t } = useTranslation(['common'])
  const [selectedTab, setSelectedTab] = useState('basicDetails')
  const [isUniqueAlert, setIsUniqueAlert] = useState(selectedRule.uniqueAlert || false)
  const [isProcessing, setIsProcessing] = useState(true)
  const [workflowConsumerId, setWorkflowConsumerId] = useState(selectedRule.workflowConsumerId || '')
  const [workflowBusinessId, setWorkflowBusinessId] = useState(selectedRule.workflowBusinessId || '')
  const workflowsList = useWorkflows(setIsProcessing, isProcessing)
  const [checklist, setChecklist] = useState({})
  const [isShadow, setIsShadow] = useState(selectedRule.isShadow || false)
  const [shadowModalOpen, setShadowModalOpen] = useState(false)
  const [edit, setEdit] = useState(false)

  const tabList = ['basicDetails', 'customParameters', 'selectFilters', 'checklist', 'advancedSettings', 'preview']

  const [ras, setRas] = useState({
    instanceId: '',
    usersToUpdate: '',
    userStatusUpdate: {
      updateTo: '',
      reason: '',
      description: '',
    },
    userKYCStatusUpdate: {
      updateTo: '',
      reason: '',
      description: '',
    },
    txStateUpdate: {
      updateTo: '',
      description: '',
    },
    updated: false,
  })

  const isChecklistActive = activeServices?.find((path) => path.includes('checklist'))
  const canCreate = activeServices?.find((path) => path.includes('createClientInstanceRule'))
  const isRasOK =
    (!ras?.userStatusUpdate && !ras?.userKYCStatusUpdate) ||
    (!ras?.usersToUpdate &&
      !Object.values(ras?.userStatusUpdate).some((usu) => usu !== '') &&
      !Object.values(ras?.userKYCStatusUpdate).some((ukycu) => ukycu !== '')) ||
    (ras?.usersToUpdate && (ras?.userStatusUpdate?.updateTo || ras?.userKYCStatusUpdate?.updateTo))

  const handleClose = () => {
    setOpen(false)
    if (setIsCopy) setIsCopy(false)
    setSelectedTab('basicDetails')
    setError(false)
    setEditedName('')
    setEditedDescription('')
    setSelectedAction('')
    setFilterParameters({})
    setFilterGuenoParameters([])
    setRuleParameters({})
    setErrorList({})
    setSelectedRule('')
    setRuleSuccess(false)
    setFechError(false)
    setFetched(false)
    setMessage('')
    setIsLoading(false)
    setIsFetching(false)
    setChecklist({})
    setWorkflowConsumerId('')
    setWorkflowBusinessId('')
    setIsEditing(false)
    setEdit(false)
  }

  useEffect(() => {
    if (selectedRule?.id) {
      const url = `${process.env.REACT_APP_BASEURL}/ras/${selectedRule.id}`
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
      fetch(process.env.REACT_APP_IS_DEMO ? '../data/ras.json' : url, options)
        .then((res) => res.json())
        .then((res) => {
          const data = res.data?.length > 0 ? res.data[0] : null
          if (data) {
            const rasVariable = {
              ...ras,
              instanceId: selectedRule?.id,
              usersToUpdate: data.usersToUpdate,
            }

            if (data?.userStatusInfo) {
              delete data?.userStatusInfo._id
              rasVariable.userStatusUpdate = { ...rasVariable.userStatusUpdate, ...data?.userStatusInfo }
            }
            if (data?.kycStatusInfo) {
              delete data?.kycStatusInfo._id
              rasVariable.userKYCStatusUpdate = { ...rasVariable.userKYCStatusUpdate, ...data?.kycStatusInfo }
            }
            if (!data?.userStatusInfo && !data?.kycStatusInfo) delete rasVariable.usersToUpdate
            if (data?.transactionStateInfo) {
              delete data?.transactionStateInfo._id
              rasVariable.txStateUpdate = { ...rasVariable.txStateUpdate, ...data?.transactionStateInfo }
            }
            setRas(rasVariable)
          }
        })
        .catch((e) => console.error(e))
    }

    if (selectedRule?.checklistTemplateId) {
      let chTemplate = checkListsState.filter((cl) => cl.id === selectedRule?.checklistTemplateId)
      chTemplate = chTemplate && chTemplate?.length > 0 ? chTemplate[0] : {}
      setChecklist(chTemplate)
    }
  }, [ruleToEdit, open])

  const handleTabChange = (direction) => {
    const updatedTabList = isChecklistActive ? tabList : tabList.filter((t) => t !== 'checklist')
    const tabIdx = updatedTabList.findIndex((tab) => tab === selectedTab)
    const newSelectedTab = updatedTabList[tabIdx + direction]

    setSelectedTab(newSelectedTab)
  }

  const handleCreateRule = () => {
    setIsLoading(true)
    setFetched(false)
    setIsFetching(true)
    setIsEditing(false)
    setShadowModalOpen(false)
    setMessage(`Creating Rule ${selectedRule.id || selectedRule.ruleId}.`)

    let url
    let data = {}

    if (!process.env.REACT_APP_IS_DEMO) {
      if (selectedRule.gueno) {
        url = `${process.env.REACT_APP_BASEURL}/rules`
        data = {
          emailClient: emailClient,
          ruleId: selectedRule.ruleId || selectedRule.id,
          ruleTemplate: selectedRule.ruleTemplate || selectedRule.templateId,
          name: editedName,
          description: editedDescription || selectedRule.ruleDescriptionAlias,
          parameters: ruleParameters,
          filters: filterGuenoParameters,
          status: 'ACTIVE',
          action: selectedAction,
          uniqueAlert: isUniqueAlert,
          isShadow: isShadow,
          ...(workflowConsumerId !== '' && { workflowConsumerId }),
          ...(workflowBusinessId !== '' && { workflowBusinessId }),
        }
        if (isRasOK && (ras?.usersToUpdate || ras?.txStateUpdate?.updateTo)) {
          let rasData = {
            emailClient: emailClient,
            ruleId: selectedRule.id,
            instanceId: null,
            // const usersToUpdate = ras?.usersToUpdate ? ras.usersToUpdate : 'ALL',
            usersToUpdate: ras.usersToUpdate === '' ? 'ALL' : ras.usersToUpdate,
            status: 'ACTIVE',
          }

          data = { ...data, ras: rasData }

          const keys = Object.keys(ras?.userStatusUpdate ?? {})

          if (ras?.userStatusUpdate?.updateTo) {
            rasData.userStatusInfo = { updateTo: ras.userStatusUpdate.updateTo }
            keys.forEach((key) => {
              if (ras.userStatusUpdate[key] && key !== 'updateTo') {
                rasData.userStatusInfo[key] = ras.userStatusUpdate[key]
              }
            })
          }
          if (ras?.userKYCStatusUpdate?.updateTo) {
            rasData.kycStatusInfo = { updateTo: ras.userKYCStatusUpdate.updateTo }
            keys.forEach((key) => {
              if (ras.userKYCStatusUpdate[key] && key !== 'updateTo') {
                rasData.kycStatusInfo[key] = ras.userKYCStatusUpdate[key]
              }
            })
          }
          if (ras?.txStateUpdate?.updateTo) {
            rasData.transactionStateInfo = { updateTo: ras?.txStateUpdate?.updateTo }
            if (ras?.txStateUpdate?.description) {
              rasData.transactionStateInfo.description = ras?.txStateUpdate?.description
            }
          }
        }
        if (checklist?.id) data.checklistTemplateId = checklist.id
      } else {
        url = `${process.env.REACT_APP_BASEURL}/kyt/createClientInstanceRule`
        data = {
          ruleId: selectedRule.id,
          parameters: ruleParameters,
          filters: filterParameters,
          ruleNameAlias: editedName,
          description: ruleToEdit.description,
          ruleDescriptionAlias: editedDescription,
          action: selectedAction,
          uniqueAlert: isUniqueAlert,
          isShadow: isShadow,
          ...(workflowConsumerId !== '' && { workflowConsumerId }),
          ...(workflowBusinessId !== '' && { workflowBusinessId }),
          status: 'ACTIVE',
          caseCreationType: selectedRule.defaultCaseCreationType,
          casePriority: selectedRule.defaultCasePriority,
        }
        if (selectedRule.type) data['type'] = selectedRule.type // acá habría que ver que onda flagright
        if (checklist?.id) data.checklistTemplateId = checklist.id
      }

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res?.success && !res?.isCPO) throw Error(res?.message)

          setIsFetching(false)
          setFetched(true)
          setFechError(false)
          setMessage(res.message)
          dispatch(updateCreation(true))
          setIsLoading(false)
          setRuleSuccess(true)

          if (!res?.isCPO) {
            const updatedRuleList = [...ruleList, { ...res?.data, gueno: selectedRule.gueno ? true : false }]
            setInstanceRules(updatedRuleList)
          }

          setTimeout(() => {
            setFetched(false)
          }, 3000)
        })
        .catch((error) => {
          console.error('[RULE CREATION ERROR] --> ', error)
          setIsFetching(false)
          setFetched(true)
          setFechError(true)
          setIsLoading(false)
          setMessage(`Create rule error.`)
          setTimeout(() => {
            setFetched(false)
          }, 3000)
        })
    } else {
      setIsFetching(false)
      setFetched(true)
      setFechError(false)
      setMessage('Rule creation sucess')
      dispatch(updateCreation(true))
      setIsLoading(false)
      setRuleSuccess(true)
    }
  }

  const handleUpdateRule = () => {
    setIsLoading(true)
    setFetched(false)
    setIsFetching(true)
    setIsEditing(false)
    setShadowModalOpen(false)
    setMessage(`Editing Rule ${selectedRule.id}.`)

    let url
    let data = {}
    let method

    if (selectedRule.gueno) {
      url = `${process.env.REACT_APP_BASEURL}/rules/${selectedRule.id}`
      data = {
        name: editedName,
        description: editedDescription,
        parameters: ruleParameters,
        filters: filterGuenoParameters,
        action: selectedAction,
        uniqueAlert: isUniqueAlert,
        isShadow: isShadow,
        ...(workflowConsumerId !== '' && { workflowConsumerId }),
        ...(workflowBusinessId !== '' && { workflowBusinessId }),
      }
      if (checklist?.id) data.checklistTemplateId = checklist.id
      method = 'PATCH'
    } else {
      url = `${process.env.REACT_APP_BASEURL}/kyt/updateClientInstanceRule/${selectedRule.id}`
      data = {
        parameters: ruleParameters,
        filters: filterParameters,
        ruleNameAlias: editedName,
        ruleDescriptionAlias: editedDescription,
        action: selectedAction,
        uniqueAlert: isUniqueAlert,
        ...(workflowConsumerId !== '' && { workflowConsumerId }),
        ...(workflowBusinessId !== '' && { workflowBusinessId }),
      }
      if (checklist?.id) data.checklistTemplateId = checklist.id
      method = 'POST'
    }

    if (!process.env.REACT_APP_IS_DEMO) {
      const options = {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (!res?.success && !res.isCPO) throw Error(res?.message)
          let listAux = [...ruleList]
          let idx = listAux.findIndex((element) => {
            return element.id === selectedRule.id.toString()
          })

          if (idx !== -1 && !res.isCPO) {
            const updatedRuleList = [
              ...listAux.slice(0, idx),
              {
                ...data,
                parameters: ruleParameters,
                id: selectedRule.id,
                ruleTemplate: selectedRule.ruleTemplate,
                ruleId: selectedRule.ruleId,
                status: res.data.status,
                hitCount: res.data.hitCount,
                runCount: res.data.runCount,
                gueno: selectedRule.gueno,
                uniqueAlert: res.data.isUniqueAlert,
                workflowConsumerId: res.data.workflowConsumerId,
                workflowBusinessId: res.data.workflowBusinessId,
                isShadow: res.data.isShadow,
              },
              ...listAux.slice(idx + 1),
            ]
            setInstanceRules(updatedRuleList)
          }

          if (ras?.updated) {
            let rasRequestData = {}
            const keys = ras.userStatusUpdate ? Object.keys(ras.userStatusUpdate) : []

            if (ras?.userStatusUpdate?.updateTo) {
              rasRequestData.usersToUpdate = ras?.usersToUpdate
              rasRequestData.userStatusInfo = { updateTo: ras.userStatusUpdate.updateTo }
              keys.forEach((key) => {
                if (ras.userStatusUpdate[key] && key !== 'updateTo' && key !== '_id') {
                  rasRequestData.userStatusInfo[key] = ras.userStatusUpdate[key]
                }
              })
            }
            if (ras?.userKYCStatusUpdate?.updateTo) {
              rasRequestData.usersToUpdate = ras?.usersToUpdate
              rasRequestData.kycStatusInfo = { updateTo: ras.userKYCStatusUpdate.updateTo }
              keys.forEach((key) => {
                if (ras.userKYCStatusUpdate[key] && key !== 'updateTo' && key !== '_id') {
                  rasRequestData.kycStatusInfo[key] = ras.userKYCStatusUpdate[key]
                }
              })
            }

            if (ras?.txStateUpdate?.updateTo) {
              rasRequestData.transactionStateInfo = { updateTo: ras?.txStateUpdate?.updateTo }
              if (ras?.txStateUpdate?.description) {
                rasRequestData.transactionStateInfo.description = ras?.txStateUpdate?.description
              }
            }

            url = `${process.env.REACT_APP_BASEURL}/ras/${selectedRule.id}`

            options.method = 'PATCH'
            options.body = JSON.stringify({ ruleId: selectedRule.ruleId, ...rasRequestData })

            fetch(`${process.env.REACT_APP_BASEURL}/ras/${selectedRule.id}`, options)
              .then((res) => res.json())
              .catch(() => '[UPDATE RAS FETCH ERROR] => ', error)
          }

          setIsFetching(false)
          setFetched(true)
          setFechError(false)
          setMessage(res.message)
          dispatch(updateCreation(true))
          setIsLoading(false)
          handleClose()

          setTimeout(() => {
            setFetched(false)
          }, 3000)
        })
        .catch((error) => {
          console.error('[UPDATE RULE FETCH ERROR] => ', error)
          setIsFetching(false)
          setFetched(true)
          setFechError(true)
          setIsLoading(false)
          setMessage(`Update rule error.`)
          setTimeout(() => {
            setFetched(false)
          }, 3000)
        })
    } else {
      let listAux = [...ruleList]
      let idx = listAux.findIndex((element) => {
        return element.id === selectedRule.id.toString()
      })

      const updatedRuleList = [
        ...listAux.slice(0, idx),
        {
          ...data,
          parameters: ruleParameters,
          id: selectedRule.id,
          ruleTemplate: selectedRule.ruleTemplate,
          ruleId: selectedRule.ruleId,
          status: selectedRule.status,
          hitCount: selectedRule.hitCount,
          runCount: selectedRule.runCount,
          gueno: selectedRule.gueno,
          uniqueAlert: selectedRule.isUniqueAlert,
          workflowConsumerId: selectedRule.workflowConsumerId,
          workflowBusinessId: selectedRule.workflowBusinessId,
          isShadow: selectedRule.isShadow,
        },
        ...listAux.slice(idx + 1),
      ]
      setInstanceRules(updatedRuleList)
      setIsFetching(false)
      setFetched(true)
      setFechError(false)
      setMessage('Rule update success')
      dispatch(updateCreation(true))
      setIsLoading(false)

      setTimeout(() => {
        setFetched(false)
      }, 3000)
    }
  }

  useEffect(() => {
    if (selectedTab !== 'customParameters') {
      setError(false)
      setErrorList({})
    }
  }, [selectedTab])

  useEffect(() => {
    setEditedName(
      myRule && !isCopy && selectedRule.ruleNameAlias
        ? selectedRule.ruleNameAlias
        : myRule && isCopy && selectedRule.ruleNameAlias
        ? `${selectedRule.ruleNameAlias} - copy of Rule ID: ${selectedRule?.id} `
        : selectedRule.name,
    )
    setEditedDescription(selectedRule?.ruleDescriptionAlias || ruleToEdit?.description)
    setSelectedAction(myRule ? selectedRule.action : selectedRule.defaultAction)

    if (myRule) {
      if (selectedRule?.uniqueAlert !== undefined) {
        setIsUniqueAlert(selectedRule.uniqueAlert)
      } else {
        setIsUniqueAlert(false)
      }

      if (selectedRule?.isShadow !== undefined) {
        setIsShadow(selectedRule.isShadow)
      }

      if (selectedRule?.workflowConsumerId) {
        setWorkflowConsumerId(selectedRule.workflowConsumerId)
      }

      if (selectedRule?.workflowBusinessId) {
        setWorkflowBusinessId(selectedRule.workflowBusinessId)
      }

      if (selectedRule.parameters) {
        setRuleParameters((prevParams) => ({
          ...prevParams,
          ...selectedRule.parameters,
        }))
      }

      if (selectedRule.filters) {
        if (!selectedRule?.gueno) {
          setFilterParameters((prevFilters) => ({
            ...prevFilters,
            ...selectedRule.filters,
          }))
        } else {
          setFilterGuenoParameters(selectedRule.filters)
        }
      }
    } else if (selectedRule.defaultParameters) {
      setRuleParameters((prevParams) => ({
        ...prevParams,
        ...selectedRule.defaultParameters,
      }))
    }

    setRas({
      instanceId: '',
      usersToUpdate: '',
      userStatusUpdate: {
        updateTo: '',
        reason: '',
        description: '',
      },
      userKYCStatusUpdate: {
        updateTo: '',
        reason: '',
        description: '',
      },
      txStateUpdate: {
        updateTo: '',
        description: '',
      },
    })
  }, [selectedRule, ruleToEdit, isCopy])

  useEffect(() => {
    let errorListAux = []
    if (selectedRule && selectedRule.parametersSchema?.required && selectedTab !== 'basicDetails') {
      selectedRule.parametersSchema.required.forEach((prop) => {
        if (!ruleParameters[prop]) errorListAux.push(`Property ${camelCaseFormatter(prop)} is required.`)
      })
    } else if (
      selectedRule?.parametersSchema?.properties &&
      Object.values(selectedRule.parametersSchema?.properties)?.find((item) => item.nullable === false)
    ) {
      Object.values(selectedRule?.parametersSchema?.properties)?.forEach((prop) => {
        if (!prop.nullable && !ruleParameters[prop.key]) {
          errorListAux.push(`Property ${camelCaseFormatter(prop.key)} is required.`)
        }
      })
    }
    if (errorListAux.length !== 0) {
      let all = errorList
      all['required'] = errorListAux
      setError(true)
      const newList = { ...all }
      setErrorList(() => newList)
    } else {
      let all = errorList
      const newList = { ...all }
      delete all['required']
      setErrorList(() => newList)

      if (Object.keys(all).length === 0) setError(false)
    }
  }, [ruleParameters, selectedRule])

  useEffect(() => {
    if (myRule && !isCopy) {
      setIsEditing(false)
    } else {
      setIsEditing(true)
    }
  }, [isCopy, open])

  return (
    <>
      <Modal keepMounted open={open} onClose={() => handleClose()}>
        <Paper elevation={0} className="modal-drawer">
          <TabContext value={selectedTab}>
            <Paper elevation={0} className="rule-drawer-sidebar" sx={{ padding: '1rem' }} variant="colorPrimary">
              <Typography variant="title">{t('common:sections')}</Typography>

              <TabList
                TabIndicatorProps={{
                  style: { display: 'none' },
                }}
                className="rule-drawer-tablist"
                onChange={(e, newValue) => setSelectedTab(newValue)}
              >
                <Tab
                  label={
                    <Chip
                      //icon={<PersonIcon />}
                      label={t('common:basicDetails')}
                      variant={selectedTab === 'basicDetails' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                    />
                  }
                  value="basicDetails"
                ></Tab>
                <Tab
                  label={
                    <Chip
                      label={t('common:customParameters')}
                      variant={selectedTab === 'customParameters' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                    />
                  }
                  value="customParameters"
                />
                <Tab
                  label={
                    <Chip
                      label={t('common:selectFilters')}
                      variant={selectedTab === 'selectFilters' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                    />
                  }
                  value="selectFilters"
                />
                {activeServices?.find((path) => path.includes('checklist')) && (
                  <Tab
                    label={
                      <Chip
                        label={t('common:checklist')}
                        variant={selectedTab === 'checklist' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                      />
                    }
                    value="checklist"
                  />
                )}
                <Tab
                  label={
                    <Chip
                      label={t('common:advancedSettings')}
                      variant={selectedTab === 'advancedSettings' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                    />
                  }
                  value="advancedSettings"
                />
                <Tab
                  label={
                    <Chip
                      label={t('common:preview')}
                      variant={selectedTab === 'preview' ? 'sliderSelectedPurple' : 'sliderTransparent'}
                    />
                  }
                  value="preview"
                />
              </TabList>
              {myRule && selectedRule.isShadow && !isCopy && (
                <Box className="drawer-hits">
                  <Divider />
                  <Button
                    endIcon={<OpenInNewIcon />}
                    onClick={() => window.open(`/transactions/?shadowRuleId=${selectedRule.id}`)}
                  >
                    <Typography>{t('common:transactionHits')}</Typography>
                  </Button>
                </Box>
              )}
            </Paper>
            <Box className="rule-drawer-top">
              <Typography variant="title">{t(`common:${selectedTab}`)}</Typography>

              <Box className="title-top">
                {selectedTab === 'selectFilters' && (
                  <Tooltip title={t('common:clearAll')}>
                    <>
                      <Button
                        variant="outlinedGrey"
                        className="icon-fab"
                        onClick={() => setFilterGuenoParameters([])}
                        disabled={!isEditing}
                        endIcon={<FilterAltOffOutlinedIcon />}
                      >
                        {t('common:clearAll')}
                      </Button>
                    </>
                  </Tooltip>
                )}
                {myRule && selectedRule.status !== 'ARCHIVED' && !isCopy && (
                  <Tooltip title={t('common:edit')}>
                    <Fab
                      className="icon-fab"
                      disabled={isFetching || isLoading || !verifyEdition}
                      onClick={() => {
                        setIsEditing(!isEditing)
                        setEdit(!edit)
                      }}
                      variant={isEditing ? 'select' : 'close'}
                    >
                      <EditOutlinedIcon />
                    </Fab>
                  </Tooltip>
                )}
                {myRule && !isCopy && !edit && (
                  <>
                    <Tooltip title={t('common:duplicate')}>
                      <Fab
                        className="icon-fab"
                        disabled={isFetching || isLoading || !verifyEdition}
                        onClick={() => {
                          setSelectedTab('basicDetails')
                          handleCopy(selectedRule?.id)
                        }}
                        variant="close"
                      >
                        <ContentCopyOutlinedIcon />
                      </Fab>
                    </Tooltip>
                    <Divider orientation="vertical" flexItem className="title-top-divider" />
                  </>
                )}
                <Fab variant="close" className="icon-fab" onClick={() => handleClose()}>
                  <CloseIcon />
                </Fab>
              </Box>
            </Box>
            <Box className="rule-drawer-container">
              {isLoading && <Spinner />}
              {isFetching && <AlertFetchSpinner message={message} spinner={true} />}
              {fetched && <AlertFetchSpinner message={message} error={fetchError} />}
              {!isLoading && (
                <Box className="rule-drawer-section-box">
                  <Box className="basic-details">
                    <Paper elevation={0} variant="rootOutlined" className="rule-drawer-section-box">
                      <Typography variant="title3">{t('common:ruleId')}</Typography>
                      <Typography variant="title">{myRule ? selectedRule.ruleId : selectedRule.id}</Typography>
                    </Paper>
                    <Paper elevation={0} variant="rootOutlined" className="rule-drawer-section-box">
                      <Typography variant="title3">{t('common:ruleInstanceId')}</Typography>
                      <Typography variant="title">{selectedRule.templateId ?? selectedRule.id}</Typography>
                    </Paper>
                  </Box>

                  <TabPanel value="basicDetails" className="rule-drawer-section-box">
                    <Paper elevation={0} variant="rootOutlined" className="rule-drawer-sections">
                      <Typography variant="title">{t('common:ruleDetails')}</Typography>

                      <Box className="rule-drawer-section-box">
                        <Typography variant="title3">{t('common:ruleName')}</Typography>
                        <TextField
                          id="name"
                          type="text"
                          disabled={!isEditing}
                          variant="outlined"
                          value={editedName || ''}
                          onChange={(event) => {
                            event.preventDefault()
                            setEditedName(event.target.value)
                          }}
                        />
                        <TextField
                          multiline
                          id="description"
                          disabled={!isEditing}
                          variant="outlined"
                          type="text"
                          value={editedDescription || ''}
                          onChange={(event) => {
                            event.preventDefault()
                            setEditedDescription(event.target.value)
                          }}
                        />
                      </Box>
                      {selectedRule?.nature && (
                        <Box className="rule-drawer-section-box">
                          <Typography variant="title3">{t('common:nature')}</Typography>
                          <TextField
                            id="name"
                            type="text"
                            disabled
                            variant="outlined"
                            value={selectedRule?.nature || ''}
                            onChange={(event) => {
                              event.preventDefault()
                              setEditedName(event.target.value)
                            }}
                          />
                        </Box>
                      )}
                    </Paper>
                  </TabPanel>

                  <TabPanel value="customParameters" className="rule-drawer-section-box">
                    <Paper elevation={0} variant="rootOutlined" className="rule-drawer-sections">
                      <Typography variant="title">{t('common:ruleParameters')}</Typography>

                      <Box className="rule-drawer-section-box">
                        {error && (
                          <Box>
                            {errorList &&
                              Object.keys(errorList).map((errorDetail, index) => (
                                <Box className="error-drawer" key={index}>
                                  <strong>{errorDetail && camelCaseFormatter(errorDetail)}: </strong>
                                  {Array.isArray(errorList[errorDetail]) &&
                                    errorList[errorDetail].map((item, index) => (
                                      <Box key={index}>
                                        <span>- {item}</span>
                                      </Box>
                                    ))}
                                </Box>
                              ))}
                          </Box>
                        )}
                        {selectedRule.gueno ? (
                          <CreateGuenoRuleEditParameters
                            rule={myRule ? ruleToEdit : selectedRule}
                            ruleParameters={ruleParameters}
                            setRuleParameters={setRuleParameters}
                            selectedAction={selectedAction}
                            setSelectedAction={setSelectedAction}
                            isUniqueAlert={isUniqueAlert}
                            setIsUniqueAlert={setIsUniqueAlert}
                            setWorkflowBusinessId={setWorkflowBusinessId}
                            setWorkflowConsumerId={setWorkflowConsumerId}
                            workflowBusinessId={workflowBusinessId}
                            workflowConsumerId={workflowConsumerId}
                            workflowsList={workflowsList}
                            setError={setError}
                            errorList={errorList}
                            setErrorList={setErrorList}
                            isEditing={isEditing}
                          />
                        ) : (
                          <CreateRuleEditParameters2
                            rule={myRule ? ruleToEdit : selectedRule}
                            ruleParameters={ruleParameters}
                            setRuleParameters={setRuleParameters}
                            selectedAction={selectedAction}
                            setSelectedAction={setSelectedAction}
                            setError={setError}
                            errorList={errorList}
                            setErrorList={setErrorList}
                            isEditing={isEditing}
                          />
                        )}
                      </Box>
                    </Paper>
                  </TabPanel>

                  <TabPanel value="selectFilters">
                    <Paper elevation={0} variant="rootOutlined" className="rule-drawer-sections">
                      <Typography variant="title">{t('common:userFilters')}</Typography>

                      <Box className="rule-drawer-section-box">
                        {selectedRule.gueno ? (
                          <CreateGuenoFilters
                            open={open}
                            isEditing={isEditing}
                            filterParameters={filterGuenoParameters}
                            setFilterParameters={setFilterGuenoParameters}
                            setError={setError}
                            errorList={errorList}
                            setErrorList={setErrorList}
                            ruleId={selectedRule.ruleId || selectedRule.id}
                          />
                        ) : (
                          <CreateFilters
                            open={open}
                            isEditing={isEditing}
                            filterParameters={filterParameters}
                            setFilterParameters={setFilterParameters}
                            setError={setError}
                            errorList={errorList}
                            setErrorList={setErrorList}
                            ruleId={selectedRule.ruleId || selectedRule.id}
                          />
                        )}
                      </Box>
                    </Paper>
                  </TabPanel>

                  <TabPanel value="checklist">
                    <Paper elevation={0} variant="rootOutlined" className="rule-drawer-sections">
                      <Typography variant="title">{t('common:checklists')}</Typography>
                      <Box className="rule-drawer-section-box">
                        <ListOfCheckIds
                          checklist={checklist}
                          setChecklist={setChecklist}
                          isEditing={isEditing}
                          myRule={myRule}
                        />
                      </Box>
                    </Paper>
                  </TabPanel>

                  <TabPanel value="advancedSettings">
                    <Paper elevation={0} variant="rootOutlined" className="rule-drawer-sections">
                      <Box className="rule-drawer-section-box">
                        <Typography variant="title">{t('common:advancedSettings')}</Typography>
                        <RulesAdvanceSettings isEditing={isEditing} ras={ras} setRas={setRas} />
                      </Box>
                    </Paper>
                  </TabPanel>
                  {!ruleSuccess && (
                    <TabPanel value="preview">
                      <Box className="rule-drawer-section-box">
                        <Paper elevation={0} variant="rootOutlined" className="rule-drawer-sections">
                          <Box className="rule-drawer-section-box">
                            <Typography variant="title">{t('common:ruleName')}</Typography>
                            <Typography variant="title3">{editedName}</Typography>
                          </Box>
                          <Box className="drawer-block">
                            <Typography variant="title">{t('common:ruleDescription')}</Typography>
                            <Typography variant="title3">{editedDescription}</Typography>
                          </Box>
                        </Paper>

                        <CreateRulePreview
                          ruleParameters={ruleParameters}
                          filters={!selectedRule.gueno ? filterParameters : filterGuenoParameters}
                          selectedAction={selectedAction}
                          isUniqueAlert={isUniqueAlert}
                          workflowConsumerId={workflowConsumerId}
                          workflowBusinessId={workflowBusinessId}
                          ras={ras}
                          checklist={
                            isChecklistActive
                              ? { ...checklist, isChecklistOk: isChecklistActive }
                              : { isChecklistOk: isChecklistActive }
                          }
                          isGueno={selectedRule.gueno}
                        />
                      </Box>
                    </TabPanel>
                  )}
                </Box>
              )}
            </Box>
            <Box className="drawer-buttons">
              <Button
                variant="outlinedGrey"
                onClick={() => handleTabChange(-1)}
                disabled={selectedTab === 'basicDetails' || isLoading}
              >
                {t('common:back')}
              </Button>

              <Button
                variant="contained"
                disabled={
                  (selectedTab !== 'basicDetails' && error && errorList.length !== 0) ||
                  (myRule && !isCopy && selectedTab === 'preview' && !isEditing) ||
                  !canCreate
                }
                onClick={selectedTab !== 'preview' ? () => handleTabChange(1) : () => setShadowModalOpen(true)}
              >
                {selectedTab !== 'preview'
                  ? t('common:next')
                  : myRule && !isCopy
                  ? t('common:updateRule')
                  : t('common:createRule')}
              </Button>
            </Box>
          </TabContext>
        </Paper>
      </Modal>
      <Modal open={shadowModalOpen} onClose={() => setShadowModalOpen(false)}>
        <Box className="modal" sx={{ maxWidth: '35rem' }}>
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:ruleActions')}</Typography>
            </Box>
            <Fab variant="close" onClick={() => setShadowModalOpen(false)}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="drawer-block">
            <FormControl>
              <Box className="actions-grid-2">
                <Paper
                  elevation={0}
                  variant="colorPrimary"
                  className="actions-flex"
                  sx={{ border: !isShadow ? '1px solid var(--info-dark)' : 'none' }}
                >
                  <FormControlLabel
                    disabled={!isEditing}
                    checked={!isShadow}
                    value="FLAG"
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: 'var(--info-dark)',
                          },
                        }}
                      />
                    }
                    label={<TableChip action="liveRule" noIcon={true} />}
                    onChange={() => setIsShadow(false)}
                    labelPlacement="start"
                  />
                  <Typography variant="subtitle4">{t('messages:liveRuleDesc')}</Typography>
                </Paper>

                <Paper
                  elevation={0}
                  variant="colorPrimary"
                  className="actions-flex"
                  sx={{ border: isShadow ? '1px solid var(--info-dark)' : 'none' }}
                >
                  <FormControlLabel
                    disabled={!isEditing}
                    checked={isShadow}
                    value="SUSPEND"
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: 'var(--info-dark)',
                          },
                        }}
                      />
                    }
                    label={<TableChip action="shadowRule" noIcon={true} />}
                    onChange={() => setIsShadow(true)}
                    labelPlacement="start"
                  />
                  <Typography variant="subtitle4">{t('messages:shadowRuleDesc')}</Typography>
                </Paper>
              </Box>
            </FormControl>
          </Box>
          <Box className="modal-filter-buttons">
            <Button variant="outlinedGrey" size="small" onClick={() => setShadowModalOpen(false)}>
              {t('common:cancel')}
            </Button>

            <Button variant="contained" size="small" onClick={myRule && !isCopy ? handleUpdateRule : handleCreateRule}>
              {t('common:confirm')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

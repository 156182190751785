import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import ModalTitle from '../../../components/common/Modal/ModalTitle'
import { AutocompleteInputForm } from '../../../components/common/form'
import { LoadingButton } from '@mui/lab'

const EditAlertForm = ({ setModalOpen, alertToUpdate, onSubmit, isLoading }) => {
  const { t } = useTranslation(['common', 'messages'])
  const alertStatusValuesMap = {
    PENDING: t('PENDING'),
    REVIEW_IN_PROGRESS: t('REVIEW_IN_PROGRESS'),
    REVIEW_FINISHED: t('REVIEW_FINISHED'),
    CLOSED_WITH_ACTIONS: t('CLOSED_WITH_ACTIONS'),
    CLOSED_WITHOUT_ACTIONS: t('CLOSED_WITHOUT_ACTIONS'),
    FALSE_POSITIVE: t('FALSE_POSITIVE'),
  }
  const { handleSubmit, control, formState } = useForm({
    mode: 'onTouched',
    defaultValues: {
      status: '',
    },
  })

  return (
    <Box className="modal">
      <ModalTitle setModalOpen={setModalOpen} title={t('common:updateAlert')} />
      <Box className="modal-box-filters">
        <Box className="modal-box">
          <Typography variant="subtitle3">{t('messages:doYouWantUpdate', { alert: alertToUpdate })}</Typography>
        </Box>
        <Box className="modal-box" sx={{ height: '180px' }}>
          <Box>
            <AutocompleteInputForm
              name="status"
              control={control}
              label={t('common:newStatus')}
              placeholder={`${t('common:select')} ${t('common:newStatus')}`}
              options={Object.keys(alertStatusValuesMap)}
              getOptionLabel={(option) => alertStatusValuesMap[option] || option}
              aria-label={t('common:newStatus')}
            />
          </Box>
          <Box className="modal-filter-buttons" sx={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
            <LoadingButton
              disabled={!formState.isDirty}
              variant="contained"
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
              aria-label={t('common:update')}
            >
              {t('common:update')}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default EditAlertForm

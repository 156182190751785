import { Box, Link, Paper, TablePagination, Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import { useGetKybNotificationsQuery, useRestartKybMutation } from '../../../../services/guenoApi'
import useUserTableConfig from '../../../../components/hooks/useUserTableConfig'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import {
  getOrderedColumns,
  getVisibilityModel,
  updateListOrder,
} from '../../../../components/common/datatable/tableUtils/tableUtils'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TableIcons } from '../../../../components/utilities/TableIcons'
import { RiskLevel } from '../../../../components/utilities/RiskLevel'
import { Link as ReactRouterLink } from 'react-router-dom'
import { toLocaleUTCDateString } from '../../../../components/common/time/timeHelper'
import { LoadingButton } from '@mui/lab'
import CustomColumnMenu from '../../../../components/common/datagrid/CustomColumnMenu'

export const KybOutdatedDatatable = ({
  page,
  isLoading,
  setIsLoading,
  setModalOpen,
  modalOpen,
  setActiveNotification,
  limit,
  offset,
  formattedDate,
}) => {
  const apiRefOutdated = useGridApiRef()
  const [pageOutDate, setPageOutDate] = useState(0)
  const { countrySegment } = useSelector((state) => state.kybCountry)
  const { t } = useTranslation(['common', 'messages'])

  const [restartKyb, { isLoading: restartIsLoading, originalArgs }] = useRestartKybMutation()

  const handleRestart = async (kybId) => {
    try {
      await restartKyb({ countrySegment, kybId })
    } catch (error) {
      console.error(error)
    }
  }

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('common:name'),
        sortable: false,
        flex: 3,
        renderCell: ({ row }) => (
          <Link
            component={ReactRouterLink}
            title={row.kyb?._id}
            to={`/kyb/${row?.countryCode ?? 'BR'}/detail/${row.kyb?._id}`}
          >
            {row.kyb?.companyDetails?.name}
          </Link>
        ),
      },
      {
        field: 'status',
        headerName: t('common:status'),
        sortable: false,
        flex: 1.5,
        renderCell: (params) => {
          return <TableIcons type={params.row.status.toUpperCase()} />
        },
      },
      {
        field: 'countryCode',
        headerName: t('common:country'),
        sortable: false,
        flex: 1,
        renderCell: (params) => {
          return params.row.countryCode
        },
      },
      {
        field: 'riskLevel',
        headerName: t('common:riskLevel'),
        sortable: false,
        flex: 1.5,
        renderCell: (params) => {
          return <Box>{params.row.riskLevel ? <RiskLevel type={params.row.riskLevel} /> : ''}</Box>
        },
      },
      {
        field: 'expirationDate',
        headerName: t('common:expirationDate'),
        flex: 1.5,
        renderCell: (params) => {
          const expirationDate = new Date(params.row.expirationDate)
          const today = new Date()

          const timeDifference = expirationDate.getTime() - today.getTime()

          const daysLeft = timeDifference / (1000 * 3600 * 24)

          const isExpiringSoon = daysLeft <= 7

          return (
            <Typography variant="number" style={{ color: isExpiringSoon ? 'red' : 'inherit' }}>
              {toLocaleUTCDateString(params.row.expirationDate)}
            </Typography>
          )
        },
      },
      {
        field: 'action',
        headerName: t('common:actions'),
        headerAlign: 'center',
        sortable: false,
        flex: 2,
        renderCell: ({ row }) => {
          return (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, alignItems: 'center' }}>
                <LoadingButton
                  variant="outlined"
                  onClick={() => {
                    setActiveNotification(row)
                    setModalOpen(!modalOpen)
                  }}
                >
                  {t('common:edit')}
                </LoadingButton>
                {row.status === 'PENDING' && (
                  <LoadingButton
                    loading={restartIsLoading && originalArgs?.kybId === row.kyb?._id}
                    onClick={() => handleRestart(row.kyb?._id)}
                    variant="outlined"
                  >
                    {t('common:reset')}
                  </LoadingButton>
                )}
              </Box>
            </Box>
          )
        },
      },
    ],
    [],
  )

  const {
    config,
    saveConfig,
    loading: configLoading,
  } = useUserTableConfig({
    tableKey: 'kybOutdatedNotifications',
    defaultConfig: {
      sortModel: [{ field: 'expirationDate', sort: 'desc' }],
      rowsPerPage: 25,
      pinnedColumns: { left: [], right: [] },
      columnOrder: columns.map((c) => c.field),
    },
  })

  const { field, sort } = useMemo(
    () => (config.sortModel?.length ? config.sortModel[0] : { field: '', sort: '' }),
    [config.sortModel],
  )

  const commonParams = {
    countrySegment,
    limit,
    offset,
    page,
    sortDirection: 1,
    sortField: 'expirationDate',
  }

  const paramsForOutDate = useMemo(
    () => ({
      status: 'PENDING',
      toDate: formattedDate,
      sortDirection: sort === 'desc' ? -1 : 1,
      sortField: field,
      page: pageOutDate,
      limit: config.rowsPerPage,
      offset: config.rowsPerPage * pageOutDate,
    }),
    [formattedDate, pageOutDate],
  )

  const { data: dataForOutDate, refetch } = useGetKybNotificationsQuery({
    ...commonParams,
    ...paramsForOutDate,
  })

  const orderedColumns = useMemo(
    () => getOrderedColumns(config.columnOrder, columns),
    [JSON.stringify(config.columnOrder), columns],
  )

  const handleColumnOrderChange = (params) => {
    const updatedOrder = updateListOrder(config.columnOrder, params)
    if (JSON.stringify(config.columnOrder) !== JSON.stringify(updatedOrder)) {
      saveConfig({ columnOrder: updatedOrder })
    }
  }

  const visibleColumnsModel = useMemo(
    () => getVisibilityModel(config.visibleColumns, columns),
    [config.visibleColumns, columns],
  )

  const handleChangePageOutDate = (event, newPage) => {
    setPageOutDate(newPage)
    setIsLoading(true)
  }

  const handleChangeRowsPerPageOutDate = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    saveConfig({ rowsPerPage: newRowsPerPage })
    setPageOutDate(0)
    setIsLoading(true)
  }

  return (
    <Box>
      <Paper elevation={0} variant="colorSecondary" className="kyb-detail">
        <Typography variant="title">{t('common:outdated')}</Typography>
        <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
          <DataGridPro
            apiRef={apiRefOutdated}
            getRowHeight={() => 'auto'}
            getRowId={(row) => row._id || row.id}
            rows={dataForOutDate?.data || []}
            columns={orderedColumns}
            slots={{
              columnMenu: CustomColumnMenu,
            }}
            hideFooter={true}
            autoHeight
            loading={isLoading || configLoading}
            sx={{
              height: 'calc(100vh - 30rem)',
              '& .MuiDataGrid-virtualScroller': {
                overflow: 'auto',
              },
            }}
            sortingMode="server"
            sortModel={config.sortModel}
            onSortModelChange={(newSortModel) => saveConfig({ sortModel: newSortModel })}
            columnOrder={config.columnOrder}
            onColumnOrderChange={handleColumnOrderChange}
            pinnedColumns={config.pinnedColumns}
            onPinnedColumnsChange={(newPinnedColumns) => saveConfig({ pinnedColumns: newPinnedColumns })}
            columnVisibilityModel={visibleColumnsModel}
            onColumnVisibilityModelChange={(newModel) => saveConfig({ visibleColumns: newModel })}
          />
          <TablePagination
            labelRowsPerPage={[]}
            component="div"
            count={dataForOutDate?.pagination?.totalElements || 0}
            page={pageOutDate}
            onPageChange={handleChangePageOutDate}
            rowsPerPage={config.rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPageOutDate}
          />
        </Box>
      </Paper>
    </Box>
  )
}

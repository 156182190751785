import { useEffect } from 'react'
import { Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useUpdateKybMutation } from '../../../services/guenoApi'
import TextInputForm from '../../../components/common/form/TextInputForm/TextInputForm'

const KybEditFieldModal = ({ name, initialValue, onSubmit, validation = '' }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [_, { isLoading }] = useUpdateKybMutation({ fixedCacheKey: 'kyb-update' })

  const validations = {
    date: yup
      .string()
      .matches(/^\d{2}\/\d{2}\/\d{4}$/, t('messages:kyb.dateOfRegistration.invalidDateFormat'))
      .test('valid-date', t('messages:kyb.dateOfRegistration.invalidDate'), (value) => {
        const [day, month, year] = value.split('/').map(Number)
        const date = new Date(year, month - 1, day)
        return date.getDate() === day && date.getMonth() === month - 1 && date.getFullYear() === year
      })
      .test('min-date', t('messages:kyb.dateOfRegistration.dateTooOld'), (value) => {
        const [day, month, year] = value.split('/').map(Number)
        const date = new Date(year, month - 1, day)
        const minDate = new Date(1850, 0, 1)
        return date >= minDate
      })
      .test('max-date', t('messages:kyb.dateOfRegistration.dateInFuture'), (value) => {
        const [day, month, year] = value.split('/').map(Number)
        const date = new Date(year, month - 1, day)
        const today = new Date()
        return date <= today
      })
      .required(t('common:required')),
  }

  const placeholders = {
    date: 'DD/MM/AAAA',
  }

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        value: validations[validation] || yup.string(),
      }),
    ),
    defaultValues: { value: initialValue },
  })

  useEffect(() => {
    setValue('value', initialValue, { shouldDirty: false })
  }, [initialValue, setValue])

  const handleFormSubmit = ({ value }) => {
    onSubmit(name, value)
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Box className="modal-box">
        <TextInputForm name="value" control={control} placeholder={placeholders[validation] || ''} />
      </Box>
      <Box className="modal-button">
        <LoadingButton
          size="small"
          color="secondary"
          variant="contained"
          disabled={!isDirty || !isValid}
          type="submit"
          loading={isLoading}
        >
          {t('common:save')}
        </LoadingButton>
      </Box>
    </form>
  )
}

export default KybEditFieldModal

import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckDetailTitle from './CheckDetailTitle'
import { useMemo } from 'react'

const variantByLevel = {
  0: 'h3',
  1: 'h4',
}

const CheckBlacklists = ({ title = '', check, level = 0 }) => {
  const { t } = useTranslation(['common', 'messages'])

  const lists = useMemo(() => {
    const documents = []

    if (check.checkDetails?.cnpjBlocklist) {
      documents.push({
        ...check.checkDetails?.cnpjBlocklist,
        type: 'CNPJ',
      })
    }

    if (Array.isArray(check?.checkDetails?.cpfBlocklist)) {
      check?.checkDetails?.cpfBlocklist?.forEach((item) =>
        documents.push({
          ...item,
          type: item.type || 'CPF',
        }),
      )
    }

    return documents
  }, [check.checkDetails])

  return (
    <Box className="detail-group">
      <Typography variant={variantByLevel[level] || variantByLevel[0]}>{title || t('common:blacklists')}</Typography>
      <CheckDetailTitle
        email={check.email}
        status={check.status}
        createdAt={check.createdAt}
        updatedAt={check.updatedAt}
      />
      {!!lists.length && (
        <Box>
          <Box className="group-table">
            <table className="bs bst group-table">
              <thead>
                <tr className="group-table">
                  <th className="bs">{t('common:type')}</th>
                  <th className="bs">{t('common:documentNumber')}</th>
                  <th className="bs">{t('common:name')}</th>
                  <th className="bs">{t('common:description')}</th>
                </tr>
              </thead>
              <tbody>
                {lists.map((element, index) => {
                  return (
                    <tr className="group-table" key={index}>
                      <td className="bs table-cell col-4">{element.type || '---'}</td>
                      <td className="bs table-cell col-4">{element.documentNumber || '---'}</td>
                      <td className="bs table-cell col-4">{element.name || '---'}</td>
                      <td className="bs table-cell col-4">{element.description || '---'}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default CheckBlacklists

import './home.scss'
import BarChart2 from '../../components/common/chart/BarChart2'
import Featured from '../../components/featured/Featured'
import Widget from '../../components/common/widget/Widget'
import { useAnnualTransactions } from '../pages-hooks/useTransactions'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Fab, Modal, Typography } from '@mui/material'
import { decodeToken } from 'react-jwt'
import { useState } from 'react'
import { acceptExpirationPasswordMessage } from '../../features/auth/loginSlice'
import { useTranslation } from 'react-i18next'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

const Home = () => {
  const { emailClient, accessToken, expirationMessageAccepted } = useSelector((state) => state.login)
  const transactions = useAnnualTransactions(emailClient)
  const decodedToken = decodeToken(accessToken)
  const [open, setOpen] = useState(!expirationMessageAccepted && decodedToken?.timeToExpirationPassword ? true : false)
  const dispatch = useDispatch()
  const { t } = useTranslation(['messages', 'common'])
  const handleClose = () => {
    setOpen(false)
    dispatch(acceptExpirationPasswordMessage(true))
  }

  return (
    <Box className="overview">
      <Box className="overview-top">
        <Widget type="transactions" inputData={transactions} />
        <Widget type="users" inputData={[]} />
        <Widget type="consumptions" inputData={transactions} />
      </Box>
      <Box className="overview-bottom">
        <Box className="featured">
          <Featured />
        </Box>
        <BarChart2 inputData={transactions} />
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box className="modal">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:attention')}!</Typography>
            </Box>
            <Fab variant="close" onClick={() => handleClose()}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Box className="modal-box">
            <Box className="modal-flex">
              <Typography>{t('messages:passwordExpire')}</Typography>
              <Typography color="primary">{Math.trunc(decodedToken?.timeToExpirationPassword)}</Typography>
              <Typography>{t('common:days')}.</Typography>
            </Box>
            <Box className="modal-filter-buttons">
              <Button variant="contained" onClick={handleClose}>
                {t('common:Ok')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default Home

import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AutocompleteInputForm, DateTimeInputForm, TextInputForm } from '../../../components/common/form'
import { FilterModalContainer } from '../../../components/utilities/FilterModalContainer'

const FiltersAlertsForm = ({ defaultValues = {}, setModalOpen, handleResetButton, handleDispatch, setPage }) => {
  const { t } = useTranslation(['common'])
  const alertStatusValuesMap = {
    PENDING: t('PENDING'),
    REVIEW_IN_PROGRESS: t('REVIEW_IN_PROGRESS'),
    REVIEW_FINISHED: t('REVIEW_FINISHED'),
    CLOSED_WITH_ACTIONS: t('CLOSED_WITH_ACTIONS'),
    CLOSED_WITHOUT_ACTIONS: t('CLOSED_WITHOUT_ACTIONS'),
    FALSE_POSITIVE: t('FALSE_POSITIVE'),
  }

  const typeValuesMap = {
    amlCrypto: t('amlCryptoAlerts'),
    aml: t('amlAlerts'),
    transactionRule: t('transactionAlerts'),
  }
  const { handleSubmit, reset, control, watch, setValue } = useForm({
    mode: 'onTouched',
    defaultValues,
  })

  const [fromDate, toDate] = watch(['fromDate', 'toDate'])

  useEffect(() => {
    if (fromDate && !toDate) {
      setValue('toDate', dayjs(fromDate).hour(23).minute(59).second(59))
    } else if (!fromDate && toDate) {
      setValue('fromDate', dayjs(fromDate).hour(0).minute(0).second(0))
    }
  }, [fromDate, toDate, setValue])

  const handleReset = () => {
    reset()
    handleResetButton()
    setModalOpen(false)
  }

  return (
    <FilterModalContainer
      setModalOpen={setModalOpen}
      handleResetButton={handleReset}
      setPage={setPage}
      handleDispatch={handleSubmit(handleDispatch)}
    >
      <Box className="modal-box-filters">
        <Box>
          <TextInputForm
            name="id"
            control={control}
            label={`${t('common:by')} ${t('common:alertId')}:`}
            placeholder={t('common:alertId')}
          />
        </Box>
        <Box className="modal-box">
          <AutocompleteInputForm
            name="status"
            control={control}
            label={t('common:status')}
            placeholder={`${t('common:select')} ${t('common:status')}`}
            options={Object.keys(alertStatusValuesMap)}
            getOptionLabel={(option) => alertStatusValuesMap[option] || option}
            aria-label={t('common:status')}
          />
        </Box>
        <Box className="modal-box">
          <AutocompleteInputForm
            name="type"
            control={control}
            label={t('common:type')}
            placeholder={`${t('common:select')} ${t('common:type')}`}
            options={Object.keys(typeValuesMap)}
            getOptionLabel={(option) => typeValuesMap[option] || option}
            aria-label={t('common:type')}
          />
        </Box>
        <Box>
          <DateTimeInputForm
            name="fromDate"
            timeName="fromTime"
            control={control}
            label={`${t('common:by')} ${t('common:initialDate')}:`}
          />
        </Box>
        <Box>
          <DateTimeInputForm
            name="toDate"
            timeName="toTime"
            control={control}
            label={`${t('common:by')} ${t('common:endDate')}:`}
          />
        </Box>
      </Box>
    </FilterModalContainer>
  )
}

export default FiltersAlertsForm

import { useState } from 'react'
import { Box, TextField, Typography, Button, Modal, Paper, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useTranslation } from 'react-i18next'

const AddMultipleStringModal = ({ updatedList = [], setUpdatedList, setChange }) => {
  const { t } = useTranslation(['common'])
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setInputValue('')
    setOpen(false)
  }

  const handleAddValues = () => {
    if (!inputValue.trim()) return

    const newValues = inputValue
      .split(',')
      .map((value) => value.trim())
      .filter((value) => value !== '')

    if (newValues.length === 0) {
      return
    }

    const uniqueNewValues = newValues.filter((value) => !updatedList.includes(value))

    if (uniqueNewValues.length > 0) {
      setUpdatedList((prevList) => [...prevList, ...uniqueNewValues])
      setChange(true)
    }

    setInputValue('')
    handleClose()
  }

  return (
    <>
      <Button endIcon={<AddCircleOutlineIcon />} variant="outlinedGrey" onClick={handleOpen}>
        {t('common:addMultiple')}
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5"> {t('common:addMultipleSeparated')}</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <TextField
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={t('common:exampleValues')}
            fullWidth
          />

          <Button variant="contained" onClick={handleAddValues} disabled={!inputValue.trim()}>
            {t('common:add')}
          </Button>
        </Paper>
      </Modal>
    </>
  )
}

export default AddMultipleStringModal

import './datatable.scss'
import { useEffect, useMemo } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Button, IconButton, Link, TablePagination, Typography } from '@mui/material'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { Status } from '../../utilities/Status'
import { Flags } from '../flag/flags'
import { setIsLoading } from '../../../features/kyb/kybCountrySlice'
import KybNoRowsOverlay from '../../../pages/kyb/components/KybNoRowsOverlay'
import { useFindKycsQuery } from '../../../services/guenoApi'
import CustomLoadingOverlay from '../datagrid/CustomLoadingOverlay'
import CustomColumnMenu from '../datagrid/CustomColumnMenu'
import useUserTableConfig from '../../hooks/useUserTableConfig'
import { getOrderedColumns, getVisibilityModel, updateListOrder } from './tableUtils/tableUtils'

const DatatableKyc = ({
  page,
  setPage,
  setRowsPerPage,
  setModalOpen,
  creationOnNoRows = false,
  documentNumber = '',
}) => {
  const { t } = useTranslation(['common'])
  const { email } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.kybPerson?.filters)
  const { currentCountry, countrySegment } = useSelector((state) => state.kybCountry)

  const columns = useMemo(
    () => [
      {
        field: 'documentNumber',
        headerName: t('common:documentNumber'),
        flex: 3,
        renderCell: ({ row }) => {
          return (
            <Link title={row._id} component={ReactRouterLink} to={`/kyc/${currentCountry}/detail/${row._id}`}>
              <Typography variant="number">{row.documentNumber ?? '---'}</Typography>
            </Link>
          )
        },
      },
      {
        field: 'name',
        headerName: t('common:name'),
        flex: 6,
        renderCell: ({ row }) => {
          return <p title={row.name ?? '---'}>{row.name ?? '---'}</p>
        },
      },
      {
        field: 'nationality',
        headerName: t('common:nationality'),
        flex: 2,
        renderCell: ({ row }) => {
          return Flags(row.nationality === 'Brazil' ? 'BR' : row.nationality)
        },
      },
      {
        field: 'status',
        headerName: t('common:status'),
        flex: 3,
        renderCell: (params) => {
          return <Status type={params.row.status} />
        },
      },
      {
        field: 'createdAt',
        headerName: t('common:creationDate'),
        flex: 3,
        renderCell: ({ row }) => (
          <Typography variant="number">{buildTimeStringFromTimestamp(row.createdAt)}</Typography>
        ),
      },
    ],
    [],
  )

  const {
    config,
    saveConfig,
    loading: configLoading,
  } = useUserTableConfig({
    tableKey: 'kyc',
    defaultConfig: {
      sortModel: [{ field: 'createdAt', sort: 'desc' }],
      rowsPerPage: 25,
      pinnedColumns: { left: [], right: [] },
      columnOrder: columns.map((c) => c.field),
    },
  })

  const { field, sort } = useMemo(
    () => (config.sortModel?.length ? config.sortModel[0] : { field: '', sort: '' }),
    [config.sortModel],
  )

  const { isFetching, isLoading, refetch, data } = useFindKycsQuery({
    email: queryState.showMyAssignments ? email : null,
    limit: config.rowsPerPage,
    sortField: field,
    sortDirection: sort === 'desc' ? -1 : 1,
    countrySegment,
    ...queryState,
  })

  const slots = {
    columnMenu: CustomColumnMenu,
    loadingOverlay: CustomLoadingOverlay,
  }

  if (creationOnNoRows) {
    slots.noRowsOverlay = KybNoRowsOverlay
  }

  const apiRef = useGridApiRef()

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    saveConfig({ rowsPerPage: newRowsPerPage })

    setPage(0)
    setIsLoading(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setIsLoading(true)
  }

  const orderedColumns = useMemo(
    () => getOrderedColumns(config.columnOrder, columns),
    [JSON.stringify(config.columnOrder), columns],
  )

  const handleColumnOrderChange = (params) => {
    const updatedOrder = updateListOrder(config.columnOrder, params)
    if (JSON.stringify(config.columnOrder) !== JSON.stringify(updatedOrder)) {
      saveConfig({ columnOrder: updatedOrder })
    }
  }

  const visibleColumnsModel = useMemo(
    () => getVisibilityModel(config.visibleColumns, columns),
    [config.visibleColumns, columns],
  )

  useEffect(() => {
    if (config.rowsPerPage) setRowsPerPage(config.rowsPerPage)
  }, [])

  return (
    <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <Box className="filter-bar">
        <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setModalOpen(true)}>
          {t('common:filters')}
        </Button>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Button
            variant="label"
            endIcon={<MenuOutlinedIcon />}
            onClick={() => apiRef.current.showPreferences('columns')}
          >
            {t('common:showHideColumns')}
          </Button>
          <IconButton onClick={refetch} disabled={isLoading} title={t('common:refresh')}>
            <RefreshIcon color="action" />
          </IconButton>
        </Box>
      </Box>
      <DataGridPro
        getRowHeight={() => 'auto'}
        getRowId={(row) => row._id || row.id}
        rows={Array.isArray(data?.data) ? data?.data : []}
        columns={orderedColumns}
        apiRef={apiRef}
        slots={slots}
        slotProps={{
          noRowsOverlay: { defaultDocumentNumber: documentNumber, isKyb: false },
        }}
        hideFooter={true}
        autoHeight
        loading={isFetching || isLoading || configLoading}
        sortingMode="server"
        sortModel={config.sortModel}
        onSortModelChange={(newSortModel) => saveConfig({ sortModel: newSortModel })}
        columnOrder={config.columnOrder}
        onColumnOrderChange={handleColumnOrderChange}
        pinnedColumns={config.pinnedColumns}
        onPinnedColumnsChange={(newPinnedColumns) => saveConfig({ pinnedColumns: newPinnedColumns })}
        columnVisibilityModel={visibleColumnsModel}
        onColumnVisibilityModelChange={(newModel) => saveConfig({ visibleColumns: newModel })}
      />

      <TablePagination
        labelRowsPerPage={[]}
        component="div"
        count={data?.pagination?.totalElements || 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={config.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}

export default DatatableKyc

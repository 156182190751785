import './datatable.scss'
import { useSelector } from 'react-redux'
import { Box, Link, Typography } from '@mui/material'
import { toLocaleUTCDateString } from '../time/timeHelper'
import { useAmlBr } from '../../../pages/pages-hooks/useAml'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridComponent } from '../datagrid/DataGridComponent'
import { TableChip } from '../../utilities/TableChip'

const DatatableAmlBr = ({ page, setPage, rowsPerPage, setRowsPerPage, setIsLoading, setModalOpen }) => {
  const { emailClient } = useSelector((state) => state.login)
  const aml = useAmlBr(emailClient, setIsLoading)

  const { t } = useTranslation(['common'])

  const columns = useMemo(
    () => [
      {
        field: '_id',
        headerName: t('common:referenceId'),
        renderCell: (params) => {
          return (
            <Link href={`/br-aml/${params.row._id}`}>
              <Typography variant="number">{params.row._id}</Typography>
            </Link>
          )
        },
      },
      {
        field: 'documentNumber',
        headerName: t('common:documentNumber'),
        renderCell: (params) => {
          return (
            <Typography variant="number">{params.row.documentNumber ? params.row.documentNumber : '---'}</Typography>
          )
        },
      },
      {
        field: 'PEPHistory',
        headerName: t('messages:aml.PEPHistory'),
        renderCell: (params) => {
          return (
            <Typography variant="number">
              {params.row?.reportDetails?.PEPHistory?.length ? params.row?.reportDetails?.PEPHistory?.length : '---'}
            </Typography>
          )
        },
      },
      {
        field: 'IsCurrentlyPEP',
        headerName: t('messages:aml.IsCurrentlyPEP'),
        renderCell: (params) => {
          return (
            <Typography variant="number">
              {typeof params.row?.reportDetails?.IsCurrentlyPEP === 'boolean' ? (
                <TableChip
                  action={params.row?.reportDetails?.IsCurrentlyPEP ? t('common:yes') : t('common:no')}
                  noIcon={true}
                />
              ) : (
                '---'
              )}
            </Typography>
          )
        },
      },
      {
        field: 'SanctionsHistory',
        headerName: t('messages:aml.SanctionsHistory'),
        renderCell: (params) => {
          return (
            <Typography variant="number">
              {params.row?.reportDetails?.SanctionsHistory?.length
                ? params.row?.reportDetails?.SanctionsHistory?.length
                : '---'}
            </Typography>
          )
        },
      },
      {
        field: 'IsCurrentlySanctioned',
        headerName: t('messages:aml.IsCurrentlySanctioned'),
        renderCell: (params) => {
          return (
            <Typography variant="number">
              {typeof params.row?.reportDetails?.IsCurrentlySanctioned === 'boolean' ? (
                <TableChip
                  action={params.row?.reportDetails?.IsCurrentlySanctioned ? t('common:yes') : t('common:no')}
                  noIcon={true}
                />
              ) : (
                '---'
              )}
            </Typography>
          )
        },
      },
      {
        field: 'WasPreviouslySanctioned',
        headerName: t('messages:aml.WasPreviouslySanctioned'),
        renderCell: (params) => {
          return (
            <Typography variant="number">
              {typeof params.row?.reportDetails?.WasPreviouslySanctioned === 'boolean' ? (
                <TableChip
                  action={params.row?.reportDetails?.WasPreviouslySanctioned ? t('common:yes') : t('common:no')}
                  noIcon={true}
                />
              ) : (
                '---'
              )}
            </Typography>
          )
        },
      },
      {
        field: 'createdAt',
        headerName: t('common:searchDate'),
        renderCell: (params) => {
          return <Typography variant="number">{toLocaleUTCDateString(params.row.createdAt)}</Typography>
        },
      },
    ],
    [],
  )

  return (
    <Box>
      <DataGridComponent
        rows={(Array.isArray(aml?.data) && aml?.data?.length && aml?.data) || []}
        columns={columns}
        count={aml?.pagination?.totalElements || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        setIsLoading={setIsLoading}
        setModalOpen={setModalOpen}
        tableKey="amlBr"
      />
    </Box>
  )
}

export default DatatableAmlBr
